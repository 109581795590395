import React, { useEffect, useState } from "react";
import "./index.scoped.css";
import CasinoVideo from "./components/CasinoVideo";
import Timer from "./components/Timer";
import { useDispatch } from "react-redux";
import { useQuery } from "react-query";
import {
  getCasinoBetsAPI,
  getCasinoExposureAPI,
  getCasinoLastTenResultAPI,
  getCasinoOddsAPI,
} from "../../service/casino";
import {
  CARD_ARRAY,
  CASINO_VIDEO,
  getCasinoPlExposureByRunner,
  getOddForEntity,
  setBet,
} from "./CasinoGeneric";
import PlaceBet from "./components/PlaceBet";
import PlacedBet from "./components/PlacedBet";
export default function Lucky7B() {
  const GAME_ID = "LUCKY7EU";
  const VIDEO_ID = 3056;
  const dispatch = useDispatch();
  const casinoData = useQuery(
    "getCasinoOdds",
    () => getCasinoOddsAPI(GAME_ID),
    {
      refetchInterval: 800,
    }
  );
  const lastTenResultData = useQuery(
    "getResult",
    () => getCasinoLastTenResultAPI(GAME_ID),
    {
      refetchInterval: 1500,
    }
  );
  const casinoBets = useQuery("casinoBets", () => getCasinoBetsAPI(GAME_ID), {
    refetchInterval: 2500,
  });
  const [casinoPlData, setCasinoPlData] = useState([]);
  const getPlExposure = async () => {
    const { response } = await getCasinoExposureAPI(
      casinoData?.data?.response?.marketId
    );
    setCasinoPlData(response);
  };
  useEffect(() => {
    if (casinoData?.data?.response?.marketId) {
      let interval = setInterval(() => {
        getPlExposure();
      }, 2000);
      return () => {
        clearInterval(interval);
      };
    }
  }, [casinoData?.data?.response?.marketId]);
  const [selectedTab, setSelectedTab] = React.useState("game");
  return (
    <div className="center-main-container casino-page ng-tns-c3502172659-1 ng-star-inserted">
      <div className="center-container ng-tns-c3502172659-1">
        <div className="casino-page-container ng-tns-c3502172659-1 lucky7a">
          <div className="casino-title-header-mobile d-xl-none ng-tns-c3502172659-1">
            <ul className="nav nav-tabs menu-tabs ng-tns-c3502172659-1">
              <li
                onClick={() => setSelectedTab("game")}
                className="nav-item ng-tns-c3502172659-1"
              >
                <div
                  className={
                    selectedTab == "game"
                      ? "ng-tns-c3502172659-1 nav-link active"
                      : "ng-tns-c3502172659-1 nav-link"
                  }
                >
                  Game
                </div>
              </li>
              <li
                onClick={() => setSelectedTab("bet")}
                className="nav-item ng-tns-c3502172659-1"
              >
                <div
                  className={
                    selectedTab == "game"
                      ? "ng-tns-c3502172659-1 nav-link active"
                      : "ng-tns-c3502172659-1 nav-link"
                  }
                >
                  {" "}
                  Placed Bet ({casinoBets?.data?.response?.length}){" "}
                </div>
              </li>
            </ul>
            <div className="pe-2 ng-tns-c3502172659-1">
              <span className="casino-rid ng-tns-c3502172659-1">
                <a className="d-block ng-tns-c3502172659-1">
                  <small className="ng-tns-c3502172659-1">Rules</small>
                </a>{" "}
                Round ID:{" "}
                <span className="ng-tns-c3502172659-1 ng-star-inserted">
                  {casinoData?.data?.response?.marketId}
                </span>
              </span>
            </div>
          </div>
          <div className="casino-header ng-tns-c3502172659-1">
            <span className="casino-name ng-tns-c3502172659-1">
              {" "}
              Lucky 7 - B
              <a className="ms-1 d-xl-inline d-none ng-tns-c3502172659-1">
                <small className="ng-tns-c3502172659-1">Rules</small>
              </a>
            </span>
            <span className="casino-rid d-none d-xl-inline-block ng-tns-c3502172659-1">
              <small className="ng-tns-c3502172659-1">
                Round ID:{" "}
                <span className="ng-tns-c3502172659-1 ng-star-inserted">
                  {casinoData?.data?.response?.marketId}
                </span>
              </small>
            </span>
          </div>

          {selectedTab == "game" ? (
            <div className="ng-tns-c3502172659-1 ng-star-inserted">
              <div className="casino-video ng-tns-c3502172659-1">
                <div className="video-box-container ng-tns-c3502172659-1">
                  <div className="casino-video-box ng-tns-c3502172659-1 ng-star-inserted">
                    <CasinoVideo id={CASINO_VIDEO.lucky7B} />
                  </div>
                </div>
                <div className="ng-tns-c3502172659-1 ng-star-inserted">
                  <div
                    _ngcontent-ng-c1798067373=""
                    className="casino-video-cards ng-tns-c1798067373-0 ng-star-inserted"
                  >
                    <div
                      _ngcontent-ng-c1798067373=""
                      className="flip-card-container ng-tns-c1798067373-0"
                    >
                      {casinoData?.data?.response?.cards &&
                        casinoData?.data?.response?.cards[0]?.map((item) => (
                          <div
                            _ngcontent-ng-c1798067373=""
                            className="me-lg-2 me-1 ng-tns-c1798067373-0 ng-star-inserted"
                          >
                            <img
                              _ngcontent-ng-c1798067373=""
                              className="ng-tns-c1798067373-0"
                              src={`https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/${item}.webp`}
                            />
                          </div>
                        ))}
                    </div>
                  </div>
                </div>

                <Timer value={casinoData?.data?.response?.autoTime} />
              </div>

              <div className="casino-detail ng-tns-c3502172659-1">
                <div className="casino-table ng-tns-c3502172659-1 ng-star-inserted">
                  <div className="casino-table-full-box ng-tns-c3502172659-1">
                    <div className="lucky7low ng-tns-c3502172659-1 ng-star-inserted">
                      <div className="casino-odds text-center ng-tns-c3502172659-1">
                        {getOddForEntity("LOW card", casinoData)}
                      </div>
                      <div
                        onClick={() => setBet("LOW card", casinoData, dispatch)}
                        className={`ng-tns-c3502172659-1 back casino-odds-box casino-odds-box-theme ${casinoData
                          ?.data?.response?.autoTime < 5 && "suspended-box"}`}
                      >
                        <span className="casino-odds ng-tns-c3502172659-1">
                          {" "}
                          LOW Card
                        </span>
                      </div>
                      <div className="casino-nation-book text-center ng-tns-c3502172659-1">
                        {getCasinoPlExposureByRunner(casinoPlData, "LOW card")}
                      </div>
                    </div>

                    <div className="lucky7 ng-tns-c3502172659-1 ng-star-inserted">
                      <img
                        className="ng-tns-c3502172659-1"
                        src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/7.webp"
                      />
                    </div>

                    <div className="lucky7high ng-tns-c3502172659-1 ng-star-inserted">
                      <div className="casino-odds text-center ng-tns-c3502172659-1">
                        {getOddForEntity("HIGH card", casinoData)}
                      </div>
                      <div
                        onClick={() =>
                          setBet("HIGH card", casinoData, dispatch)
                        }
                        className={`ng-tns-c3502172659-1 back casino-odds-box casino-odds-box-theme ${casinoData
                          ?.data?.response?.autoTime < 5 && "suspended-box"}`}
                      >
                        <span className="casino-odds ng-tns-c3502172659-1">
                          HIGH Card
                        </span>
                      </div>
                      <div className="casino-nation-book text-center ng-tns-c3502172659-1">
                        {getCasinoPlExposureByRunner(casinoPlData, "HIGH card")}
                      </div>
                    </div>

                    <div className="min_max_odds ng-tns-c3502172659-1 ng-star-inserted">
                      <span className="min_max_span ng-tns-c3502172659-1">
                        <b className="ng-tns-c3502172659-1">Min:</b>
                        <span className="ng-tns-c3502172659-1"> 100</span>
                      </span>
                      <span className="min_max_span ng-tns-c3502172659-1">
                        <b className="ng-tns-c3502172659-1">Max:</b>
                        <span className="ng-tns-c3502172659-1">
                          <span className="ng-tns-c3502172659-1 ng-star-inserted">
                            {" "}
                            100000{" "}
                          </span>
                        </span>
                      </span>
                    </div>
                  </div>
                  <div className="casino-table-box mt-3 ng-tns-c3502172659-1">
                    <div className="casino-table-left-box ng-tns-c3502172659-1">
                      <div className="lucky7odds ng-tns-c3502172659-1 ng-star-inserted">
                        <div className="casino-odds text-center ng-tns-c3502172659-1">
                          {getOddForEntity("even", casinoData)}
                        </div>
                        <div
                          onClick={() => setBet("even", casinoData, dispatch)}
                          className={`ng-tns-c3502172659-1 back casino-odds-box casino-odds-box-theme ${casinoData
                            ?.data?.response?.autoTime < 5 && "suspended-box"}`}
                        >
                          <span className="casino-odds ng-tns-c3502172659-1">
                            Even
                          </span>
                        </div>
                        <div className="casino-nation-book text-center ng-tns-c3502172659-1">
                          {getCasinoPlExposureByRunner(casinoPlData, "Even")}
                        </div>
                      </div>

                      <div className="lucky7odds ng-tns-c3502172659-1 ng-star-inserted">
                        <div className="casino-odds text-center ng-tns-c3502172659-1">
                          {getOddForEntity("odd", casinoData)}
                        </div>
                        <div
                          onClick={() => setBet("odd", casinoData, dispatch)}
                          className={`ng-tns-c3502172659-1 back casino-odds-box casino-odds-box-theme ${casinoData
                            ?.data?.response?.autoTime < 5 && "suspended-box"}`}
                        >
                          <span className="casino-odds ng-tns-c3502172659-1">
                            Odd
                          </span>
                        </div>
                        <div className="casino-nation-book text-center ng-tns-c3502172659-1">
                          {getCasinoPlExposureByRunner(casinoPlData, "Odd")}
                        </div>
                      </div>

                      <div className="min_max_odds ng-tns-c3502172659-1 ng-star-inserted">
                        <span className="min_max_span ng-tns-c3502172659-1">
                          <b className="ng-tns-c3502172659-1">Min:</b>
                          <span className="ng-tns-c3502172659-1"> 100</span>
                        </span>
                        <span className="min_max_span ng-tns-c3502172659-1">
                          <b className="ng-tns-c3502172659-1">Max:</b>
                          <span className="ng-tns-c3502172659-1">
                            <span className="ng-tns-c3502172659-1 ng-star-inserted">
                              {" "}
                              25000{" "}
                            </span>
                          </span>
                        </span>
                      </div>
                    </div>
                    <div className="casino-table-right-box ng-tns-c3502172659-1">
                      <div className="lucky7odds ng-tns-c3502172659-1 ng-star-inserted">
                        <div className="casino-odds text-center ng-tns-c3502172659-1">
                          {getOddForEntity("red", casinoData)}
                        </div>
                        <div
                          onClick={() => setBet("red", casinoData, dispatch)}
                          className={`ng-tns-c3502172659-1 back casino-odds-box casino-odds-box-theme ${casinoData
                            ?.data?.response?.autoTime < 5 && "suspended-box"}`}
                        >
                          <span className="casino-odds ng-tns-c3502172659-1">
                            <span className="card-icon ms-1 ng-tns-c3502172659-1">
                              <span className="card-red ng-tns-c3502172659-1">
                                <img
                                  className="ng-tns-c3502172659-1"
                                  src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/SS.webp"
                                />
                              </span>
                            </span>
                            <span className="card-icon ms-1 ng-tns-c3502172659-1">
                              <span className="card-red ng-tns-c3502172659-1">
                                <img
                                  className="ng-tns-c3502172659-1"
                                  src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/DD.webp"
                                />
                              </span>
                            </span>
                          </span>
                        </div>
                        <div className="casino-nation-book text-center ng-tns-c3502172659-1">
                          {getCasinoPlExposureByRunner(casinoPlData, "Red")}
                        </div>
                      </div>

                      <div className="lucky7odds ng-tns-c3502172659-1 ng-star-inserted">
                        <div className="casino-odds text-center ng-tns-c3502172659-1">
                          {getOddForEntity("black", casinoData)}
                        </div>
                        <div
                          onClick={() => setBet("black", casinoData, dispatch)}
                          className={`ng-tns-c3502172659-1 back casino-odds-box casino-odds-box-theme ${casinoData
                            ?.data?.response?.autoTime < 5 && "suspended-box"}`}
                        >
                          <span className="casino-odds ng-tns-c3502172659-1">
                            <span className="card-icon ms-1 ng-tns-c3502172659-1">
                              <span className="card-red ng-tns-c3502172659-1">
                                <img
                                  className="ng-tns-c3502172659-1"
                                  src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/CC.webp"
                                />
                              </span>
                            </span>
                            <span className="card-icon ms-1 ng-tns-c3502172659-1">
                              <span className="card-red ng-tns-c3502172659-1">
                                <img
                                  className="ng-tns-c3502172659-1"
                                  src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/HH.webp"
                                />
                              </span>
                            </span>
                          </span>
                        </div>
                        <div className="casino-nation-book text-center ng-tns-c3502172659-1">
                          {getCasinoPlExposureByRunner(casinoPlData, "Black")}
                        </div>
                      </div>

                      <div className="min_max_odds ng-tns-c3502172659-1 ng-star-inserted">
                        <span className="min_max_span ng-tns-c3502172659-1">
                          <b className="ng-tns-c3502172659-1">Min:</b>
                          <span className="ng-tns-c3502172659-1"> 100</span>
                        </span>
                        <span className="min_max_span ng-tns-c3502172659-1">
                          <b className="ng-tns-c3502172659-1">Max:</b>
                          <span className="ng-tns-c3502172659-1">
                            <span className="ng-tns-c3502172659-1 ng-star-inserted">
                              {" "}
                              25000{" "}
                            </span>
                          </span>
                        </span>
                      </div>
                    </div>
                  </div>
                  <PlaceBet GAME_ID={GAME_ID} />
                  <div className="casino-table-full-box lucky7acards mt-3 ng-tns-c3502172659-1">
                    <div className="casino-odds w-100 text-center ng-tns-c3502172659-1">
                      {" "}
                      12{" "}
                    </div>

                    {CARD_ARRAY.map((card, index) => (
                      <div
                        onClick={() =>
                          setBet("card " + card, casinoData, dispatch)
                        }
                        className="card-odd-box ng-tns-c3502172659-1 ng-star-inserted"
                      >
                        <div
                          className={`ng-tns-c3502172659-1 ${casinoData?.data
                            ?.response?.autoTime < 5 && "suspended-box"}`}
                        >
                          <img
                            className="ng-tns-c3502172659-1 ng-star-inserted"
                            src={`https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/${card}.webp`}
                          />
                        </div>
                        <div className="casino-nation-book ng-tns-c3502172659-1">
                          {getCasinoPlExposureByRunner(
                            casinoPlData,
                            "Card " + card
                          )}
                        </div>
                      </div>
                    ))}

                    <div className="min_max_odds ng-tns-c3502172659-1 ng-star-inserted">
                      <span className="min_max_span ng-tns-c3502172659-1">
                        <b className="ng-tns-c3502172659-1">Min:</b>
                        <span className="ng-tns-c3502172659-1"> 100</span>
                      </span>
                      <span className="min_max_span ng-tns-c3502172659-1">
                        <b className="ng-tns-c3502172659-1">Max:</b>
                        <span className="ng-tns-c3502172659-1">
                          <span className="ng-tns-c3502172659-1 ng-star-inserted">
                            {" "}
                            25000{" "}
                          </span>
                        </span>
                      </span>
                    </div>
                  </div>
                </div>

                <div className="ng-tns-c3502172659-1">
                  <div className="casino-last-result-title ng-tns-c3502172659-1">
                    <span className="ng-tns-c3502172659-1">Last Result</span>
                    <span className="ng-tns-c3502172659-1">
                      <a className="ng-tns-c3502172659-1">View All</a>
                    </span>
                  </div>
                  <div className="casino-last-results ng-tns-c3502172659-1 ng-star-inserted">
                    {lastTenResultData?.data?.data?.casinoMarketHistoryResponseDTOS?.map(
                      (item) => (
                        <span
                          className={`ng-tns-c3502172659-1 result result-${
                            item?.mainWinner == "T"
                              ? "c"
                              : item?.mainWinner == "L"
                              ? "a"
                              : "b"
                          } ng-star-inserted`}
                        >
                          <span className="ng-tns-c3502172659-1">
                            {" "}
                            {item?.mainWinner}
                          </span>
                        </span>
                      )
                    )}
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <PlacedBet bets={casinoBets?.data?.response} />
          )}
          <div className="ng-tns-c3502172659-1 d-none matchbettable w-100 ng-star-inserted">
            <div className="table-responsive ng-tns-c3502172659-1">
              <table className="table ng-tns-c3502172659-1">
                <thead className="ng-tns-c3502172659-1">
                  <tr className="ng-tns-c3502172659-1">
                    <th className="ng-tns-c3502172659-1">Matched Bet</th>
                    <th className="text-end ng-tns-c3502172659-1">Odds</th>
                    <th className="text-end ng-tns-c3502172659-1">Stake</th>
                  </tr>
                </thead>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div className="sidebar right-sidebar casino-right-sidebar ng-tns-c3502172659-1">
        <div className="sidebar-box place-bet-container d-xl-block d-none ng-tns-c3502172659-1">
          <div className="sidebar-title ng-tns-c3502172659-1">
            <h4 className="ng-tns-c3502172659-1">Place Bet</h4>
          </div>
        </div>
        <div className="sidebar-box my-bet-container d-xl-block d-none ng-tns-c3502172659-1">
          <div className="sidebar-title ng-tns-c3502172659-1">
            <h4 className="ng-tns-c3502172659-1">My Bet</h4>
            <a className="sideviewmore ng-tns-c3502172659-1">View more</a>
          </div>
          <div className="my-bets ng-tns-c3502172659-1">
            <div className="table-responsive w-100 ng-tns-c3502172659-1">
              <table className="table ng-tns-c3502172659-1">
                <thead className="ng-tns-c3502172659-1">
                  <tr className="ng-tns-c3502172659-1">
                    <th className="ng-tns-c3502172659-1">Matched Bet</th>
                    <th className="text-end ng-tns-c3502172659-1">Odds</th>
                    <th className="text-end ng-tns-c3502172659-1">Stake</th>
                  </tr>
                </thead>
              </table>
            </div>
          </div>
        </div>
        <div className="sidebar-box sidebar-box-rules my-bet-container ng-tns-c3502172659-1">
          <app-casino-game-rules
            className="ng-tns-c3502172659-1"
            _nghost-ng-c1761710207=""
          />
        </div>
      </div>
    </div>
  );
}
