import React from "react";
import "./index.scoped.css";
import { useDispatch } from "react-redux";
import { useQuery } from "react-query";
import {
  getCasinoBetsAPI,
  getCasinoLastTenResultAPI,
  getCasinoOddsAPI,
} from "../../service/casino";
import PlaceBet from "./components/PlaceBet";
import CasinoVideo from "./components/CasinoVideo";
import { CASINO_VIDEO, getOddForEntity, setBet } from "./CasinoGeneric";
import Timer from "./components/Timer";
import PlacedBet from "./components/PlacedBet";
import CasinoPage from "./components/CasinoPage";
export default function FiveCricket() {
  const GAME_ID = "CRICKET_V3";
  const dispatch = useDispatch();
  const casinoData = useQuery(
    "getCasinoOdds",
    () => getCasinoOddsAPI(GAME_ID),
    {
      refetchInterval: 800,
    }
  );
  const lastTenResultData = useQuery(
    "getResult",
    () => getCasinoLastTenResultAPI(GAME_ID),
    {
      refetchInterval: 1500,
    }
  );
  const casinoBets = useQuery("casinoBets", () => getCasinoBetsAPI(GAME_ID), {
    refetchInterval: 2500,
  });
  return (
    <>
      <CasinoPage
        casino={casinoData?.data}
        VIDEO_ID={CASINO_VIDEO.fiveFiveCricket}
        GAME_ID={GAME_ID}
        shortName={"super-over"}
        gameName={"5FIVE CRICKET"}
      >
        <div
          _ngcontent-ng-c1798067373=""
          className="ng-tns-c1798067373-7 ng-star-inserted"
        >
          <div
            _ngcontent-ng-c1798067373=""
            className="casino-detail ng-tns-c1798067373-7"
          >
            <div
              _ngcontent-ng-c1798067373=""
              className="desktop-design d-lg-block d-none ng-tns-c1798067373-7"
            >
              <div
                _ngcontent-ng-c1798067373=""
                className="game-market market-4 ng-tns-c1798067373-7"
              >
                <div
                  _ngcontent-ng-c1798067373=""
                  className="market-title ng-tns-c1798067373-7"
                >
                  <span
                    _ngcontent-ng-c1798067373=""
                    className="ng-tns-c1798067373-7"
                  >
                    Bookmaker Market
                  </span>
                  <a
                    _ngcontent-ng-c1798067373=""
                    href="javascript:void(0);"
                    data-bs-toggle="modal"
                    data-bs-target="#BookMarkerModal"
                    type="button"
                    className="rules_btn ng-tns-c1798067373-7"
                  >
                    <i
                      _ngcontent-ng-c1798067373=""
                      className="fas fa-info-circle ng-tns-c1798067373-7"
                    />
                  </a>
                </div>
                <div
                  _ngcontent-ng-c1798067373=""
                  className="market-header ng-tns-c1798067373-7"
                >
                  <div
                    _ngcontent-ng-c1798067373=""
                    className="market-nation-detail ng-tns-c1798067373-7"
                  >
                    <span
                      _ngcontent-ng-c1798067373=""
                      className="market-nation-name ng-tns-c1798067373-7"
                    />
                  </div>
                  <div
                    _ngcontent-ng-c1798067373=""
                    className="market-odd-box no-border d-none d-md-block ng-tns-c1798067373-7"
                  />
                  <div
                    _ngcontent-ng-c1798067373=""
                    className="market-odd-box no-border d-none d-md-block ng-tns-c1798067373-7"
                  />
                  <div
                    _ngcontent-ng-c1798067373=""
                    className="market-odd-box back ng-tns-c1798067373-7"
                  >
                    <b
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-7"
                    >
                      Back
                    </b>
                  </div>
                  <div
                    _ngcontent-ng-c1798067373=""
                    className="market-odd-box lay ng-tns-c1798067373-7"
                  >
                    <b
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-7"
                    >
                      Lay
                    </b>
                  </div>
                  <div
                    _ngcontent-ng-c1798067373=""
                    className="market-odd-box no-border ng-tns-c1798067373-7"
                  />
                  <div
                    _ngcontent-ng-c1798067373=""
                    className="market-odd-box no-border ng-tns-c1798067373-7"
                  />
                </div>
                <div
                  _ngcontent-ng-c1798067373=""
                  data-title="OPEN"
                  className="market-body ng-tns-c1798067373-7"
                >
                  <div
                    _ngcontent-ng-c1798067373=""
                    data-title="ACTIVE"
                    className="market-row ng-tns-c1798067373-7 ng-star-inserted"
                  >
                    <div
                      _ngcontent-ng-c1798067373=""
                      className="market-nation-detail ng-tns-c1798067373-7"
                    >
                      <span
                        _ngcontent-ng-c1798067373=""
                        className="market-nation-name ng-tns-c1798067373-7"
                      >
                        AUS
                      </span>
                      <div
                        _ngcontent-ng-c1798067373=""
                        className="market-nation-book ng-tns-c1798067373-7"
                      >
                        <span
                          _ngcontent-ng-c1798067373=""
                          id="book_1"
                          className="float-left book ng-tns-c1798067373-7"
                          style={{ color: "black" }}
                        >
                          <span
                            _ngcontent-ng-c1798067373=""
                            className="ng-tns-c1798067373-7"
                          >
                            {" "}
                            0{" "}
                          </span>
                        </span>
                      </div>
                    </div>
                    <div
                      _ngcontent-ng-c1798067373=""
                      data-title="SUSPENDED"
                      className={`Market-odd-row-box ng-tns-c1798067373-7 ${
                        casinoData?.data?.response.autoTime < 5
                          ? "suspended-table"
                          : ""
                      }`}
                    >
                      <div
                        _ngcontent-ng-c1798067373=""
                        className="back2 market-odd-box ng-tns-c1798067373-7"
                      >
                        <span
                          _ngcontent-ng-c1798067373=""
                          className="market-odd ng-tns-c1798067373-7"
                        />
                        <span
                          _ngcontent-ng-c1798067373=""
                          className="market-volume ng-tns-c1798067373-7"
                        />
                      </div>
                      <div
                        _ngcontent-ng-c1798067373=""
                        className="back1 market-odd-box ng-tns-c1798067373-7"
                      >
                        <span
                          _ngcontent-ng-c1798067373=""
                          className="market-odd ng-tns-c1798067373-7"
                        />
                        <span
                          _ngcontent-ng-c1798067373=""
                          className="market-volume ng-tns-c1798067373-7"
                        />
                      </div>
                      <div
                        _ngcontent-ng-c1798067373=""
                        className="back market-odd-box ng-tns-c1798067373-7"
                        onClick={() =>
                          setBet("AUS", casinoData, dispatch, "back")
                        }
                      >
                        <span
                          _ngcontent-ng-c1798067373=""
                          className="market-odd ng-tns-c1798067373-7"
                        >
                          {" "}
                          {getOddForEntity("AUS", casinoData, true)}
                        </span>
                        <span
                          _ngcontent-ng-c1798067373=""
                          className="market-volume ng-tns-c1798067373-7"
                        >
                          {" "}
                          {getOddForEntity("AUS", casinoData, true, true)}{" "}
                        </span>
                      </div>
                      <div
                        _ngcontent-ng-c1798067373=""
                        className="lay market-odd-box ng-tns-c1798067373-7"
                        onClick={() =>
                          setBet("AUS", casinoData, dispatch, "lay")
                        }
                      >
                        <span
                          _ngcontent-ng-c1798067373=""
                          className="market-odd ng-tns-c1798067373-7"
                        >
                          {" "}
                          {getOddForEntity("AUS", casinoData, false)}
                        </span>
                        <span
                          _ngcontent-ng-c1798067373=""
                          className="market-volume ng-tns-c1798067373-7"
                        >
                          {" "}
                          {getOddForEntity("AUS", casinoData, false, true)}{" "}
                        </span>
                      </div>
                      <div
                        _ngcontent-ng-c1798067373=""
                        className="lay1 market-odd-box ng-tns-c1798067373-7"
                      >
                        <span
                          _ngcontent-ng-c1798067373=""
                          className="market-odd ng-tns-c1798067373-7"
                        />
                        <span
                          _ngcontent-ng-c1798067373=""
                          className="market-volume ng-tns-c1798067373-7"
                        />
                      </div>
                      <div
                        _ngcontent-ng-c1798067373=""
                        className="lay2 market-odd-box ng-tns-c1798067373-7"
                      >
                        <span
                          _ngcontent-ng-c1798067373=""
                          className="market-odd ng-tns-c1798067373-7"
                        />
                        <span
                          _ngcontent-ng-c1798067373=""
                          className="market-volume ng-tns-c1798067373-7"
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    _ngcontent-ng-c1798067373=""
                    data-title="ACTIVE"
                    className="market-row ng-tns-c1798067373-7 ng-star-inserted"
                  >
                    <div
                      _ngcontent-ng-c1798067373=""
                      className="market-nation-detail ng-tns-c1798067373-7"
                    >
                      <span
                        _ngcontent-ng-c1798067373=""
                        className="market-nation-name ng-tns-c1798067373-7"
                      >
                        IND
                      </span>
                      <div
                        _ngcontent-ng-c1798067373=""
                        className="market-nation-book ng-tns-c1798067373-7"
                      >
                        <span
                          _ngcontent-ng-c1798067373=""
                          id="book_1"
                          className="float-left book ng-tns-c1798067373-7"
                          style={{ color: "black" }}
                        >
                          <span
                            _ngcontent-ng-c1798067373=""
                            className="ng-tns-c1798067373-7"
                          >
                            {" "}
                            0{" "}
                          </span>
                        </span>
                      </div>
                    </div>
                    <div
                      _ngcontent-ng-c1798067373=""
                      data-title="SUSPENDED"
                      className={`Market-odd-row-box ng-tns-c1798067373-7 ${
                        casinoData?.data?.response.autoTime < 5
                          ? "suspended-table"
                          : ""
                      }`}
                    >
                      <div
                        _ngcontent-ng-c1798067373=""
                        className="back2 market-odd-box ng-tns-c1798067373-7"
                      >
                        <span
                          _ngcontent-ng-c1798067373=""
                          className="market-odd ng-tns-c1798067373-7"
                        />
                        <span
                          _ngcontent-ng-c1798067373=""
                          className="market-volume ng-tns-c1798067373-7"
                        />
                      </div>
                      <div
                        _ngcontent-ng-c1798067373=""
                        className="back1 market-odd-box ng-tns-c1798067373-7"
                      >
                        <span
                          _ngcontent-ng-c1798067373=""
                          className="market-odd ng-tns-c1798067373-7"
                        />
                        <span
                          _ngcontent-ng-c1798067373=""
                          className="market-volume ng-tns-c1798067373-7"
                        />
                      </div>
                      <div
                        _ngcontent-ng-c1798067373=""
                        className="back market-odd-box ng-tns-c1798067373-7"
                        onClick={() =>
                          setBet("IND", casinoData, dispatch, "back")
                        }
                      >
                        <span
                          _ngcontent-ng-c1798067373=""
                          className="market-odd ng-tns-c1798067373-7"
                        >
                          {" "}
                          {getOddForEntity("IND", casinoData, true)}
                        </span>
                        <span
                          _ngcontent-ng-c1798067373=""
                          className="market-volume ng-tns-c1798067373-7"
                        >
                          {" "}
                          {getOddForEntity("IND", casinoData, true, true)}{" "}
                        </span>
                      </div>
                      <div
                        _ngcontent-ng-c1798067373=""
                        className="lay market-odd-box ng-tns-c1798067373-7"
                        onClick={() =>
                          setBet("IND", casinoData, dispatch, "lay")
                        }
                      >
                        <span
                          _ngcontent-ng-c1798067373=""
                          className="market-odd ng-tns-c1798067373-7"
                        >
                          {" "}
                          {getOddForEntity("IND", casinoData, false)}
                        </span>
                        <span
                          _ngcontent-ng-c1798067373=""
                          className="market-volume ng-tns-c1798067373-7"
                        >
                          {" "}
                          {getOddForEntity("IND", casinoData, false, true)}{" "}
                        </span>
                      </div>
                      <div
                        _ngcontent-ng-c1798067373=""
                        className="lay1 market-odd-box ng-tns-c1798067373-7"
                      >
                        <span
                          _ngcontent-ng-c1798067373=""
                          className="market-odd ng-tns-c1798067373-7"
                        />
                        <span
                          _ngcontent-ng-c1798067373=""
                          className="market-volume ng-tns-c1798067373-7"
                        />
                      </div>
                      <div
                        _ngcontent-ng-c1798067373=""
                        className="lay2 market-odd-box ng-tns-c1798067373-7"
                      >
                        <span
                          _ngcontent-ng-c1798067373=""
                          className="market-odd ng-tns-c1798067373-7"
                        />
                        <span
                          _ngcontent-ng-c1798067373=""
                          className="market-volume ng-tns-c1798067373-7"
                        />
                      </div>
                    </div>
                  </div>
                  {/**/}
                </div>
              </div>
              <div
                _ngcontent-ng-c1798067373=""
                className="game-market market-6 fancy-market-design ng-tns-c1798067373-7"
              >
                <div
                  _ngcontent-ng-c1798067373=""
                  className="row row10 ng-tns-c1798067373-7"
                >
                  {/**/}
                </div>
              </div>
            </div>
            <div
              _ngcontent-ng-c1798067373=""
              className="mobile-design d-lg-none d-block ng-tns-c1798067373-7"
            >
              <div
                _ngcontent-ng-c1798067373=""
                className="game-market market-4 ng-tns-c1798067373-7"
              >
                <div
                  _ngcontent-ng-c1798067373=""
                  className="market-title ng-tns-c1798067373-7"
                >
                  <span
                    _ngcontent-ng-c1798067373=""
                    className="ng-tns-c1798067373-7"
                  >
                    Bookmaker Market
                  </span>
                  <a
                    _ngcontent-ng-c1798067373=""
                    data-bs-toggle="modal"
                    data-bs-target="#BookMarkerModal"
                    href="javascript:void(0);"
                    className="rules_btn ng-tns-c1798067373-7"
                  >
                    <i
                      _ngcontent-ng-c1798067373=""
                      className="fas fa-info-circle ng-tns-c1798067373-7"
                    />
                  </a>
                </div>
                <div
                  _ngcontent-ng-c1798067373=""
                  className="market-header ng-tns-c1798067373-7"
                >
                  <div
                    _ngcontent-ng-c1798067373=""
                    className="market-nation-detail ng-tns-c1798067373-7"
                  >
                    <span
                      _ngcontent-ng-c1798067373=""
                      className="market-nation-name ng-tns-c1798067373-7"
                    >
                      {" "}
                      Max:{" "}
                      <span
                        _ngcontent-ng-c1798067373=""
                        className="ng-tns-c1798067373-7"
                      >
                        <span
                          _ngcontent-ng-c1798067373=""
                          className="ng-tns-c1798067373-7 ng-star-inserted"
                        >
                          {" "}
                          300000{" "}
                        </span>
                        {/**/}
                        {/**/}
                      </span>
                    </span>
                  </div>
                  <div
                    _ngcontent-ng-c1798067373=""
                    className="market-odd-box no-border d-none d-md-block ng-tns-c1798067373-7"
                  />
                  <div
                    _ngcontent-ng-c1798067373=""
                    className="market-odd-box no-border d-none d-md-block ng-tns-c1798067373-7"
                  />
                  <div
                    _ngcontent-ng-c1798067373=""
                    className="market-odd-box back ng-tns-c1798067373-7"
                  >
                    <b
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-7"
                    >
                      Back
                    </b>
                  </div>
                  <div
                    _ngcontent-ng-c1798067373=""
                    className="market-odd-box lay ng-tns-c1798067373-7"
                  >
                    <b
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-7"
                    >
                      Lay
                    </b>
                  </div>
                  <div
                    _ngcontent-ng-c1798067373=""
                    className="market-odd-box no-border ng-tns-c1798067373-7"
                  />
                  <div
                    _ngcontent-ng-c1798067373=""
                    className="market-odd-box no-border ng-tns-c1798067373-7"
                  />
                </div>
                <div
                  _ngcontent-ng-c1798067373=""
                  data-title="OPEN"
                  className="market-body ng-tns-c1798067373-7"
                >
                  <div
                    _ngcontent-ng-c1798067373=""
                    data-title="ACTIVE"
                    className="market-row ng-tns-c1798067373-7 ng-star-inserted"
                  >
                    <div
                      _ngcontent-ng-c1798067373=""
                      className="market-nation-detail ng-tns-c1798067373-7"
                    >
                      <span
                        _ngcontent-ng-c1798067373=""
                        className="market-nation-name ng-tns-c1798067373-7"
                      >
                        AUS
                      </span>
                      <div
                        _ngcontent-ng-c1798067373=""
                        className="market-nation-book ng-tns-c1798067373-7"
                      >
                        <span
                          _ngcontent-ng-c1798067373=""
                          id="book_1"
                          className="float-left book ng-tns-c1798067373-7"
                          style={{ color: "black" }}
                        >
                          <span
                            _ngcontent-ng-c1798067373=""
                            className="ng-tns-c1798067373-7"
                          >
                            {" "}
                            0{" "}
                          </span>
                        </span>
                      </div>
                    </div>
                    <div
                      _ngcontent-ng-c1798067373=""
                      data-title="SUSPENDED"
                      className="Market-odd-row-box ng-tns-c1798067373-7"
                    >
                      <div
                        _ngcontent-ng-c1798067373=""
                        type="button"
                        data-bs-toggle="modal"
                        className="market-odd-box back2 ng-tns-c1798067373-7"
                      >
                        <span
                          _ngcontent-ng-c1798067373=""
                          className="market-odd ng-tns-c1798067373-7"
                        />
                        <span
                          _ngcontent-ng-c1798067373=""
                          className="market-volume ng-tns-c1798067373-7"
                        />
                      </div>
                      <div
                        _ngcontent-ng-c1798067373=""
                        type="button"
                        data-bs-toggle="modal"
                        className="market-odd-box back1 ng-tns-c1798067373-7"
                      >
                        <span
                          _ngcontent-ng-c1798067373=""
                          className="market-odd ng-tns-c1798067373-7"
                        />
                        <span
                          _ngcontent-ng-c1798067373=""
                          className="market-volume ng-tns-c1798067373-7"
                        />
                      </div>
                      <div
                        _ngcontent-ng-c1798067373=""
                        className="back market-odd-box ng-tns-c1798067373-7"
                      >
                        <span
                          _ngcontent-ng-c1798067373=""
                          className="market-odd ng-tns-c1798067373-7"
                        >
                          {" "}
                          1.01
                        </span>
                        <span
                          _ngcontent-ng-c1798067373=""
                          className="market-volume ng-tns-c1798067373-7"
                        >
                          {" "}
                          300000{" "}
                        </span>
                      </div>
                      <div
                        _ngcontent-ng-c1798067373=""
                        className="lay market-odd-box ng-tns-c1798067373-7"
                      >
                        <span
                          _ngcontent-ng-c1798067373=""
                          className="market-odd ng-tns-c1798067373-7"
                        >
                          {" "}
                          1.03
                        </span>
                        <span
                          _ngcontent-ng-c1798067373=""
                          className="market-volume ng-tns-c1798067373-7"
                        >
                          {" "}
                          300000{" "}
                        </span>
                      </div>
                      <div
                        _ngcontent-ng-c1798067373=""
                        type="button"
                        data-bs-toggle="modal"
                        className="market-odd-box lay1 ng-tns-c1798067373-7"
                      >
                        <span
                          _ngcontent-ng-c1798067373=""
                          className="market-odd ng-tns-c1798067373-7"
                        />
                        <span
                          _ngcontent-ng-c1798067373=""
                          className="market-volume ng-tns-c1798067373-7"
                        />
                      </div>
                      <div
                        _ngcontent-ng-c1798067373=""
                        type="button"
                        data-bs-toggle="modal"
                        className="market-odd-box lay2 ng-tns-c1798067373-7"
                      >
                        <span
                          _ngcontent-ng-c1798067373=""
                          className="market-odd ng-tns-c1798067373-7"
                        />
                        <span
                          _ngcontent-ng-c1798067373=""
                          className="market-volume ng-tns-c1798067373-7"
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    _ngcontent-ng-c1798067373=""
                    data-title="ACTIVE"
                    className="market-row ng-tns-c1798067373-7 ng-star-inserted"
                  >
                    <div
                      _ngcontent-ng-c1798067373=""
                      className="market-nation-detail ng-tns-c1798067373-7"
                    >
                      <span
                        _ngcontent-ng-c1798067373=""
                        className="market-nation-name ng-tns-c1798067373-7"
                      >
                        IND
                      </span>
                      <div
                        _ngcontent-ng-c1798067373=""
                        className="market-nation-book ng-tns-c1798067373-7"
                      >
                        <span
                          _ngcontent-ng-c1798067373=""
                          id="book_1"
                          className="float-left book ng-tns-c1798067373-7"
                          style={{ color: "black" }}
                        >
                          <span
                            _ngcontent-ng-c1798067373=""
                            className="ng-tns-c1798067373-7"
                          >
                            {" "}
                            0{" "}
                          </span>
                        </span>
                      </div>
                    </div>
                    <div
                      _ngcontent-ng-c1798067373=""
                      data-title="SUSPENDED"
                      className="Market-odd-row-box ng-tns-c1798067373-7 suspended-table"
                    >
                      <div
                        _ngcontent-ng-c1798067373=""
                        type="button"
                        data-bs-toggle="modal"
                        className="market-odd-box back2 ng-tns-c1798067373-7"
                      >
                        <span
                          _ngcontent-ng-c1798067373=""
                          className="market-odd ng-tns-c1798067373-7"
                        />
                        <span
                          _ngcontent-ng-c1798067373=""
                          className="market-volume ng-tns-c1798067373-7"
                        />
                      </div>
                      <div
                        _ngcontent-ng-c1798067373=""
                        type="button"
                        data-bs-toggle="modal"
                        className="market-odd-box back1 ng-tns-c1798067373-7"
                      >
                        <span
                          _ngcontent-ng-c1798067373=""
                          className="market-odd ng-tns-c1798067373-7"
                        />
                        <span
                          _ngcontent-ng-c1798067373=""
                          className="market-volume ng-tns-c1798067373-7"
                        />
                      </div>
                      <div
                        _ngcontent-ng-c1798067373=""
                        className="back market-odd-box ng-tns-c1798067373-7"
                      >
                        <span
                          _ngcontent-ng-c1798067373=""
                          className="market-odd ng-tns-c1798067373-7"
                        >
                          {" "}
                          0
                        </span>
                        <span
                          _ngcontent-ng-c1798067373=""
                          className="market-volume ng-tns-c1798067373-7"
                        >
                          {" "}
                          0{" "}
                        </span>
                      </div>
                      <div
                        _ngcontent-ng-c1798067373=""
                        className="lay market-odd-box ng-tns-c1798067373-7"
                      >
                        <span
                          _ngcontent-ng-c1798067373=""
                          className="market-odd ng-tns-c1798067373-7"
                        >
                          {" "}
                          0
                        </span>
                        <span
                          _ngcontent-ng-c1798067373=""
                          className="market-volume ng-tns-c1798067373-7"
                        >
                          {" "}
                          0{" "}
                        </span>
                      </div>
                      <div
                        _ngcontent-ng-c1798067373=""
                        type="button"
                        data-bs-toggle="modal"
                        className="market-odd-box lay1 ng-tns-c1798067373-7"
                      >
                        <span
                          _ngcontent-ng-c1798067373=""
                          className="market-odd ng-tns-c1798067373-7"
                        />
                        <span
                          _ngcontent-ng-c1798067373=""
                          className="market-volume ng-tns-c1798067373-7"
                        />
                      </div>
                      <div
                        _ngcontent-ng-c1798067373=""
                        type="button"
                        data-bs-toggle="modal"
                        className="market-odd-box lay2 ng-tns-c1798067373-7"
                      >
                        <span
                          _ngcontent-ng-c1798067373=""
                          className="market-odd ng-tns-c1798067373-7"
                        />
                        <span
                          _ngcontent-ng-c1798067373=""
                          className="market-volume ng-tns-c1798067373-7"
                        />
                      </div>
                    </div>
                  </div>
                  {/**/}
                </div>
              </div>
              <div
                _ngcontent-ng-c1798067373=""
                className="game-market market-6 fancy-monile-ddesign ng-tns-c1798067373-7"
              >
                <div
                  _ngcontent-ng-c1798067373=""
                  className="row row10 ng-tns-c1798067373-7"
                >
                  {/**/}
                </div>
              </div>
            </div>
          </div>
        </div>
      </CasinoPage>
      <div
        _ngcontent-ng-c1798067373=""
        className="sidebar right-sidebar casino-right-sidebar ng-tns-c1798067373-8"
      >
        <div
          _ngcontent-ng-c1798067373=""
          className="sidebar-box place-bet-container d-xl-block d-none ng-tns-c1798067373-8"
        >
          {/**/}
          <div
            _ngcontent-ng-c1798067373=""
            className="sidebar-title ng-tns-c1798067373-8"
          >
            <h4 _ngcontent-ng-c1798067373="" className="ng-tns-c1798067373-8">
              Place Bet
            </h4>
          </div>
          {/**/}
        </div>
        <div
          _ngcontent-ng-c1798067373=""
          className="sidebar-box my-bet-container d-xl-block d-none ng-tns-c1798067373-8"
        >
          <div
            _ngcontent-ng-c1798067373=""
            className="sidebar-title ng-tns-c1798067373-8"
          >
            <h4 _ngcontent-ng-c1798067373="" className="ng-tns-c1798067373-8">
              My Bet
            </h4>
            <a
              _ngcontent-ng-c1798067373=""
              className="sideviewmore ng-tns-c1798067373-8"
            >
              View more
            </a>
          </div>
          <div
            _ngcontent-ng-c1798067373=""
            className="my-bets ng-tns-c1798067373-8"
          >
            <div
              _ngcontent-ng-c1798067373=""
              className="table-responsive w-100 ng-tns-c1798067373-8"
            >
              <table
                _ngcontent-ng-c1798067373=""
                className="table ng-tns-c1798067373-8"
              >
                <thead
                  _ngcontent-ng-c1798067373=""
                  className="ng-tns-c1798067373-8"
                >
                  <tr
                    _ngcontent-ng-c1798067373=""
                    className="ng-tns-c1798067373-8"
                  >
                    <th
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-8"
                    >
                      Matched Bet
                    </th>
                    <th
                      _ngcontent-ng-c1798067373=""
                      className="text-end ng-tns-c1798067373-8"
                    >
                      Odds
                    </th>
                    <th
                      _ngcontent-ng-c1798067373=""
                      className="text-end ng-tns-c1798067373-8"
                    >
                      Stake
                    </th>
                  </tr>
                </thead>
                {/**/}
              </table>
            </div>
          </div>
        </div>
        <div
          _ngcontent-ng-c1798067373=""
          className="sidebar-box sidebar-box-rules my-bet-container ng-tns-c1798067373-8"
        >
          <app-casino-game-rules
            _ngcontent-ng-c1798067373=""
            className="ng-tns-c1798067373-8"
            _nghost-ng-c1761710207=""
          >
            {/**/}
            {/**/}
            {/**/}
            <div
              _ngcontent-ng-c1761710207=""
              className="sidebar-title ng-star-inserted"
            >
              <h4 _ngcontent-ng-c1761710207="">Rules</h4>
            </div>
            <div _ngcontent-ng-c1761710207="" className="row ng-star-inserted">
              <div _ngcontent-ng-c1761710207="" className="col-6 pe-1">
                <div
                  _ngcontent-ng-c1761710207=""
                  className="my-bets cricket5-rules_table"
                >
                  <div _ngcontent-ng-c1761710207="" className="text-center">
                    AUS
                  </div>
                  <div
                    _ngcontent-ng-c1761710207=""
                    className="table-responsive super-over-rule"
                  >
                    <table _ngcontent-ng-c1761710207="" className="table">
                      <thead _ngcontent-ng-c1761710207="">
                        <tr _ngcontent-ng-c1761710207="">
                          <th _ngcontent-ng-c1761710207="">Cards</th>
                          <th _ngcontent-ng-c1761710207="" className="text-end">
                            Value
                          </th>
                        </tr>
                      </thead>
                      <tbody _ngcontent-ng-c1761710207="">
                        <tr _ngcontent-ng-c1761710207="">
                          <td _ngcontent-ng-c1761710207="">
                            <img
                              _ngcontent-ng-c1761710207=""
                              src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/superover/cards/cardA.webp"
                            />
                            <span _ngcontent-ng-c1761710207="" className="mx-2">
                              X
                            </span>
                            10{" "}
                          </td>
                          <td _ngcontent-ng-c1761710207="" className="text-end">
                            1 Run
                          </td>
                        </tr>
                        <tr _ngcontent-ng-c1761710207="">
                          <td _ngcontent-ng-c1761710207="">
                            <img
                              _ngcontent-ng-c1761710207=""
                              src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/superover/cards/card2.webp"
                            />
                            <span _ngcontent-ng-c1761710207="" className="mx-2">
                              X
                            </span>
                            10{" "}
                          </td>
                          <td _ngcontent-ng-c1761710207="" className="text-end">
                            2 Run
                          </td>
                        </tr>
                        <tr _ngcontent-ng-c1761710207="">
                          <td _ngcontent-ng-c1761710207="">
                            <img
                              _ngcontent-ng-c1761710207=""
                              src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/superover/cards/card3.webp"
                            />
                            <span _ngcontent-ng-c1761710207="" className="mx-2">
                              X
                            </span>
                            10{" "}
                          </td>
                          <td _ngcontent-ng-c1761710207="" className="text-end">
                            3 Run
                          </td>
                        </tr>
                        <tr _ngcontent-ng-c1761710207="">
                          <td _ngcontent-ng-c1761710207="">
                            <img
                              _ngcontent-ng-c1761710207=""
                              src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/superover/cards/card4.webp"
                            />
                            <span _ngcontent-ng-c1761710207="" className="mx-2">
                              X
                            </span>
                            10{" "}
                          </td>
                          <td _ngcontent-ng-c1761710207="" className="text-end">
                            4 Run
                          </td>
                        </tr>
                        <tr _ngcontent-ng-c1761710207="">
                          <td _ngcontent-ng-c1761710207="">
                            <img
                              _ngcontent-ng-c1761710207=""
                              src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/superover/cards/card6.webp"
                            />
                            <span _ngcontent-ng-c1761710207="" className="mx-2">
                              X
                            </span>
                            10{" "}
                          </td>
                          <td _ngcontent-ng-c1761710207="" className="text-end">
                            6 Run
                          </td>
                        </tr>
                        <tr _ngcontent-ng-c1761710207="">
                          <td _ngcontent-ng-c1761710207="">
                            <img
                              _ngcontent-ng-c1761710207=""
                              src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/superover/cards/card10.webp"
                            />
                            <span _ngcontent-ng-c1761710207="" className="mx-2">
                              X
                            </span>
                            10{" "}
                          </td>
                          <td _ngcontent-ng-c1761710207="" className="text-end">
                            0 Run
                          </td>
                        </tr>
                        <tr _ngcontent-ng-c1761710207="">
                          <td _ngcontent-ng-c1761710207="">
                            <img
                              _ngcontent-ng-c1761710207=""
                              src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/superover/cards/cardK.webp"
                            />
                            <span _ngcontent-ng-c1761710207="" className="mx-2">
                              X
                            </span>
                            10{" "}
                          </td>
                          <td _ngcontent-ng-c1761710207="" className="text-end">
                            Wicket
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div _ngcontent-ng-c1761710207="" className="col-6 ps-1">
                <div
                  _ngcontent-ng-c1761710207=""
                  className="my-bets cricket5-rules_table"
                >
                  <div _ngcontent-ng-c1761710207="" className="text-center">
                    IND
                  </div>
                  <div
                    _ngcontent-ng-c1761710207=""
                    className="table-responsive super-over-rule"
                  >
                    <table _ngcontent-ng-c1761710207="" className="table">
                      <thead _ngcontent-ng-c1761710207="">
                        <tr _ngcontent-ng-c1761710207="">
                          <th _ngcontent-ng-c1761710207="">Cards</th>
                          <th _ngcontent-ng-c1761710207="" className="text-end">
                            Value
                          </th>
                        </tr>
                      </thead>
                      <tbody _ngcontent-ng-c1761710207="">
                        <tr _ngcontent-ng-c1761710207="">
                          <td _ngcontent-ng-c1761710207="">
                            <img
                              _ngcontent-ng-c1761710207=""
                              src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/superover/cards/cardA.webp"
                            />
                            <span _ngcontent-ng-c1761710207="" className="mx-2">
                              X
                            </span>
                            10{" "}
                          </td>
                          <td _ngcontent-ng-c1761710207="" className="text-end">
                            1 Run
                          </td>
                        </tr>
                        <tr _ngcontent-ng-c1761710207="">
                          <td _ngcontent-ng-c1761710207="">
                            <img
                              _ngcontent-ng-c1761710207=""
                              src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/superover/cards/card2.webp"
                            />
                            <span _ngcontent-ng-c1761710207="" className="mx-2">
                              X
                            </span>
                            10{" "}
                          </td>
                          <td _ngcontent-ng-c1761710207="" className="text-end">
                            2 Run
                          </td>
                        </tr>
                        <tr _ngcontent-ng-c1761710207="">
                          <td _ngcontent-ng-c1761710207="">
                            <img
                              _ngcontent-ng-c1761710207=""
                              src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/superover/cards/card3.webp"
                            />
                            <span _ngcontent-ng-c1761710207="" className="mx-2">
                              X
                            </span>
                            10{" "}
                          </td>
                          <td _ngcontent-ng-c1761710207="" className="text-end">
                            3 Run
                          </td>
                        </tr>
                        <tr _ngcontent-ng-c1761710207="">
                          <td _ngcontent-ng-c1761710207="">
                            <img
                              _ngcontent-ng-c1761710207=""
                              src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/superover/cards/card4.webp"
                            />
                            <span _ngcontent-ng-c1761710207="" className="mx-2">
                              X
                            </span>
                            10{" "}
                          </td>
                          <td _ngcontent-ng-c1761710207="" className="text-end">
                            4 Run
                          </td>
                        </tr>
                        <tr _ngcontent-ng-c1761710207="">
                          <td _ngcontent-ng-c1761710207="">
                            <img
                              _ngcontent-ng-c1761710207=""
                              src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/superover/cards/card6.webp"
                            />
                            <span _ngcontent-ng-c1761710207="" className="mx-2">
                              X
                            </span>
                            10{" "}
                          </td>
                          <td _ngcontent-ng-c1761710207="" className="text-end">
                            6 Run
                          </td>
                        </tr>
                        <tr _ngcontent-ng-c1761710207="">
                          <td _ngcontent-ng-c1761710207="">
                            <img
                              _ngcontent-ng-c1761710207=""
                              src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/superover/cards/card10.webp"
                            />
                            <span _ngcontent-ng-c1761710207="" className="mx-2">
                              X
                            </span>
                            10{" "}
                          </td>
                          <td _ngcontent-ng-c1761710207="" className="text-end">
                            0 Run
                          </td>
                        </tr>
                        <tr _ngcontent-ng-c1761710207="">
                          <td _ngcontent-ng-c1761710207="">
                            <img
                              _ngcontent-ng-c1761710207=""
                              src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/superover/cards/cardK.webp"
                            />
                            <span _ngcontent-ng-c1761710207="" className="mx-2">
                              X
                            </span>
                            10{" "}
                          </td>
                          <td _ngcontent-ng-c1761710207="" className="text-end">
                            Wicket
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            {/**/}
            {/**/}
          </app-casino-game-rules>
        </div>
      </div>
    </>
  );
}
