/* eslint-disable react-hooks/rules-of-hooks */
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { loginAPI } from "../service/AuthService";
import { PUBLIC_KEY } from "../utils/constants";
import { JSEncrypt } from "jsencrypt";
import toast, { Toaster } from "react-hot-toast";
import { generateHash } from "../service";
import { useNavigate } from "react-router-dom";
import Footer from "../includes/footer";
export default function PublicOutletM(props) {
  const navigate = useNavigate();
  const userData = useSelector((state) => state.account.userData);
  const [userName, setUserName] = React.useState("");
  const [password, setPassword] = React.useState("");
  const dispatch = useDispatch();

  const login = async (isDemo = false) => {
    let fp = await generateHash();
    localStorage.setItem("fingerprint", fp);
    let encryptedPassword = new JSEncrypt();
    encryptedPassword.setPublicKey(PUBLIC_KEY);
    encryptedPassword = encryptedPassword.encrypt(password);

    var { data, code, message, response, error } = await loginAPI(
      userName,
      encryptedPassword,
      isDemo
    );
    if (code === 200) {
      dispatch({
        type: "accountData/setUserData",
        payload: response,
      });

      dispatch({
        type: "accountData/setLoginPopSate",
        payload: false,
      });

      dispatch({
        type: "accountData/setChangePopSate",
        payload: response.resetRequired,
      });
      if (response?.mfaEnabled) {
        navigate("/otp", {
          state: {
            userName,
          },
        });
      } else {
        toast.success("Login Successfull");
      }
    } else if (code == 403) {
      toast.error("Invalid User Name or Password");
    } else if (code == 401) {
      toast.error(response);
    } else {
      toast.error("Account is disabled or Something went wrong");
    }
  };
  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      login(false);
    }
  };

  return (
    <div className="wrapper">
      <div className="login-page">
        <div className="login-box">
          <div className="logo-login">
            <a href="/">
              <img src="/logo.png" />
            </a>
          </div>
          <div className="login-form mt-4">
            <h4 className="text-center login-title">
              Login <i className="fas fa-hand-point-down" />
            </h4>
            <form
              onSubmit={(e) => {
                e.preventDefault();
              }}
            >
              <div className="mb-4 input-group position-relative">
                <input
                  name="username"
                  type="text"
                  className="form-control"
                  onChange={(e) => setUserName(e.target.value)}
                  placeholder="Username"
                />
                <span className="input-group-text">
                  <i className="fas fa-user" />
                </span>
              </div>
              <div className="mb-4 input-group position-relative">
                <input
                  name="password"
                  type="password"
                  className="form-control"
                  onChange={(e) => setPassword(e.target.value)}
                  placeholder="Password"
                />
                <span className="input-group-text">
                  <i className="fas fa-key" />
                </span>
              </div>
              <div className="d-grid">
                <button
                  onClick={() => login(false)}
                  onHandleKeyDown={handleKeyDown}
                  className="btn btn-primary btn-block"
                >
                  Login
                  <i className="fas fa-sign-in-alt float-end mt-1" />
                </button>

                <button
                  onClick={() => login(true)}
                  type="button"
                  className="btn btn-primary btn-block mt-2"
                >
                  Login with demo ID
                  <i className="fas fa-sign-in-alt float-end mt-1" />
                </button>
              </div>
              <small className="recaptchaTerms mt-1">
                This site is protected by reCAPTCHA and the Google
                <a href="https://policies.google.com/privacy">
                  Privacy Policy
                </a>{" "}
                and
                <a href="https://policies.google.com/terms">
                  Terms of Service
                </a>{" "}
                apply.
              </small>
              <p className="mt-1" />
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
