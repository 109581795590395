import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAnnouncementAPI, searchAPI } from "../../service/AuthService";
import { useState } from "react";
import moment from "moment";
import ExposureTable from "../../components/ExposureTable";
import InitialRule from "../../components/InitialRule";

const HeaderM = () => {
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.account.userData);
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [announcement, setAnnouncement] = React.useState({});
  const [searchResults, setSearchResults] = useState([]);
  const [isExposureTableOpen, setIsExposureTableOpen] = useState(false);
  const liveMatch = useSelector((state) => state.account.matchList);
  const handleSearch = async (e) => {
    e.preventDefault();
    const { value } = e.target;
    if (value?.length < 3) {
      return;
    }
    const { response, code } = await searchAPI(value);
    if (code == 200) {
      setSearchResults([
        ...response.cricket?.sort((a, b) => {
          // Use toUpperCase() to ignore character casing
          const bandA = a.eventName;
          const bandB = b.eventName;

          let comparison = 0;
          if (bandA > bandB) {
            comparison = 1;
          } else if (bandA < bandB) {
            comparison = -1;
          }
          return comparison;
        }),
        ...response.tennis?.sort((a, b) => {
          // Use toUpperCase() to ignore character casing
          const bandA = a.eventName;
          const bandB = b.eventName;

          let comparison = 0;
          if (bandA > bandB) {
            comparison = 1;
          } else if (bandA < bandB) {
            comparison = -1;
          }
          return comparison;
        }),
        ...response.soccer?.sort((a, b) => {
          // Use toUpperCase() to ignore character casing
          const bandA = a.eventName;
          const bandB = b.eventName;

          let comparison = 0;
          if (bandA > bandB) {
            comparison = 1;
          } else if (bandA < bandB) {
            comparison = -1;
          }
          return comparison;
        }),
      ]);
    }
  };
  const getAnnouncement = async () => {
    const { response, code } = await getAnnouncementAPI();
    if (code == 200) {
      setAnnouncement(response);
    }
  };

  useEffect(() => {
    getAnnouncement();
  }, []);
  return (
    <header
      style={{
        backgroundColor: "black",
      }}
      className="header"
    >
      {isExposureTableOpen && (
        <ExposureTable onClose={setIsExposureTableOpen} />
      )}
      <div className="container-fluid">
        <div className="row row5 pt-1 pb-1">
          <div className="logo col-6">
            <a href="/home" className="">
              <i className="fas fa-home mr-1" />
              <img
                style={{ width: "80px", height: "30px", margin: 1 }}
                src="/logo.png"
                alt="Betboxlive"
              />
            </a>
          </div>
          <div className="col-6 text-right bal-expo">
            <p className="mb-0">
              <i className="fas fa-landmark mr-1" />
              <b>{userData?.balance}</b>
            </p>
            <div className="">
              <span
                onClick={() => setIsExposureTableOpen(true)}
                className="mr-1"
              >
                <u>Exp:{userData?.liability}</u>
              </span>

              <div className="dropdown d-inline-block">
                <a
                  href="#"
                  data-toggle="dropdown"
                  className="dropdown-toggle"
                >
                  <u>{userData?.username}</u>
                </a>
                <div className="dropdown-menu">
                  <a href="/home" className="dropdown-item">
                    Home
                  </a>
                  <a
                    href="/reports/accountstatement"
                    className="dropdown-item"
                  >
                    Account Statement
                  </a>
                  <a href="/reports/profitloss" className="dropdown-item">
                    Profit Loss Report
                  </a>
                  <a href="/reports/bethistory" className="dropdown-item">
                    Bet History
                  </a>
                  <a href="/reports/unsetteledbet" className="dropdown-item">
                    Unsetteled Bet
                  </a>
                  <a href="/reports/casinoresults" className="dropdown-item">
                    Casino Report History
                  </a>
                  <a href="/setting/changebtnvalue" className="dropdown-item">
                    Set Button Values
                  </a>

                  <a href="/settings/security-auth" className="dropdown-item">
                    Security Auth Verification
                  </a>
                  <a href="/setting/changepassword" className="dropdown-item">
                    Change Password
                  </a>
                  {/*    <a href="#" className="dropdown-item">
                    Balance
                    <div className="custom-control custom-checkbox float-right">
                      <input
                        type="checkbox"
                        id="customCheck"
                        className="custom-control-input"
                      />
                      <label
                        htmlFor="customCheck"
                        className="custom-control-label"
                      />
                    </div>
                  </a>
                  <a href="#" className="dropdown-item">
                    Exposure
                    <div className="custom-control custom-checkbox float-right">
                      <input
                        type="checkbox"
                        id="customCheck1"
                        className="custom-control-input"
                      />
                      <label
                        htmlFor="customCheck1"
                        className="custom-control-label"
                      />
                    </div>
                  </a> */}
                  <a href="/rules" className="dropdown-item">
                    Rules
                  </a>
                  <a
                    onClick={() => {
                      window.localStorage.clear();

                      dispatch({
                        type: "accountData/logOut",
                        payload: {},
                      });
                      setTimeout(() => {
                        window.location.href = "/";
                      }, 1000);
                    }}
                    className="dropdown-item mt-2 text-danger"
                  >
                    <b>Logout</b>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row row5 header-bottom">
          <div className="col-12">
            <div className="search-box-container">
              <div className="search-box float-left">
                <input
                  type="text"
                  className={`search_input ${
                    isSearchOpen ? "search_input-hover" : ""
                  } `}
                  onChange={handleSearch}
                />

                <a
                  onClick={() => {
                    setIsSearchOpen(!isSearchOpen);
                    setSearchResults([]);
                  }}
                  className="search_icon"
                >
                  {isSearchOpen ? (
                    <i className="fas fa-times" />
                  ) : (
                    <i className="fas fa-search" />
                  )}
                </a>
              </div>
              {searchResults?.length > 0 && (
                <ul>
                  {searchResults?.map((item) => (
                    <li>
                      <a
                        href={`/game/${item?.sport?.toLowerCase()}/${
                          item.id
                        }`}
                        class=""
                      >
                        <div>
                          <div class="search-game-name">
                            <b>{item?.sport}</b>
                          </div>
                          <div class="game-date text-right">
                            {/* 3/31/2023 12:00:00 AM */}
                            {moment(item?.eventDate).format(
                              "DD/MM/YYYY HH:MM:SS "
                            )}
                          </div>
                          <div class="game-teams">{item?.eventName}</div>
                        </div>
                      </a>
                    </li>
                  ))}
                </ul>
              )}
            </div>
            <marquee
              scrollamount={3}
              className={isSearchOpen ? "searchOpen" : "searchClose"}
            >
              {announcement?.announcement}
            </marquee>
          </div>
        </div>
      </div>
      {/*  <div className="row row5 header-bottom">
        <div className="game-nav-bar">
          <div className="nav nav-tabs ">
            {liveMatch?.cricket
              ?.slice(0, 1)
              ?.map((item) => (
                <div className="nav-item text-center">{item?.eventName}</div>
              ))}
            {liveMatch?.tennis?.slice(0, 1)?.map((item) => (
              <div
                onClick={() => {
                  window.location.href = "/casino/dt20";
                }}
                className="nav-item"
              >
                {item?.eventName}
              </div>
            ))}
            {liveMatch?.soccer?.slice(0, 1)?.map((item) => (
              <div
                onClick={() => {
                  window.location.href = "/casino/dt20";
                }}
                className="nav-item"
              >
                {item?.eventName}
              </div>
            ))}
          </div>
        </div>

      </div> */}
      <div data-v-e0e25fa0="" id="home" className="tab-pane sports active">
        <ul data-v-e0e25fa0="" className="nav nav-tabs game-nav-bar">
          {liveMatch?.cricket?.map((item) => (
            <li data-v-e0e25fa0="" className="nav-item text-center">
              <a
                data-v-e0e25fa0=""
                data-toggle="tab"
                href={`/game/cricket/${item?.cricketId}`}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                className="nav-link active "
              >
                <div>
                  <img
                    data-v-e0e25fa0=""
                    style={{
                      marginRight: "5px",
                    }}
                    src="https://dzm0kbaskt4pv.cloudfront.net/v11/static/mobile/img/gameImg/4.png"
                  />
                </div>
                <div data-v-e0e25fa0="">{item?.eventName}</div>
              </a>
            </li>
          ))}
          {liveMatch?.tennis?.map((item) => (
            <li data-v-e0e25fa0="" className="nav-item text-center">
              <a
                data-v-e0e25fa0=""
                data-toggle="tab"
                href={`/game/tennis/${item?.tennisId}`}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                className="nav-link active "
              >
                <div>
                  <img
                    data-v-e0e25fa0=""
                    style={{
                      marginRight: "5px",
                    }}
                    src="https://dzm0kbaskt4pv.cloudfront.net/v11/static/mobile/img/gameImg/2.png"
                  />
                </div>
                <div data-v-e0e25fa0="">{item?.eventName}</div>
              </a>
            </li>
          ))}
          {liveMatch?.soccer?.map((item) => (
            <li data-v-e0e25fa0="" className="nav-item text-center">
              <a
                data-v-e0e25fa0=""
                data-toggle="tab"
                href={`/game/soccer/${item?.soccerId}`}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                className="nav-link active "
              >
                <div>
                  <img
                    data-v-e0e25fa0=""
                    style={{
                      marginRight: "5px",
                    }}
                    src="https://dzm0kbaskt4pv.cloudfront.net/v11/static/mobile/img/gameImg/1.png"
                  />
                </div>
                <div data-v-e0e25fa0="">{item?.eventName}</div>
              </a>
            </li>
          ))}
        </ul>

        {/**/}
      </div>
    </header>
  );
};

export default HeaderM;
