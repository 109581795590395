import React, { useState } from "react";
import "./index.scoped.css";
import { CASINO_VIDEO_URL } from "../../utils/constants";
import { CASINO_VIDEO } from "./CasinoGeneric";
import { useQuery } from "react-query";
import { useDispatch } from "react-redux";
import { getCasinoOddsAPI } from "../../service/casino";
import CasinoPage from "./components/CasinoPage";
export default function DragonTigerLion() {
  const GAME_ID = "DRAGON_TIGER_20";
  const VIDEO_ID = 3056;
  const dispatch = useDispatch();
  const [selectedType, setSelectedType] = useState("DRAGON");
  const casinoData = useQuery(
    "getCasinoOdds",
    () => getCasinoOddsAPI(GAME_ID),
    {
      refetchInterval: 800,
    }
  );
  return (
    <CasinoPage
      casino={casinoData?.data}
      VIDEO_ID={CASINO_VIDEO.twentyTwentyDTL}
      GAME_ID={GAME_ID}
      shortName={"dtl20"}
      gameName={"2020 DRAGON TIGER LION"}
    >
      <div
        _ngcontent-ng-c1798067373=""
        className="casino-table ng-tns-c1798067373-1 ng-star-inserted"
      >
        <div
          _ngcontent-ng-c1798067373=""
          className="casino-table-box d-none d-md-flex ng-tns-c1798067373-1"
        >
          <div
            _ngcontent-ng-c1798067373=""
            className="casino-table-left-box ng-tns-c1798067373-1"
          >
            <div
              _ngcontent-ng-c1798067373=""
              className="casino-table-header ng-tns-c1798067373-1"
            >
              <div
                _ngcontent-ng-c1798067373=""
                className="casino-nation-detail ng-tns-c1798067373-1"
              />
              <div
                _ngcontent-ng-c1798067373=""
                className="casino-odds-box back ng-tns-c1798067373-1"
              >
                Dragon
              </div>
              <div
                _ngcontent-ng-c1798067373=""
                className="casino-odds-box back ng-tns-c1798067373-1"
              >
                Tiger
              </div>
              <div
                _ngcontent-ng-c1798067373=""
                className="casino-odds-box back ng-tns-c1798067373-1"
              >
                Lion
              </div>
            </div>
            <div
              _ngcontent-ng-c1798067373=""
              className="casino-table-body ng-tns-c1798067373-1"
            >
              <div
                _ngcontent-ng-c1798067373=""
                className="casino-table-row ng-tns-c1798067373-1"
              >
                <div
                  _ngcontent-ng-c1798067373=""
                  className="casino-nation-detail ng-tns-c1798067373-1"
                >
                  <div
                    _ngcontent-ng-c1798067373=""
                    className="casino-nation-name ng-tns-c1798067373-1"
                  >
                    Winner
                  </div>
                  <div
                    _ngcontent-ng-c1798067373=""
                    className="min_max_collapse ng-tns-c1798067373-1"
                  >
                    <a
                      _ngcontent-ng-c1798067373=""
                      data-bs-toggle="collapse"
                      href="#collapserangew2"
                      role="button"
                      aria-expanded="false"
                      aria-controls="collapserangew2"
                      className="minmax-btn collapsed ng-tns-c1798067373-1"
                    >
                      <i
                        _ngcontent-ng-c1798067373=""
                        className="fas fa-info-circle ng-tns-c1798067373-1"
                      />
                    </a>
                    <div
                      _ngcontent-ng-c1798067373=""
                      id="collapserangew2"
                      className="range-collpase collapse ng-tns-c1798067373-1"
                    >
                      <span
                        _ngcontent-ng-c1798067373=""
                        className="min_max_span ms-0 ng-tns-c1798067373-1"
                      >
                        <b
                          _ngcontent-ng-c1798067373=""
                          className="ng-tns-c1798067373-1"
                        >
                          Min:
                        </b>
                        <span
                          _ngcontent-ng-c1798067373=""
                          className="ng-tns-c1798067373-1"
                        >
                          100
                        </span>
                      </span>
                      <span
                        _ngcontent-ng-c1798067373=""
                        className="min_max_span ms-1 ng-tns-c1798067373-1"
                      >
                        <b
                          _ngcontent-ng-c1798067373=""
                          className="ng-tns-c1798067373-1"
                        >
                          Max:
                        </b>
                        <span
                          _ngcontent-ng-c1798067373=""
                          className="ng-tns-c1798067373-1"
                        >
                          100000
                        </span>
                      </span>
                    </div>
                  </div>
                </div>
                <div
                  _ngcontent-ng-c1798067373=""
                  className="ng-tns-c1798067373-1 back casino-odds-box suspended-box ng-star-inserted"
                >
                  <span
                    _ngcontent-ng-c1798067373=""
                    className="casino-odds ng-tns-c1798067373-1"
                  >
                    2.94
                  </span>
                  <p
                    _ngcontent-ng-c1798067373=""
                    className="ng-tns-c1798067373-1"
                  >
                    {" "}
                    0{" "}
                  </p>
                </div>
                <div
                  _ngcontent-ng-c1798067373=""
                  className="ng-tns-c1798067373-1 back casino-odds-box suspended-box ng-star-inserted"
                >
                  <span
                    _ngcontent-ng-c1798067373=""
                    className="casino-odds ng-tns-c1798067373-1"
                  >
                    2.94
                  </span>
                  <p
                    _ngcontent-ng-c1798067373=""
                    className="ng-tns-c1798067373-1"
                  >
                    {" "}
                    0{" "}
                  </p>
                </div>
                <div
                  _ngcontent-ng-c1798067373=""
                  className="ng-tns-c1798067373-1 back casino-odds-box suspended-box ng-star-inserted"
                >
                  <span
                    _ngcontent-ng-c1798067373=""
                    className="casino-odds ng-tns-c1798067373-1"
                  >
                    2.94
                  </span>
                  <p
                    _ngcontent-ng-c1798067373=""
                    className="ng-tns-c1798067373-1"
                  >
                    {" "}
                    0{" "}
                  </p>
                </div>
              </div>
              <div
                _ngcontent-ng-c1798067373=""
                className="casino-table-row ng-tns-c1798067373-1"
              >
                <div
                  _ngcontent-ng-c1798067373=""
                  className="casino-nation-detail ng-tns-c1798067373-1"
                >
                  <div
                    _ngcontent-ng-c1798067373=""
                    className="casino-nation-name ng-tns-c1798067373-1"
                  >
                    {" "}
                    Black
                    <span
                      _ngcontent-ng-c1798067373=""
                      className="card-icon ms-1 ng-tns-c1798067373-1"
                    >
                      <span
                        _ngcontent-ng-c1798067373=""
                        className="card-red ng-tns-c1798067373-1"
                      >
                        <img
                          _ngcontent-ng-c1798067373=""
                          className="ng-tns-c1798067373-1"
                          src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/CC.webp"
                        />
                      </span>
                    </span>
                    <span
                      _ngcontent-ng-c1798067373=""
                      className="card-icon ms-1 ng-tns-c1798067373-1"
                    >
                      <span
                        _ngcontent-ng-c1798067373=""
                        className="card-red ng-tns-c1798067373-1"
                      >
                        <img
                          _ngcontent-ng-c1798067373=""
                          className="ng-tns-c1798067373-1"
                          src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/HH.webp"
                        />
                      </span>
                    </span>
                  </div>
                  <div
                    _ngcontent-ng-c1798067373=""
                    className="min_max_collapse ng-tns-c1798067373-1"
                  >
                    <a
                      _ngcontent-ng-c1798067373=""
                      data-bs-toggle="collapse"
                      href="#collapserangeb2"
                      role="button"
                      aria-expanded="false"
                      aria-controls="collapserangeb2"
                      className="minmax-btn collapsed ng-tns-c1798067373-1"
                    >
                      <i
                        _ngcontent-ng-c1798067373=""
                        className="fas fa-info-circle ng-tns-c1798067373-1"
                      />
                    </a>
                    <div
                      _ngcontent-ng-c1798067373=""
                      id="collapserangeb2"
                      className="range-collpase collapse ng-tns-c1798067373-1"
                    >
                      <span
                        _ngcontent-ng-c1798067373=""
                        className="min_max_span ms-0 ng-tns-c1798067373-1"
                      >
                        <b
                          _ngcontent-ng-c1798067373=""
                          className="ng-tns-c1798067373-1"
                        >
                          Min:
                        </b>
                        <span
                          _ngcontent-ng-c1798067373=""
                          className="ng-tns-c1798067373-1"
                        >
                          100
                        </span>
                      </span>
                      <span
                        _ngcontent-ng-c1798067373=""
                        className="min_max_span ms-1 ng-tns-c1798067373-1"
                      >
                        <b
                          _ngcontent-ng-c1798067373=""
                          className="ng-tns-c1798067373-1"
                        >
                          Max:
                        </b>
                        <span
                          _ngcontent-ng-c1798067373=""
                          className="ng-tns-c1798067373-1"
                        >
                          100000
                        </span>
                      </span>
                    </div>
                  </div>
                </div>
                <div
                  _ngcontent-ng-c1798067373=""
                  className="ng-tns-c1798067373-1 back casino-odds-box suspended-box ng-star-inserted"
                >
                  <span
                    _ngcontent-ng-c1798067373=""
                    className="casino-odds ng-tns-c1798067373-1"
                  >
                    1.97
                  </span>
                  <p
                    _ngcontent-ng-c1798067373=""
                    className="ng-tns-c1798067373-1"
                  >
                    {" "}
                    0{" "}
                  </p>
                </div>
                <div
                  _ngcontent-ng-c1798067373=""
                  className="ng-tns-c1798067373-1 back casino-odds-box suspended-box ng-star-inserted"
                >
                  <span
                    _ngcontent-ng-c1798067373=""
                    className="casino-odds ng-tns-c1798067373-1"
                  >
                    1.97
                  </span>
                  <p
                    _ngcontent-ng-c1798067373=""
                    className="ng-tns-c1798067373-1"
                  >
                    {" "}
                    0{" "}
                  </p>
                </div>
                <div
                  _ngcontent-ng-c1798067373=""
                  className="ng-tns-c1798067373-1 back casino-odds-box suspended-box ng-star-inserted"
                >
                  <span
                    _ngcontent-ng-c1798067373=""
                    className="casino-odds ng-tns-c1798067373-1"
                  >
                    1.97
                  </span>
                  <p
                    _ngcontent-ng-c1798067373=""
                    className="ng-tns-c1798067373-1"
                  >
                    {" "}
                    0{" "}
                  </p>
                </div>
              </div>
              <div
                _ngcontent-ng-c1798067373=""
                className="casino-table-row ng-tns-c1798067373-1"
              >
                <div
                  _ngcontent-ng-c1798067373=""
                  className="casino-nation-detail ng-tns-c1798067373-1"
                >
                  <div
                    _ngcontent-ng-c1798067373=""
                    className="casino-nation-name ng-tns-c1798067373-1"
                  >
                    {" "}
                    Red
                    <span
                      _ngcontent-ng-c1798067373=""
                      className="card-icon ms-1 ng-tns-c1798067373-1"
                    >
                      <span
                        _ngcontent-ng-c1798067373=""
                        className="card-red ng-tns-c1798067373-1"
                      >
                        <img
                          _ngcontent-ng-c1798067373=""
                          className="ng-tns-c1798067373-1"
                          src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/SS.webp"
                        />
                      </span>
                    </span>
                    <span
                      _ngcontent-ng-c1798067373=""
                      className="card-icon ms-1 ng-tns-c1798067373-1"
                    >
                      <span
                        _ngcontent-ng-c1798067373=""
                        className="card-red ng-tns-c1798067373-1"
                      >
                        <img
                          _ngcontent-ng-c1798067373=""
                          className="ng-tns-c1798067373-1"
                          src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/DD.webp"
                        />
                      </span>
                    </span>
                  </div>
                  <div
                    _ngcontent-ng-c1798067373=""
                    className="min_max_collapse ng-tns-c1798067373-1"
                  >
                    <a
                      _ngcontent-ng-c1798067373=""
                      data-bs-toggle="collapse"
                      href="#collapseranger2"
                      role="button"
                      aria-expanded="false"
                      aria-controls="collapseranger2"
                      className="minmax-btn collapsed ng-tns-c1798067373-1"
                    >
                      <i
                        _ngcontent-ng-c1798067373=""
                        className="fas fa-info-circle ng-tns-c1798067373-1"
                      />
                    </a>
                    <div
                      _ngcontent-ng-c1798067373=""
                      id="collapseranger2"
                      className="range-collpase collapse ng-tns-c1798067373-1"
                    >
                      <span
                        _ngcontent-ng-c1798067373=""
                        className="min_max_span ms-0 ng-tns-c1798067373-1"
                      >
                        <b
                          _ngcontent-ng-c1798067373=""
                          className="ng-tns-c1798067373-1"
                        >
                          Min:
                        </b>
                        <span
                          _ngcontent-ng-c1798067373=""
                          className="ng-tns-c1798067373-1"
                        >
                          100
                        </span>
                      </span>
                      <span
                        _ngcontent-ng-c1798067373=""
                        className="min_max_span ms-1 ng-tns-c1798067373-1"
                      >
                        <b
                          _ngcontent-ng-c1798067373=""
                          className="ng-tns-c1798067373-1"
                        >
                          Max:
                        </b>
                        <span
                          _ngcontent-ng-c1798067373=""
                          className="ng-tns-c1798067373-1"
                        >
                          100000
                        </span>
                      </span>
                    </div>
                  </div>
                </div>
                <div
                  _ngcontent-ng-c1798067373=""
                  className="ng-tns-c1798067373-1 back casino-odds-box suspended-box ng-star-inserted"
                >
                  <span
                    _ngcontent-ng-c1798067373=""
                    className="casino-odds ng-tns-c1798067373-1"
                  >
                    1.97
                  </span>
                  <p
                    _ngcontent-ng-c1798067373=""
                    className="ng-tns-c1798067373-1"
                  >
                    {" "}
                    0{" "}
                  </p>
                </div>
                <div
                  _ngcontent-ng-c1798067373=""
                  className="ng-tns-c1798067373-1 back casino-odds-box suspended-box ng-star-inserted"
                >
                  <span
                    _ngcontent-ng-c1798067373=""
                    className="casino-odds ng-tns-c1798067373-1"
                  >
                    1.97
                  </span>
                  <p
                    _ngcontent-ng-c1798067373=""
                    className="ng-tns-c1798067373-1"
                  >
                    {" "}
                    0{" "}
                  </p>
                </div>
                <div
                  _ngcontent-ng-c1798067373=""
                  className="ng-tns-c1798067373-1 back casino-odds-box suspended-box ng-star-inserted"
                >
                  <span
                    _ngcontent-ng-c1798067373=""
                    className="casino-odds ng-tns-c1798067373-1"
                  >
                    1.97
                  </span>
                  <p
                    _ngcontent-ng-c1798067373=""
                    className="ng-tns-c1798067373-1"
                  >
                    {" "}
                    0{" "}
                  </p>
                </div>
              </div>
              <div
                _ngcontent-ng-c1798067373=""
                className="casino-table-row ng-tns-c1798067373-1"
              >
                <div
                  _ngcontent-ng-c1798067373=""
                  className="casino-nation-detail ng-tns-c1798067373-1"
                >
                  <div
                    _ngcontent-ng-c1798067373=""
                    className="casino-nation-name ng-tns-c1798067373-1"
                  >
                    Odd
                  </div>
                  <div
                    _ngcontent-ng-c1798067373=""
                    className="min_max_collapse ng-tns-c1798067373-1"
                  >
                    <a
                      _ngcontent-ng-c1798067373=""
                      data-bs-toggle="collapse"
                      href="#collapserangeodds2"
                      role="button"
                      aria-expanded="false"
                      aria-controls="collapserangeodds2"
                      className="minmax-btn collapsed ng-tns-c1798067373-1"
                    >
                      <i
                        _ngcontent-ng-c1798067373=""
                        className="fas fa-info-circle ng-tns-c1798067373-1"
                      />
                    </a>
                    <div
                      _ngcontent-ng-c1798067373=""
                      id="collapserangeodds2"
                      className="range-collpase collapse ng-tns-c1798067373-1"
                    >
                      <span
                        _ngcontent-ng-c1798067373=""
                        className="min_max_span ms-0 ng-tns-c1798067373-1"
                      >
                        <b
                          _ngcontent-ng-c1798067373=""
                          className="ng-tns-c1798067373-1"
                        >
                          Min:
                        </b>
                        <span
                          _ngcontent-ng-c1798067373=""
                          className="ng-tns-c1798067373-1"
                        >
                          100
                        </span>
                      </span>
                      <span
                        _ngcontent-ng-c1798067373=""
                        className="min_max_span ms-1 ng-tns-c1798067373-1"
                      >
                        <b
                          _ngcontent-ng-c1798067373=""
                          className="ng-tns-c1798067373-1"
                        >
                          Max:
                        </b>
                        <span
                          _ngcontent-ng-c1798067373=""
                          className="ng-tns-c1798067373-1"
                        >
                          100000
                        </span>
                      </span>
                    </div>
                  </div>
                </div>
                <div
                  _ngcontent-ng-c1798067373=""
                  className="ng-tns-c1798067373-1 back casino-odds-box suspended-box ng-star-inserted"
                >
                  <span
                    _ngcontent-ng-c1798067373=""
                    className="casino-odds ng-tns-c1798067373-1"
                  >
                    1.83
                  </span>
                  <p
                    _ngcontent-ng-c1798067373=""
                    className="ng-tns-c1798067373-1"
                  >
                    {" "}
                    0{" "}
                  </p>
                </div>
                <div
                  _ngcontent-ng-c1798067373=""
                  className="ng-tns-c1798067373-1 back casino-odds-box suspended-box ng-star-inserted"
                >
                  <span
                    _ngcontent-ng-c1798067373=""
                    className="casino-odds ng-tns-c1798067373-1"
                  >
                    1.83
                  </span>
                  <p
                    _ngcontent-ng-c1798067373=""
                    className="ng-tns-c1798067373-1"
                  >
                    {" "}
                    0{" "}
                  </p>
                </div>
                <div
                  _ngcontent-ng-c1798067373=""
                  className="ng-tns-c1798067373-1 back casino-odds-box suspended-box ng-star-inserted"
                >
                  <span
                    _ngcontent-ng-c1798067373=""
                    className="casino-odds ng-tns-c1798067373-1"
                  >
                    1.83
                  </span>
                  <p
                    _ngcontent-ng-c1798067373=""
                    className="ng-tns-c1798067373-1"
                  >
                    {" "}
                    0{" "}
                  </p>
                </div>
              </div>
              <div
                _ngcontent-ng-c1798067373=""
                className="casino-table-row ng-tns-c1798067373-1"
              >
                <div
                  _ngcontent-ng-c1798067373=""
                  className="casino-nation-detail ng-tns-c1798067373-1"
                >
                  <div
                    _ngcontent-ng-c1798067373=""
                    className="casino-nation-name ng-tns-c1798067373-1"
                  >
                    Even
                  </div>
                  <div
                    _ngcontent-ng-c1798067373=""
                    className="min_max_collapse ng-tns-c1798067373-1"
                  >
                    <a
                      _ngcontent-ng-c1798067373=""
                      data-bs-toggle="collapse"
                      href="#collapserangeeven2"
                      role="button"
                      aria-expanded="false"
                      aria-controls="collapserangeeven2"
                      className="minmax-btn collapsed ng-tns-c1798067373-1"
                    >
                      <i
                        _ngcontent-ng-c1798067373=""
                        className="fas fa-info-circle ng-tns-c1798067373-1"
                      />
                    </a>
                    <div
                      _ngcontent-ng-c1798067373=""
                      id="collapserangeeven2"
                      className="range-collpase collapse ng-tns-c1798067373-1"
                    >
                      <span
                        _ngcontent-ng-c1798067373=""
                        className="min_max_span ms-0 ng-tns-c1798067373-1"
                      >
                        <b
                          _ngcontent-ng-c1798067373=""
                          className="ng-tns-c1798067373-1"
                        >
                          Min:
                        </b>
                        <span
                          _ngcontent-ng-c1798067373=""
                          className="ng-tns-c1798067373-1"
                        >
                          100
                        </span>
                      </span>
                      <span
                        _ngcontent-ng-c1798067373=""
                        className="min_max_span ms-1 ng-tns-c1798067373-1"
                      >
                        <b
                          _ngcontent-ng-c1798067373=""
                          className="ng-tns-c1798067373-1"
                        >
                          Max:
                        </b>
                        <span
                          _ngcontent-ng-c1798067373=""
                          className="ng-tns-c1798067373-1"
                        >
                          100000
                        </span>
                      </span>
                    </div>
                  </div>
                </div>
                <div
                  _ngcontent-ng-c1798067373=""
                  className="ng-tns-c1798067373-1 back casino-odds-box suspended-box ng-star-inserted"
                >
                  <span
                    _ngcontent-ng-c1798067373=""
                    className="casino-odds ng-tns-c1798067373-1"
                  >
                    2.12
                  </span>
                  <p
                    _ngcontent-ng-c1798067373=""
                    className="ng-tns-c1798067373-1"
                  >
                    {" "}
                    0{" "}
                  </p>
                </div>
                <div
                  _ngcontent-ng-c1798067373=""
                  className="ng-tns-c1798067373-1 back casino-odds-box suspended-box ng-star-inserted"
                >
                  <span
                    _ngcontent-ng-c1798067373=""
                    className="casino-odds ng-tns-c1798067373-1"
                  >
                    2.12
                  </span>
                  <p
                    _ngcontent-ng-c1798067373=""
                    className="ng-tns-c1798067373-1"
                  >
                    {" "}
                    0{" "}
                  </p>
                </div>
                <div
                  _ngcontent-ng-c1798067373=""
                  className="ng-tns-c1798067373-1 back casino-odds-box suspended-box ng-star-inserted"
                >
                  <span
                    _ngcontent-ng-c1798067373=""
                    className="casino-odds ng-tns-c1798067373-1"
                  >
                    2.12
                  </span>
                  <p
                    _ngcontent-ng-c1798067373=""
                    className="ng-tns-c1798067373-1"
                  >
                    {" "}
                    0{" "}
                  </p>
                </div>
              </div>
              <div
                _ngcontent-ng-c1798067373=""
                className="casino-table-row ng-tns-c1798067373-1"
              >
                <div
                  _ngcontent-ng-c1798067373=""
                  className="casino-nation-detail ng-tns-c1798067373-1"
                >
                  <div
                    _ngcontent-ng-c1798067373=""
                    className="casino-nation-name casino-nation-card ng-tns-c1798067373-1"
                  >
                    <img
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-1"
                      src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/A.webp"
                    />
                    <div
                      _ngcontent-ng-c1798067373=""
                      className="min_max_collapse ng-tns-c1798067373-1"
                    >
                      <a
                        _ngcontent-ng-c1798067373=""
                        data-bs-toggle="collapse"
                        href="#collapserange2odds"
                        role="button"
                        aria-expanded="false"
                        aria-controls="collapserange2odds"
                        className="minmax-btn collapsed ng-tns-c1798067373-1"
                      >
                        <i
                          _ngcontent-ng-c1798067373=""
                          className="fas fa-info-circle ng-tns-c1798067373-1"
                        />
                      </a>
                      <div
                        _ngcontent-ng-c1798067373=""
                        id="collapserange2odds"
                        className="range-collpase collapse ng-tns-c1798067373-1"
                      >
                        <span
                          _ngcontent-ng-c1798067373=""
                          className="min_max_span ms-0 ng-tns-c1798067373-1"
                        >
                          <b
                            _ngcontent-ng-c1798067373=""
                            className="ng-tns-c1798067373-1"
                          >
                            Min:
                          </b>
                          <span
                            _ngcontent-ng-c1798067373=""
                            className="ng-tns-c1798067373-1"
                          >
                            100
                          </span>
                        </span>
                        <span
                          _ngcontent-ng-c1798067373=""
                          className="min_max_span ms-1 ng-tns-c1798067373-1"
                        >
                          <b
                            _ngcontent-ng-c1798067373=""
                            className="ng-tns-c1798067373-1"
                          >
                            Max:
                          </b>
                          <span
                            _ngcontent-ng-c1798067373=""
                            className="ng-tns-c1798067373-1"
                          >
                            100000
                          </span>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  _ngcontent-ng-c1798067373=""
                  className="ng-tns-c1798067373-1 back casino-odds-box suspended-box ng-star-inserted"
                >
                  <span
                    _ngcontent-ng-c1798067373=""
                    className="casino-odds ng-tns-c1798067373-1"
                  >
                    12
                  </span>
                  <p
                    _ngcontent-ng-c1798067373=""
                    className="ng-tns-c1798067373-1"
                  >
                    {" "}
                    0{" "}
                  </p>
                </div>
                <div
                  _ngcontent-ng-c1798067373=""
                  className="ng-tns-c1798067373-1 back casino-odds-box suspended-box ng-star-inserted"
                >
                  <span
                    _ngcontent-ng-c1798067373=""
                    className="casino-odds ng-tns-c1798067373-1"
                  >
                    12
                  </span>
                  <p
                    _ngcontent-ng-c1798067373=""
                    className="ng-tns-c1798067373-1"
                  >
                    {" "}
                    0{" "}
                  </p>
                </div>
                <div
                  _ngcontent-ng-c1798067373=""
                  className="ng-tns-c1798067373-1 back casino-odds-box suspended-box ng-star-inserted"
                >
                  <span
                    _ngcontent-ng-c1798067373=""
                    className="casino-odds ng-tns-c1798067373-1"
                  >
                    12
                  </span>
                  <p
                    _ngcontent-ng-c1798067373=""
                    className="ng-tns-c1798067373-1"
                  >
                    {" "}
                    0{" "}
                  </p>
                </div>
              </div>
              <div
                _ngcontent-ng-c1798067373=""
                className="casino-table-row ng-tns-c1798067373-1"
              >
                <div
                  _ngcontent-ng-c1798067373=""
                  className="casino-nation-detail ng-tns-c1798067373-1"
                >
                  <div
                    _ngcontent-ng-c1798067373=""
                    className="casino-nation-name casino-nation-card ng-tns-c1798067373-1"
                  >
                    <img
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-1"
                      src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/2.webp"
                    />
                    <div
                      _ngcontent-ng-c1798067373=""
                      className="min_max_collapse ng-tns-c1798067373-1"
                    >
                      <a
                        _ngcontent-ng-c1798067373=""
                        data-bs-toggle="collapse"
                        href="#collapserange2two"
                        role="button"
                        aria-expanded="false"
                        aria-controls="collapserange2two"
                        className="minmax-btn collapsed ng-tns-c1798067373-1"
                      >
                        <i
                          _ngcontent-ng-c1798067373=""
                          className="fas fa-info-circle ng-tns-c1798067373-1"
                        />
                      </a>
                      <div
                        _ngcontent-ng-c1798067373=""
                        id="collapserange2two"
                        className="range-collpase collapse ng-tns-c1798067373-1"
                      >
                        <span
                          _ngcontent-ng-c1798067373=""
                          className="min_max_span ms-0 ng-tns-c1798067373-1"
                        >
                          <b
                            _ngcontent-ng-c1798067373=""
                            className="ng-tns-c1798067373-1"
                          >
                            Min:
                          </b>
                          <span
                            _ngcontent-ng-c1798067373=""
                            className="ng-tns-c1798067373-1"
                          >
                            100
                          </span>
                        </span>
                        <span
                          _ngcontent-ng-c1798067373=""
                          className="min_max_span ms-1 ng-tns-c1798067373-1"
                        >
                          <b
                            _ngcontent-ng-c1798067373=""
                            className="ng-tns-c1798067373-1"
                          >
                            Max:
                          </b>
                          <span
                            _ngcontent-ng-c1798067373=""
                            className="ng-tns-c1798067373-1"
                          >
                            100000
                          </span>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  _ngcontent-ng-c1798067373=""
                  className="ng-tns-c1798067373-1 back casino-odds-box suspended-box ng-star-inserted"
                >
                  <span
                    _ngcontent-ng-c1798067373=""
                    className="casino-odds ng-tns-c1798067373-1"
                  >
                    12
                  </span>
                  <p
                    _ngcontent-ng-c1798067373=""
                    className="ng-tns-c1798067373-1"
                  >
                    {" "}
                    0{" "}
                  </p>
                </div>
                <div
                  _ngcontent-ng-c1798067373=""
                  className="ng-tns-c1798067373-1 back casino-odds-box suspended-box ng-star-inserted"
                >
                  <span
                    _ngcontent-ng-c1798067373=""
                    className="casino-odds ng-tns-c1798067373-1"
                  >
                    12
                  </span>
                  <p
                    _ngcontent-ng-c1798067373=""
                    className="ng-tns-c1798067373-1"
                  >
                    {" "}
                    0{" "}
                  </p>
                </div>
                <div
                  _ngcontent-ng-c1798067373=""
                  className="ng-tns-c1798067373-1 back casino-odds-box suspended-box ng-star-inserted"
                >
                  <span
                    _ngcontent-ng-c1798067373=""
                    className="casino-odds ng-tns-c1798067373-1"
                  >
                    12
                  </span>
                  <p
                    _ngcontent-ng-c1798067373=""
                    className="ng-tns-c1798067373-1"
                  >
                    {" "}
                    0{" "}
                  </p>
                </div>
              </div>
              <div
                _ngcontent-ng-c1798067373=""
                className="casino-table-row ng-tns-c1798067373-1"
              >
                <div
                  _ngcontent-ng-c1798067373=""
                  className="casino-nation-detail ng-tns-c1798067373-1"
                >
                  <div
                    _ngcontent-ng-c1798067373=""
                    className="casino-nation-name casino-nation-card ng-tns-c1798067373-1"
                  >
                    <img
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-1"
                      src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/3.webp"
                    />
                    <div
                      _ngcontent-ng-c1798067373=""
                      className="min_max_collapse ng-tns-c1798067373-1"
                    >
                      <a
                        _ngcontent-ng-c1798067373=""
                        data-bs-toggle="collapse"
                        href="#collapserange2three"
                        role="button"
                        aria-expanded="false"
                        aria-controls="collapserange2three"
                        className="minmax-btn collapsed ng-tns-c1798067373-1"
                      >
                        <i
                          _ngcontent-ng-c1798067373=""
                          className="fas fa-info-circle ng-tns-c1798067373-1"
                        />
                      </a>
                      <div
                        _ngcontent-ng-c1798067373=""
                        id="collapserange2three"
                        className="range-collpase collapse ng-tns-c1798067373-1"
                      >
                        <span
                          _ngcontent-ng-c1798067373=""
                          className="min_max_span ms-0 ng-tns-c1798067373-1"
                        >
                          <b
                            _ngcontent-ng-c1798067373=""
                            className="ng-tns-c1798067373-1"
                          >
                            Min:
                          </b>
                          <span
                            _ngcontent-ng-c1798067373=""
                            className="ng-tns-c1798067373-1"
                          >
                            100
                          </span>
                        </span>
                        <span
                          _ngcontent-ng-c1798067373=""
                          className="min_max_span ms-1 ng-tns-c1798067373-1"
                        >
                          <b
                            _ngcontent-ng-c1798067373=""
                            className="ng-tns-c1798067373-1"
                          >
                            Max:
                          </b>
                          <span
                            _ngcontent-ng-c1798067373=""
                            className="ng-tns-c1798067373-1"
                          >
                            100000
                          </span>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  _ngcontent-ng-c1798067373=""
                  className="ng-tns-c1798067373-1 back casino-odds-box suspended-box ng-star-inserted"
                >
                  <span
                    _ngcontent-ng-c1798067373=""
                    className="casino-odds ng-tns-c1798067373-1"
                  >
                    12
                  </span>
                  <p
                    _ngcontent-ng-c1798067373=""
                    className="ng-tns-c1798067373-1"
                  >
                    {" "}
                    0{" "}
                  </p>
                </div>
                <div
                  _ngcontent-ng-c1798067373=""
                  className="ng-tns-c1798067373-1 back casino-odds-box suspended-box ng-star-inserted"
                >
                  <span
                    _ngcontent-ng-c1798067373=""
                    className="casino-odds ng-tns-c1798067373-1"
                  >
                    12
                  </span>
                  <p
                    _ngcontent-ng-c1798067373=""
                    className="ng-tns-c1798067373-1"
                  >
                    {" "}
                    0{" "}
                  </p>
                </div>
                <div
                  _ngcontent-ng-c1798067373=""
                  className="ng-tns-c1798067373-1 back casino-odds-box suspended-box ng-star-inserted"
                >
                  <span
                    _ngcontent-ng-c1798067373=""
                    className="casino-odds ng-tns-c1798067373-1"
                  >
                    12
                  </span>
                  <p
                    _ngcontent-ng-c1798067373=""
                    className="ng-tns-c1798067373-1"
                  >
                    {" "}
                    0{" "}
                  </p>
                </div>
              </div>
              <div
                _ngcontent-ng-c1798067373=""
                className="casino-table-row ng-tns-c1798067373-1"
              >
                <div
                  _ngcontent-ng-c1798067373=""
                  className="casino-nation-detail ng-tns-c1798067373-1"
                >
                  <div
                    _ngcontent-ng-c1798067373=""
                    className="casino-nation-name casino-nation-card ng-tns-c1798067373-1"
                  >
                    <img
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-1"
                      src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/4.webp"
                    />
                    <div
                      _ngcontent-ng-c1798067373=""
                      className="min_max_collapse ng-tns-c1798067373-1"
                    >
                      <a
                        _ngcontent-ng-c1798067373=""
                        data-bs-toggle="collapse"
                        href="#collapserange2four"
                        role="button"
                        aria-expanded="false"
                        aria-controls="collapserange2four"
                        className="minmax-btn collapsed ng-tns-c1798067373-1"
                      >
                        <i
                          _ngcontent-ng-c1798067373=""
                          className="fas fa-info-circle ng-tns-c1798067373-1"
                        />
                      </a>
                      <div
                        _ngcontent-ng-c1798067373=""
                        id="collapserange2four"
                        className="range-collpase collapse ng-tns-c1798067373-1"
                      >
                        <span
                          _ngcontent-ng-c1798067373=""
                          className="min_max_span ms-0 ng-tns-c1798067373-1"
                        >
                          <b
                            _ngcontent-ng-c1798067373=""
                            className="ng-tns-c1798067373-1"
                          >
                            Min:
                          </b>
                          <span
                            _ngcontent-ng-c1798067373=""
                            className="ng-tns-c1798067373-1"
                          >
                            100
                          </span>
                        </span>
                        <span
                          _ngcontent-ng-c1798067373=""
                          className="min_max_span ms-1 ng-tns-c1798067373-1"
                        >
                          <b
                            _ngcontent-ng-c1798067373=""
                            className="ng-tns-c1798067373-1"
                          >
                            Max:
                          </b>
                          <span
                            _ngcontent-ng-c1798067373=""
                            className="ng-tns-c1798067373-1"
                          >
                            100000
                          </span>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  _ngcontent-ng-c1798067373=""
                  className="ng-tns-c1798067373-1 back casino-odds-box suspended-box ng-star-inserted"
                >
                  <span
                    _ngcontent-ng-c1798067373=""
                    className="casino-odds ng-tns-c1798067373-1"
                  >
                    12
                  </span>
                  <p
                    _ngcontent-ng-c1798067373=""
                    className="ng-tns-c1798067373-1"
                  >
                    {" "}
                    0{" "}
                  </p>
                </div>
                <div
                  _ngcontent-ng-c1798067373=""
                  className="ng-tns-c1798067373-1 back casino-odds-box suspended-box ng-star-inserted"
                >
                  <span
                    _ngcontent-ng-c1798067373=""
                    className="casino-odds ng-tns-c1798067373-1"
                  >
                    12
                  </span>
                  <p
                    _ngcontent-ng-c1798067373=""
                    className="ng-tns-c1798067373-1"
                  >
                    {" "}
                    0{" "}
                  </p>
                </div>
                <div
                  _ngcontent-ng-c1798067373=""
                  className="ng-tns-c1798067373-1 back casino-odds-box suspended-box ng-star-inserted"
                >
                  <span
                    _ngcontent-ng-c1798067373=""
                    className="casino-odds ng-tns-c1798067373-1"
                  >
                    12
                  </span>
                  <p
                    _ngcontent-ng-c1798067373=""
                    className="ng-tns-c1798067373-1"
                  >
                    {" "}
                    0{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div
            _ngcontent-ng-c1798067373=""
            className="casino-table-right-box ng-tns-c1798067373-1"
          >
            <div
              _ngcontent-ng-c1798067373=""
              className="casino-table-header ng-tns-c1798067373-1"
            >
              <div
                _ngcontent-ng-c1798067373=""
                className="casino-nation-detail ng-tns-c1798067373-1"
              />
              <div
                _ngcontent-ng-c1798067373=""
                className="casino-odds-box back ng-tns-c1798067373-1"
              >
                Dragon
              </div>
              <div
                _ngcontent-ng-c1798067373=""
                className="casino-odds-box back ng-tns-c1798067373-1"
              >
                Tiger
              </div>
              <div
                _ngcontent-ng-c1798067373=""
                className="casino-odds-box back ng-tns-c1798067373-1"
              >
                Lion
              </div>
            </div>
            <div
              _ngcontent-ng-c1798067373=""
              className="casino-table-body ng-tns-c1798067373-1"
            >
              <div
                _ngcontent-ng-c1798067373=""
                className="casino-table-row ng-tns-c1798067373-1"
              >
                <div
                  _ngcontent-ng-c1798067373=""
                  className="casino-nation-detail ng-tns-c1798067373-1"
                >
                  <div
                    _ngcontent-ng-c1798067373=""
                    className="casino-nation-name casino-nation-card ng-tns-c1798067373-1"
                  >
                    <img
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-1"
                      src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/5.webp"
                    />
                    <div
                      _ngcontent-ng-c1798067373=""
                      className="min_max_collapse ng-tns-c1798067373-1"
                    >
                      <a
                        _ngcontent-ng-c1798067373=""
                        data-bs-toggle="collapse"
                        href="#collapserange2five"
                        role="button"
                        aria-expanded="false"
                        aria-controls="collapserange2five"
                        className="minmax-btn collapsed ng-tns-c1798067373-1"
                      >
                        <i
                          _ngcontent-ng-c1798067373=""
                          className="fas fa-info-circle ng-tns-c1798067373-1"
                        />
                      </a>
                      <div
                        _ngcontent-ng-c1798067373=""
                        id="collapserange2five"
                        className="range-collpase collapse ng-tns-c1798067373-1"
                      >
                        <span
                          _ngcontent-ng-c1798067373=""
                          className="min_max_span ms-0 ng-tns-c1798067373-1"
                        >
                          <b
                            _ngcontent-ng-c1798067373=""
                            className="ng-tns-c1798067373-1"
                          >
                            Min:
                          </b>
                          <span
                            _ngcontent-ng-c1798067373=""
                            className="ng-tns-c1798067373-1"
                          >
                            100
                          </span>
                        </span>
                        <span
                          _ngcontent-ng-c1798067373=""
                          className="min_max_span ms-1 ng-tns-c1798067373-1"
                        >
                          <b
                            _ngcontent-ng-c1798067373=""
                            className="ng-tns-c1798067373-1"
                          >
                            Max:
                          </b>
                          <span
                            _ngcontent-ng-c1798067373=""
                            className="ng-tns-c1798067373-1"
                          >
                            100000
                          </span>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  _ngcontent-ng-c1798067373=""
                  className="ng-tns-c1798067373-1 back casino-odds-box suspended-box ng-star-inserted"
                >
                  <span
                    _ngcontent-ng-c1798067373=""
                    className="casino-odds ng-tns-c1798067373-1"
                  >
                    12
                  </span>
                  <p
                    _ngcontent-ng-c1798067373=""
                    className="ng-tns-c1798067373-1"
                  >
                    {" "}
                    0{" "}
                  </p>
                </div>
                <div
                  _ngcontent-ng-c1798067373=""
                  className="ng-tns-c1798067373-1 back casino-odds-box suspended-box ng-star-inserted"
                >
                  <span
                    _ngcontent-ng-c1798067373=""
                    className="casino-odds ng-tns-c1798067373-1"
                  >
                    12
                  </span>
                  <p
                    _ngcontent-ng-c1798067373=""
                    className="ng-tns-c1798067373-1"
                  >
                    {" "}
                    0{" "}
                  </p>
                </div>
                <div
                  _ngcontent-ng-c1798067373=""
                  className="ng-tns-c1798067373-1 back casino-odds-box suspended-box ng-star-inserted"
                >
                  <span
                    _ngcontent-ng-c1798067373=""
                    className="casino-odds ng-tns-c1798067373-1"
                  >
                    12
                  </span>
                  <p
                    _ngcontent-ng-c1798067373=""
                    className="ng-tns-c1798067373-1"
                  >
                    {" "}
                    0{" "}
                  </p>
                </div>
              </div>
              <div
                _ngcontent-ng-c1798067373=""
                className="casino-table-row ng-tns-c1798067373-1"
              >
                <div
                  _ngcontent-ng-c1798067373=""
                  className="casino-nation-detail ng-tns-c1798067373-1"
                >
                  <div
                    _ngcontent-ng-c1798067373=""
                    className="casino-nation-name casino-nation-card ng-tns-c1798067373-1"
                  >
                    <img
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-1"
                      src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/6.webp"
                    />
                    <div
                      _ngcontent-ng-c1798067373=""
                      className="min_max_collapse ng-tns-c1798067373-1"
                    >
                      <a
                        _ngcontent-ng-c1798067373=""
                        data-bs-toggle="collapse"
                        href="#collapserange2six"
                        role="button"
                        aria-expanded="false"
                        aria-controls="collapserange2six"
                        className="minmax-btn collapsed ng-tns-c1798067373-1"
                      >
                        <i
                          _ngcontent-ng-c1798067373=""
                          className="fas fa-info-circle ng-tns-c1798067373-1"
                        />
                      </a>
                      <div
                        _ngcontent-ng-c1798067373=""
                        id="collapserange2six"
                        className="range-collpase collapse ng-tns-c1798067373-1"
                      >
                        <span
                          _ngcontent-ng-c1798067373=""
                          className="min_max_span ms-0 ng-tns-c1798067373-1"
                        >
                          <b
                            _ngcontent-ng-c1798067373=""
                            className="ng-tns-c1798067373-1"
                          >
                            Min:
                          </b>
                          <span
                            _ngcontent-ng-c1798067373=""
                            className="ng-tns-c1798067373-1"
                          >
                            100
                          </span>
                        </span>
                        <span
                          _ngcontent-ng-c1798067373=""
                          className="min_max_span ms-1 ng-tns-c1798067373-1"
                        >
                          <b
                            _ngcontent-ng-c1798067373=""
                            className="ng-tns-c1798067373-1"
                          >
                            Max:
                          </b>
                          <span
                            _ngcontent-ng-c1798067373=""
                            className="ng-tns-c1798067373-1"
                          >
                            100000
                          </span>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  _ngcontent-ng-c1798067373=""
                  className="ng-tns-c1798067373-1 back casino-odds-box suspended-box ng-star-inserted"
                >
                  <span
                    _ngcontent-ng-c1798067373=""
                    className="casino-odds ng-tns-c1798067373-1"
                  >
                    12
                  </span>
                  <p
                    _ngcontent-ng-c1798067373=""
                    className="ng-tns-c1798067373-1"
                  >
                    {" "}
                    0{" "}
                  </p>
                </div>
                <div
                  _ngcontent-ng-c1798067373=""
                  className="ng-tns-c1798067373-1 back casino-odds-box suspended-box ng-star-inserted"
                >
                  <span
                    _ngcontent-ng-c1798067373=""
                    className="casino-odds ng-tns-c1798067373-1"
                  >
                    12
                  </span>
                  <p
                    _ngcontent-ng-c1798067373=""
                    className="ng-tns-c1798067373-1"
                  >
                    {" "}
                    0{" "}
                  </p>
                </div>
                <div
                  _ngcontent-ng-c1798067373=""
                  className="ng-tns-c1798067373-1 back casino-odds-box suspended-box ng-star-inserted"
                >
                  <span
                    _ngcontent-ng-c1798067373=""
                    className="casino-odds ng-tns-c1798067373-1"
                  >
                    12
                  </span>
                  <p
                    _ngcontent-ng-c1798067373=""
                    className="ng-tns-c1798067373-1"
                  >
                    {" "}
                    0{" "}
                  </p>
                </div>
              </div>
              <div
                _ngcontent-ng-c1798067373=""
                className="casino-table-row ng-tns-c1798067373-1"
              >
                <div
                  _ngcontent-ng-c1798067373=""
                  className="casino-nation-detail ng-tns-c1798067373-1"
                >
                  <div
                    _ngcontent-ng-c1798067373=""
                    className="casino-nation-name casino-nation-card ng-tns-c1798067373-1"
                  >
                    <img
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-1"
                      src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/7.webp"
                    />
                    <div
                      _ngcontent-ng-c1798067373=""
                      className="min_max_collapse ng-tns-c1798067373-1"
                    >
                      <a
                        _ngcontent-ng-c1798067373=""
                        data-bs-toggle="collapse"
                        href="#collapserange2seven"
                        role="button"
                        aria-expanded="false"
                        aria-controls="collapserange2seven"
                        className="minmax-btn collapsed ng-tns-c1798067373-1"
                      >
                        <i
                          _ngcontent-ng-c1798067373=""
                          className="fas fa-info-circle ng-tns-c1798067373-1"
                        />
                      </a>
                      <div
                        _ngcontent-ng-c1798067373=""
                        id="collapserange2seven"
                        className="range-collpase collapse ng-tns-c1798067373-1"
                      >
                        <span
                          _ngcontent-ng-c1798067373=""
                          className="min_max_span ms-0 ng-tns-c1798067373-1"
                        >
                          <b
                            _ngcontent-ng-c1798067373=""
                            className="ng-tns-c1798067373-1"
                          >
                            Min:
                          </b>
                          <span
                            _ngcontent-ng-c1798067373=""
                            className="ng-tns-c1798067373-1"
                          >
                            100
                          </span>
                        </span>
                        <span
                          _ngcontent-ng-c1798067373=""
                          className="min_max_span ms-1 ng-tns-c1798067373-1"
                        >
                          <b
                            _ngcontent-ng-c1798067373=""
                            className="ng-tns-c1798067373-1"
                          >
                            Max:
                          </b>
                          <span
                            _ngcontent-ng-c1798067373=""
                            className="ng-tns-c1798067373-1"
                          >
                            100000
                          </span>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  _ngcontent-ng-c1798067373=""
                  className="ng-tns-c1798067373-1 back casino-odds-box suspended-box ng-star-inserted"
                >
                  <span
                    _ngcontent-ng-c1798067373=""
                    className="casino-odds ng-tns-c1798067373-1"
                  >
                    12
                  </span>
                  <p
                    _ngcontent-ng-c1798067373=""
                    className="ng-tns-c1798067373-1"
                  >
                    {" "}
                    0{" "}
                  </p>
                </div>
                <div
                  _ngcontent-ng-c1798067373=""
                  className="ng-tns-c1798067373-1 back casino-odds-box suspended-box ng-star-inserted"
                >
                  <span
                    _ngcontent-ng-c1798067373=""
                    className="casino-odds ng-tns-c1798067373-1"
                  >
                    12
                  </span>
                  <p
                    _ngcontent-ng-c1798067373=""
                    className="ng-tns-c1798067373-1"
                  >
                    {" "}
                    0{" "}
                  </p>
                </div>
                <div
                  _ngcontent-ng-c1798067373=""
                  className="ng-tns-c1798067373-1 back casino-odds-box suspended-box ng-star-inserted"
                >
                  <span
                    _ngcontent-ng-c1798067373=""
                    className="casino-odds ng-tns-c1798067373-1"
                  >
                    12
                  </span>
                  <p
                    _ngcontent-ng-c1798067373=""
                    className="ng-tns-c1798067373-1"
                  >
                    {" "}
                    0{" "}
                  </p>
                </div>
              </div>
              <div
                _ngcontent-ng-c1798067373=""
                className="casino-table-row ng-tns-c1798067373-1"
              >
                <div
                  _ngcontent-ng-c1798067373=""
                  className="casino-nation-detail ng-tns-c1798067373-1"
                >
                  <div
                    _ngcontent-ng-c1798067373=""
                    className="casino-nation-name casino-nation-card ng-tns-c1798067373-1"
                  >
                    <img
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-1"
                      src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/8.webp"
                    />
                    <div
                      _ngcontent-ng-c1798067373=""
                      className="min_max_collapse ng-tns-c1798067373-1"
                    >
                      <a
                        _ngcontent-ng-c1798067373=""
                        data-bs-toggle="collapse"
                        href="#collapserange2eight"
                        role="button"
                        aria-expanded="false"
                        aria-controls="collapserange2eight"
                        className="minmax-btn collapsed ng-tns-c1798067373-1"
                      >
                        <i
                          _ngcontent-ng-c1798067373=""
                          className="fas fa-info-circle ng-tns-c1798067373-1"
                        />
                      </a>
                      <div
                        _ngcontent-ng-c1798067373=""
                        id="collapserange2eight"
                        className="range-collpase collapse ng-tns-c1798067373-1"
                      >
                        <span
                          _ngcontent-ng-c1798067373=""
                          className="min_max_span ms-0 ng-tns-c1798067373-1"
                        >
                          <b
                            _ngcontent-ng-c1798067373=""
                            className="ng-tns-c1798067373-1"
                          >
                            Min:
                          </b>
                          <span
                            _ngcontent-ng-c1798067373=""
                            className="ng-tns-c1798067373-1"
                          >
                            100
                          </span>
                        </span>
                        <span
                          _ngcontent-ng-c1798067373=""
                          className="min_max_span ms-1 ng-tns-c1798067373-1"
                        >
                          <b
                            _ngcontent-ng-c1798067373=""
                            className="ng-tns-c1798067373-1"
                          >
                            Max:
                          </b>
                          <span
                            _ngcontent-ng-c1798067373=""
                            className="ng-tns-c1798067373-1"
                          >
                            100000
                          </span>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  _ngcontent-ng-c1798067373=""
                  className="ng-tns-c1798067373-1 back casino-odds-box suspended-box ng-star-inserted"
                >
                  <span
                    _ngcontent-ng-c1798067373=""
                    className="casino-odds ng-tns-c1798067373-1"
                  >
                    12
                  </span>
                  <p
                    _ngcontent-ng-c1798067373=""
                    className="ng-tns-c1798067373-1"
                  >
                    {" "}
                    0{" "}
                  </p>
                </div>
                <div
                  _ngcontent-ng-c1798067373=""
                  className="ng-tns-c1798067373-1 back casino-odds-box suspended-box ng-star-inserted"
                >
                  <span
                    _ngcontent-ng-c1798067373=""
                    className="casino-odds ng-tns-c1798067373-1"
                  >
                    12
                  </span>
                  <p
                    _ngcontent-ng-c1798067373=""
                    className="ng-tns-c1798067373-1"
                  >
                    {" "}
                    0{" "}
                  </p>
                </div>
                <div
                  _ngcontent-ng-c1798067373=""
                  className="ng-tns-c1798067373-1 back casino-odds-box suspended-box ng-star-inserted"
                >
                  <span
                    _ngcontent-ng-c1798067373=""
                    className="casino-odds ng-tns-c1798067373-1"
                  >
                    12
                  </span>
                  <p
                    _ngcontent-ng-c1798067373=""
                    className="ng-tns-c1798067373-1"
                  >
                    {" "}
                    0{" "}
                  </p>
                </div>
              </div>
              <div
                _ngcontent-ng-c1798067373=""
                className="casino-table-row ng-tns-c1798067373-1"
              >
                <div
                  _ngcontent-ng-c1798067373=""
                  className="casino-nation-detail ng-tns-c1798067373-1"
                >
                  <div
                    _ngcontent-ng-c1798067373=""
                    className="casino-nation-name casino-nation-card ng-tns-c1798067373-1"
                  >
                    <img
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-1"
                      src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/9.webp"
                    />
                    <div
                      _ngcontent-ng-c1798067373=""
                      className="min_max_collapse ng-tns-c1798067373-1"
                    >
                      <a
                        _ngcontent-ng-c1798067373=""
                        data-bs-toggle="collapse"
                        href="#collapserange2night"
                        role="button"
                        aria-expanded="false"
                        aria-controls="collapserange2night"
                        className="minmax-btn collapsed ng-tns-c1798067373-1"
                      >
                        <i
                          _ngcontent-ng-c1798067373=""
                          className="fas fa-info-circle ng-tns-c1798067373-1"
                        />
                      </a>
                      <div
                        _ngcontent-ng-c1798067373=""
                        id="collapserange2night"
                        className="range-collpase collapse ng-tns-c1798067373-1"
                      >
                        <span
                          _ngcontent-ng-c1798067373=""
                          className="min_max_span ms-0 ng-tns-c1798067373-1"
                        >
                          <b
                            _ngcontent-ng-c1798067373=""
                            className="ng-tns-c1798067373-1"
                          >
                            Min:
                          </b>
                          <span
                            _ngcontent-ng-c1798067373=""
                            className="ng-tns-c1798067373-1"
                          >
                            100
                          </span>
                        </span>
                        <span
                          _ngcontent-ng-c1798067373=""
                          className="min_max_span ms-1 ng-tns-c1798067373-1"
                        >
                          <b
                            _ngcontent-ng-c1798067373=""
                            className="ng-tns-c1798067373-1"
                          >
                            Max:
                          </b>
                          <span
                            _ngcontent-ng-c1798067373=""
                            className="ng-tns-c1798067373-1"
                          >
                            100000
                          </span>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  _ngcontent-ng-c1798067373=""
                  className="ng-tns-c1798067373-1 back casino-odds-box suspended-box ng-star-inserted"
                >
                  <span
                    _ngcontent-ng-c1798067373=""
                    className="casino-odds ng-tns-c1798067373-1"
                  >
                    12
                  </span>
                  <p
                    _ngcontent-ng-c1798067373=""
                    className="ng-tns-c1798067373-1"
                  >
                    {" "}
                    0{" "}
                  </p>
                </div>
                <div
                  _ngcontent-ng-c1798067373=""
                  className="ng-tns-c1798067373-1 back casino-odds-box suspended-box ng-star-inserted"
                >
                  <span
                    _ngcontent-ng-c1798067373=""
                    className="casino-odds ng-tns-c1798067373-1"
                  >
                    12
                  </span>
                  <p
                    _ngcontent-ng-c1798067373=""
                    className="ng-tns-c1798067373-1"
                  >
                    {" "}
                    0{" "}
                  </p>
                </div>
                <div
                  _ngcontent-ng-c1798067373=""
                  className="ng-tns-c1798067373-1 back casino-odds-box suspended-box ng-star-inserted"
                >
                  <span
                    _ngcontent-ng-c1798067373=""
                    className="casino-odds ng-tns-c1798067373-1"
                  >
                    12
                  </span>
                  <p
                    _ngcontent-ng-c1798067373=""
                    className="ng-tns-c1798067373-1"
                  >
                    {" "}
                    0{" "}
                  </p>
                </div>
              </div>
              <div
                _ngcontent-ng-c1798067373=""
                className="casino-table-row ng-tns-c1798067373-1"
              >
                <div
                  _ngcontent-ng-c1798067373=""
                  className="casino-nation-detail ng-tns-c1798067373-1"
                >
                  <div
                    _ngcontent-ng-c1798067373=""
                    className="casino-nation-name casino-nation-card ng-tns-c1798067373-1"
                  >
                    <img
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-1"
                      src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/10.webp"
                    />
                    <div
                      _ngcontent-ng-c1798067373=""
                      className="min_max_collapse ng-tns-c1798067373-1"
                    >
                      <a
                        _ngcontent-ng-c1798067373=""
                        data-bs-toggle="collapse"
                        href="#collapserange2ten"
                        role="button"
                        aria-expanded="false"
                        aria-controls="collapserange2ten"
                        className="minmax-btn collapsed ng-tns-c1798067373-1"
                      >
                        <i
                          _ngcontent-ng-c1798067373=""
                          className="fas fa-info-circle ng-tns-c1798067373-1"
                        />
                      </a>
                      <div
                        _ngcontent-ng-c1798067373=""
                        id="collapserange2ten"
                        className="range-collpase collapse ng-tns-c1798067373-1"
                      >
                        <span
                          _ngcontent-ng-c1798067373=""
                          className="min_max_span ms-0 ng-tns-c1798067373-1"
                        >
                          <b
                            _ngcontent-ng-c1798067373=""
                            className="ng-tns-c1798067373-1"
                          >
                            Min:
                          </b>
                          <span
                            _ngcontent-ng-c1798067373=""
                            className="ng-tns-c1798067373-1"
                          >
                            100
                          </span>
                        </span>
                        <span
                          _ngcontent-ng-c1798067373=""
                          className="min_max_span ms-1 ng-tns-c1798067373-1"
                        >
                          <b
                            _ngcontent-ng-c1798067373=""
                            className="ng-tns-c1798067373-1"
                          >
                            Max:
                          </b>
                          <span
                            _ngcontent-ng-c1798067373=""
                            className="ng-tns-c1798067373-1"
                          >
                            100000
                          </span>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  _ngcontent-ng-c1798067373=""
                  className="ng-tns-c1798067373-1 back casino-odds-box suspended-box ng-star-inserted"
                >
                  <span
                    _ngcontent-ng-c1798067373=""
                    className="casino-odds ng-tns-c1798067373-1"
                  >
                    12
                  </span>
                  <p
                    _ngcontent-ng-c1798067373=""
                    className="ng-tns-c1798067373-1"
                  >
                    {" "}
                    0{" "}
                  </p>
                </div>
                <div
                  _ngcontent-ng-c1798067373=""
                  className="ng-tns-c1798067373-1 back casino-odds-box suspended-box ng-star-inserted"
                >
                  <span
                    _ngcontent-ng-c1798067373=""
                    className="casino-odds ng-tns-c1798067373-1"
                  >
                    12
                  </span>
                  <p
                    _ngcontent-ng-c1798067373=""
                    className="ng-tns-c1798067373-1"
                  >
                    {" "}
                    0{" "}
                  </p>
                </div>
                <div
                  _ngcontent-ng-c1798067373=""
                  className="ng-tns-c1798067373-1 back casino-odds-box suspended-box ng-star-inserted"
                >
                  <span
                    _ngcontent-ng-c1798067373=""
                    className="casino-odds ng-tns-c1798067373-1"
                  >
                    12
                  </span>
                  <p
                    _ngcontent-ng-c1798067373=""
                    className="ng-tns-c1798067373-1"
                  >
                    {" "}
                    0{" "}
                  </p>
                </div>
              </div>
              <div
                _ngcontent-ng-c1798067373=""
                className="casino-table-row ng-tns-c1798067373-1"
              >
                <div
                  _ngcontent-ng-c1798067373=""
                  className="casino-nation-detail ng-tns-c1798067373-1"
                >
                  <div
                    _ngcontent-ng-c1798067373=""
                    className="casino-nation-name casino-nation-card ng-tns-c1798067373-1"
                  >
                    <img
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-1"
                      src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/J.webp"
                    />
                    <div
                      _ngcontent-ng-c1798067373=""
                      className="min_max_collapse ng-tns-c1798067373-1"
                    >
                      <a
                        _ngcontent-ng-c1798067373=""
                        data-bs-toggle="collapse"
                        href="#collapserange2elevn"
                        role="button"
                        aria-expanded="false"
                        aria-controls="collapserange2elevn"
                        className="minmax-btn collapsed ng-tns-c1798067373-1"
                      >
                        <i
                          _ngcontent-ng-c1798067373=""
                          className="fas fa-info-circle ng-tns-c1798067373-1"
                        />
                      </a>
                      <div
                        _ngcontent-ng-c1798067373=""
                        id="collapserange2elevn"
                        className="range-collpase collapse ng-tns-c1798067373-1"
                      >
                        <span
                          _ngcontent-ng-c1798067373=""
                          className="min_max_span ms-0 ng-tns-c1798067373-1"
                        >
                          <b
                            _ngcontent-ng-c1798067373=""
                            className="ng-tns-c1798067373-1"
                          >
                            Min:
                          </b>
                          <span
                            _ngcontent-ng-c1798067373=""
                            className="ng-tns-c1798067373-1"
                          >
                            100
                          </span>
                        </span>
                        <span
                          _ngcontent-ng-c1798067373=""
                          className="min_max_span ms-1 ng-tns-c1798067373-1"
                        >
                          <b
                            _ngcontent-ng-c1798067373=""
                            className="ng-tns-c1798067373-1"
                          >
                            Max:
                          </b>
                          <span
                            _ngcontent-ng-c1798067373=""
                            className="ng-tns-c1798067373-1"
                          >
                            100000
                          </span>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  _ngcontent-ng-c1798067373=""
                  className="ng-tns-c1798067373-1 back casino-odds-box suspended-box ng-star-inserted"
                >
                  <span
                    _ngcontent-ng-c1798067373=""
                    className="casino-odds ng-tns-c1798067373-1"
                  >
                    12
                  </span>
                  <p
                    _ngcontent-ng-c1798067373=""
                    className="ng-tns-c1798067373-1"
                  >
                    {" "}
                    0{" "}
                  </p>
                </div>
                <div
                  _ngcontent-ng-c1798067373=""
                  className="ng-tns-c1798067373-1 back casino-odds-box suspended-box ng-star-inserted"
                >
                  <span
                    _ngcontent-ng-c1798067373=""
                    className="casino-odds ng-tns-c1798067373-1"
                  >
                    12
                  </span>
                  <p
                    _ngcontent-ng-c1798067373=""
                    className="ng-tns-c1798067373-1"
                  >
                    {" "}
                    0{" "}
                  </p>
                </div>
                <div
                  _ngcontent-ng-c1798067373=""
                  className="ng-tns-c1798067373-1 back casino-odds-box suspended-box ng-star-inserted"
                >
                  <span
                    _ngcontent-ng-c1798067373=""
                    className="casino-odds ng-tns-c1798067373-1"
                  >
                    12
                  </span>
                  <p
                    _ngcontent-ng-c1798067373=""
                    className="ng-tns-c1798067373-1"
                  >
                    {" "}
                    0{" "}
                  </p>
                </div>
              </div>
              <div
                _ngcontent-ng-c1798067373=""
                className="casino-table-row ng-tns-c1798067373-1"
              >
                <div
                  _ngcontent-ng-c1798067373=""
                  className="casino-nation-detail ng-tns-c1798067373-1"
                >
                  <div
                    _ngcontent-ng-c1798067373=""
                    className="casino-nation-name casino-nation-card ng-tns-c1798067373-1"
                  >
                    <img
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-1"
                      src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/Q.webp"
                    />
                    <div
                      _ngcontent-ng-c1798067373=""
                      className="min_max_collapse ng-tns-c1798067373-1"
                    >
                      <a
                        _ngcontent-ng-c1798067373=""
                        data-bs-toggle="collapse"
                        href="#twelft"
                        role="button"
                        aria-expanded="false"
                        aria-controls="twelft"
                        className="minmax-btn collapsed ng-tns-c1798067373-1"
                      >
                        <i
                          _ngcontent-ng-c1798067373=""
                          className="fas fa-info-circle ng-tns-c1798067373-1"
                        />
                      </a>
                      <div
                        _ngcontent-ng-c1798067373=""
                        id="twelft"
                        className="range-collpase collapse ng-tns-c1798067373-1"
                      >
                        <span
                          _ngcontent-ng-c1798067373=""
                          className="min_max_span ms-0 ng-tns-c1798067373-1"
                        >
                          <b
                            _ngcontent-ng-c1798067373=""
                            className="ng-tns-c1798067373-1"
                          >
                            Min:
                          </b>
                          <span
                            _ngcontent-ng-c1798067373=""
                            className="ng-tns-c1798067373-1"
                          >
                            100
                          </span>
                        </span>
                        <span
                          _ngcontent-ng-c1798067373=""
                          className="min_max_span ms-1 ng-tns-c1798067373-1"
                        >
                          <b
                            _ngcontent-ng-c1798067373=""
                            className="ng-tns-c1798067373-1"
                          >
                            Max:
                          </b>
                          <span
                            _ngcontent-ng-c1798067373=""
                            className="ng-tns-c1798067373-1"
                          >
                            100000
                          </span>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  _ngcontent-ng-c1798067373=""
                  className="ng-tns-c1798067373-1 back casino-odds-box suspended-box ng-star-inserted"
                >
                  <span
                    _ngcontent-ng-c1798067373=""
                    className="casino-odds ng-tns-c1798067373-1"
                  >
                    12
                  </span>
                  <p
                    _ngcontent-ng-c1798067373=""
                    className="ng-tns-c1798067373-1"
                  >
                    {" "}
                    0{" "}
                  </p>
                </div>
                <div
                  _ngcontent-ng-c1798067373=""
                  className="ng-tns-c1798067373-1 back casino-odds-box suspended-box ng-star-inserted"
                >
                  <span
                    _ngcontent-ng-c1798067373=""
                    className="casino-odds ng-tns-c1798067373-1"
                  >
                    12
                  </span>
                  <p
                    _ngcontent-ng-c1798067373=""
                    className="ng-tns-c1798067373-1"
                  >
                    {" "}
                    0{" "}
                  </p>
                </div>
                <div
                  _ngcontent-ng-c1798067373=""
                  className="ng-tns-c1798067373-1 back casino-odds-box suspended-box ng-star-inserted"
                >
                  <span
                    _ngcontent-ng-c1798067373=""
                    className="casino-odds ng-tns-c1798067373-1"
                  >
                    12
                  </span>
                  <p
                    _ngcontent-ng-c1798067373=""
                    className="ng-tns-c1798067373-1"
                  >
                    {" "}
                    0{" "}
                  </p>
                </div>
              </div>
              <div
                _ngcontent-ng-c1798067373=""
                className="casino-table-row ng-tns-c1798067373-1"
              >
                <div
                  _ngcontent-ng-c1798067373=""
                  className="casino-nation-detail ng-tns-c1798067373-1"
                >
                  <div
                    _ngcontent-ng-c1798067373=""
                    className="casino-nation-name casino-nation-card ng-tns-c1798067373-1"
                  >
                    <img
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-1"
                      src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/K.webp"
                    />
                    <div
                      _ngcontent-ng-c1798067373=""
                      className="min_max_collapse ng-tns-c1798067373-1"
                    >
                      <a
                        _ngcontent-ng-c1798067373=""
                        data-bs-toggle="collapse"
                        href="#collapserange2thrteen"
                        role="button"
                        aria-expanded="false"
                        aria-controls="collapserange2thrteen"
                        className="minmax-btn collapsed ng-tns-c1798067373-1"
                      >
                        <i
                          _ngcontent-ng-c1798067373=""
                          className="fas fa-info-circle ng-tns-c1798067373-1"
                        />
                      </a>
                      <div
                        _ngcontent-ng-c1798067373=""
                        id="collapserange2thrteen"
                        className="range-collpase collapse ng-tns-c1798067373-1"
                      >
                        <span
                          _ngcontent-ng-c1798067373=""
                          className="min_max_span ms-0 ng-tns-c1798067373-1"
                        >
                          <b
                            _ngcontent-ng-c1798067373=""
                            className="ng-tns-c1798067373-1"
                          >
                            Min:
                          </b>
                          <span
                            _ngcontent-ng-c1798067373=""
                            className="ng-tns-c1798067373-1"
                          >
                            100
                          </span>
                        </span>
                        <span
                          _ngcontent-ng-c1798067373=""
                          className="min_max_span ms-1 ng-tns-c1798067373-1"
                        >
                          <b
                            _ngcontent-ng-c1798067373=""
                            className="ng-tns-c1798067373-1"
                          >
                            Max:
                          </b>
                          <span
                            _ngcontent-ng-c1798067373=""
                            className="ng-tns-c1798067373-1"
                          >
                            100000
                          </span>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  _ngcontent-ng-c1798067373=""
                  className="ng-tns-c1798067373-1 back casino-odds-box suspended-box ng-star-inserted"
                >
                  <span
                    _ngcontent-ng-c1798067373=""
                    className="casino-odds ng-tns-c1798067373-1"
                  >
                    12
                  </span>
                  <p
                    _ngcontent-ng-c1798067373=""
                    className="ng-tns-c1798067373-1"
                  >
                    {" "}
                    0{" "}
                  </p>
                </div>
                <div
                  _ngcontent-ng-c1798067373=""
                  className="ng-tns-c1798067373-1 back casino-odds-box suspended-box ng-star-inserted"
                >
                  <span
                    _ngcontent-ng-c1798067373=""
                    className="casino-odds ng-tns-c1798067373-1"
                  >
                    12
                  </span>
                  <p
                    _ngcontent-ng-c1798067373=""
                    className="ng-tns-c1798067373-1"
                  >
                    {" "}
                    0{" "}
                  </p>
                </div>
                <div
                  _ngcontent-ng-c1798067373=""
                  className="ng-tns-c1798067373-1 back casino-odds-box suspended-box ng-star-inserted"
                >
                  <span
                    _ngcontent-ng-c1798067373=""
                    className="casino-odds ng-tns-c1798067373-1"
                  >
                    12
                  </span>
                  <p
                    _ngcontent-ng-c1798067373=""
                    className="ng-tns-c1798067373-1"
                  >
                    {" "}
                    0{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ul
          _ngcontent-ng-c1798067373=""
          role="tablist"
          className="d-xl-none nav nav-tabs menu-tabs ng-tns-c1798067373-1"
        >
          <li
            _ngcontent-ng-c1798067373=""
            role="presentation"
            className="nav-item ng-tns-c1798067373-1"
          >
            <button
              _ngcontent-ng-c1798067373=""
              type="button"
              id="uncontrolled-tab-example-tab-0"
              role="tab"
              data-rr-ui-event-key={0}
              aria-controls="uncontrolled-tab-example-tabpane-0"
              aria-selected="true"
              className="ng-tns-c1798067373-1 active nav-link"
            >
              {" "}
              Dragon{" "}
            </button>
          </li>
          <li
            _ngcontent-ng-c1798067373=""
            role="presentation"
            className="nav-item ng-tns-c1798067373-1"
          >
            <button
              _ngcontent-ng-c1798067373=""
              type="button"
              id="uncontrolled-tab-example-tab-1"
              role="tab"
              data-rr-ui-event-key={1}
              aria-controls="uncontrolled-tab-example-tabpane-1"
              tabIndex={-1}
              className="ng-tns-c1798067373-1 nav-link"
            >
              {" "}
              Tiger{" "}
            </button>
          </li>
          <li
            _ngcontent-ng-c1798067373=""
            role="presentation"
            className="nav-item ng-tns-c1798067373-1"
          >
            <button
              _ngcontent-ng-c1798067373=""
              type="button"
              id="uncontrolled-tab-example-tab-2"
              role="tab"
              data-rr-ui-event-key={2}
              aria-controls="uncontrolled-tab-example-tabpane-2"
              tabIndex={-1}
              className="ng-tns-c1798067373-1 nav-link"
            >
              {" "}
              Lion{" "}
            </button>
          </li>
        </ul>
        <div
          _ngcontent-ng-c1798067373=""
          className="tab-content ng-tns-c1798067373-1"
        >
          <div
            _ngcontent-ng-c1798067373=""
            role="tabpanel"
            id="uncontrolled-tab-example-tabpane-0"
            aria-labelledby="uncontrolled-tab-example-tab-0"
            className="ng-tns-c1798067373-1 active d-xl-none fade show tab-pane"
          >
            <div
              _ngcontent-ng-c1798067373=""
              className="casino-table-box ng-tns-c1798067373-1"
            >
              <div
                _ngcontent-ng-c1798067373=""
                className="casino-table-left-box ng-tns-c1798067373-1"
              >
                <div
                  _ngcontent-ng-c1798067373=""
                  className="casino-table-body ng-tns-c1798067373-1"
                >
                  <div
                    _ngcontent-ng-c1798067373=""
                    className="casino-table-row ng-tns-c1798067373-1 ng-star-inserted"
                  >
                    <div
                      _ngcontent-ng-c1798067373=""
                      className="casino-nation-detail ng-tns-c1798067373-1"
                    >
                      <div
                        _ngcontent-ng-c1798067373=""
                        className="casino-nation-name ng-tns-c1798067373-1 ng-star-inserted"
                      >
                        {" "}
                        Winner{" "}
                      </div>

                      <div
                        _ngcontent-ng-c1798067373=""
                        className="min_max_collapse ng-tns-c1798067373-1 ng-star-inserted"
                      >
                        <a
                          _ngcontent-ng-c1798067373=""
                          data-bs-toggle="collapse"
                          role="button"
                          aria-expanded="false"
                          className="minmax-btn collapsed ng-tns-c1798067373-1"
                          href="#collapserangedtll1"
                          aria-controls="collapserangedtll1"
                        >
                          <i
                            _ngcontent-ng-c1798067373=""
                            className="fas fa-info-circle ng-tns-c1798067373-1"
                          />
                        </a>
                        <div
                          _ngcontent-ng-c1798067373=""
                          className="range-collpase collapse ng-tns-c1798067373-1"
                          id="collapserangedtll1"
                        >
                          <span
                            _ngcontent-ng-c1798067373=""
                            className="min_max_span ms-0 ng-tns-c1798067373-1"
                          >
                            <b
                              _ngcontent-ng-c1798067373=""
                              className="ng-tns-c1798067373-1"
                            >
                              Min:
                            </b>
                            <span
                              _ngcontent-ng-c1798067373=""
                              className="ng-tns-c1798067373-1"
                            >
                              100
                            </span>
                          </span>
                          <span
                            _ngcontent-ng-c1798067373=""
                            className="min_max_span ms-1 ng-tns-c1798067373-1"
                          >
                            <b
                              _ngcontent-ng-c1798067373=""
                              className="ng-tns-c1798067373-1"
                            >
                              Max:
                            </b>
                            <span
                              _ngcontent-ng-c1798067373=""
                              className="ng-tns-c1798067373-1"
                            >
                              100000
                            </span>
                          </span>
                        </div>
                      </div>
                    </div>
                    <div
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-1 back casino-odds-box suspended-box"
                    >
                      <span
                        _ngcontent-ng-c1798067373=""
                        className="casino-odds ng-tns-c1798067373-1"
                      >
                        2.94
                      </span>
                      <p
                        _ngcontent-ng-c1798067373=""
                        className="ng-tns-c1798067373-1"
                      >
                        {" "}
                        0{" "}
                      </p>
                    </div>
                  </div>
                  <div
                    _ngcontent-ng-c1798067373=""
                    className="casino-table-row ng-tns-c1798067373-1 ng-star-inserted"
                  >
                    <div
                      _ngcontent-ng-c1798067373=""
                      className="casino-nation-detail ng-tns-c1798067373-1"
                    >
                      <div
                        _ngcontent-ng-c1798067373=""
                        className="casino-nation-name ng-tns-c1798067373-1 ng-star-inserted"
                      >
                        {" "}
                        Black
                        <span
                          _ngcontent-ng-c1798067373=""
                          className="card-icon ms-1 ng-tns-c1798067373-1"
                        >
                          <span
                            _ngcontent-ng-c1798067373=""
                            className="card-red ng-tns-c1798067373-1"
                          >
                            <img
                              _ngcontent-ng-c1798067373=""
                              className="ng-tns-c1798067373-1"
                              src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/SS.webp"
                            />
                          </span>
                        </span>
                        <span
                          _ngcontent-ng-c1798067373=""
                          className="card-icon ms-1 ng-tns-c1798067373-1"
                        >
                          <span
                            _ngcontent-ng-c1798067373=""
                            className="card-red ng-tns-c1798067373-1"
                          >
                            <img
                              _ngcontent-ng-c1798067373=""
                              className="ng-tns-c1798067373-1"
                              src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/CC.webp"
                            />
                          </span>
                        </span>
                      </div>

                      <div
                        _ngcontent-ng-c1798067373=""
                        className="min_max_collapse ng-tns-c1798067373-1 ng-star-inserted"
                      >
                        <a
                          _ngcontent-ng-c1798067373=""
                          data-bs-toggle="collapse"
                          role="button"
                          aria-expanded="false"
                          className="minmax-btn collapsed ng-tns-c1798067373-1"
                          href="#collapserangedtll2"
                          aria-controls="collapserangedtll2"
                        >
                          <i
                            _ngcontent-ng-c1798067373=""
                            className="fas fa-info-circle ng-tns-c1798067373-1"
                          />
                        </a>
                        <div
                          _ngcontent-ng-c1798067373=""
                          className="range-collpase collapse ng-tns-c1798067373-1"
                          id="collapserangedtll2"
                        >
                          <span
                            _ngcontent-ng-c1798067373=""
                            className="min_max_span ms-0 ng-tns-c1798067373-1"
                          >
                            <b
                              _ngcontent-ng-c1798067373=""
                              className="ng-tns-c1798067373-1"
                            >
                              Min:
                            </b>
                            <span
                              _ngcontent-ng-c1798067373=""
                              className="ng-tns-c1798067373-1"
                            >
                              100
                            </span>
                          </span>
                          <span
                            _ngcontent-ng-c1798067373=""
                            className="min_max_span ms-1 ng-tns-c1798067373-1"
                          >
                            <b
                              _ngcontent-ng-c1798067373=""
                              className="ng-tns-c1798067373-1"
                            >
                              Max:
                            </b>
                            <span
                              _ngcontent-ng-c1798067373=""
                              className="ng-tns-c1798067373-1"
                            >
                              100000
                            </span>
                          </span>
                        </div>
                      </div>
                    </div>
                    <div
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-1 back casino-odds-box suspended-box"
                    >
                      <span
                        _ngcontent-ng-c1798067373=""
                        className="casino-odds ng-tns-c1798067373-1"
                      >
                        1.97
                      </span>
                      <p
                        _ngcontent-ng-c1798067373=""
                        className="ng-tns-c1798067373-1"
                      >
                        {" "}
                        0{" "}
                      </p>
                    </div>
                  </div>
                  <div
                    _ngcontent-ng-c1798067373=""
                    className="casino-table-row ng-tns-c1798067373-1 ng-star-inserted"
                  >
                    <div
                      _ngcontent-ng-c1798067373=""
                      className="casino-nation-detail ng-tns-c1798067373-1"
                    >
                      <div
                        _ngcontent-ng-c1798067373=""
                        className="casino-nation-name ng-tns-c1798067373-1 ng-star-inserted"
                      >
                        {" "}
                        Red
                        <span
                          _ngcontent-ng-c1798067373=""
                          className="card-icon ms-1 ng-tns-c1798067373-1"
                        >
                          <span
                            _ngcontent-ng-c1798067373=""
                            className="card-red ng-tns-c1798067373-1"
                          >
                            <img
                              _ngcontent-ng-c1798067373=""
                              className="ng-tns-c1798067373-1"
                              src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/SS.webp"
                            />
                          </span>
                        </span>
                        <span
                          _ngcontent-ng-c1798067373=""
                          className="card-icon ms-1 ng-tns-c1798067373-1"
                        >
                          <span
                            _ngcontent-ng-c1798067373=""
                            className="card-red ng-tns-c1798067373-1"
                          >
                            <img
                              _ngcontent-ng-c1798067373=""
                              className="ng-tns-c1798067373-1"
                              src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/DD.webp"
                            />
                          </span>
                        </span>
                      </div>

                      <div
                        _ngcontent-ng-c1798067373=""
                        className="min_max_collapse ng-tns-c1798067373-1 ng-star-inserted"
                      >
                        <a
                          _ngcontent-ng-c1798067373=""
                          data-bs-toggle="collapse"
                          role="button"
                          aria-expanded="false"
                          className="minmax-btn collapsed ng-tns-c1798067373-1"
                          href="#collapserangedtll3"
                          aria-controls="collapserangedtll3"
                        >
                          <i
                            _ngcontent-ng-c1798067373=""
                            className="fas fa-info-circle ng-tns-c1798067373-1"
                          />
                        </a>
                        <div
                          _ngcontent-ng-c1798067373=""
                          className="range-collpase collapse ng-tns-c1798067373-1"
                          id="collapserangedtll3"
                        >
                          <span
                            _ngcontent-ng-c1798067373=""
                            className="min_max_span ms-0 ng-tns-c1798067373-1"
                          >
                            <b
                              _ngcontent-ng-c1798067373=""
                              className="ng-tns-c1798067373-1"
                            >
                              Min:
                            </b>
                            <span
                              _ngcontent-ng-c1798067373=""
                              className="ng-tns-c1798067373-1"
                            >
                              100
                            </span>
                          </span>
                          <span
                            _ngcontent-ng-c1798067373=""
                            className="min_max_span ms-1 ng-tns-c1798067373-1"
                          >
                            <b
                              _ngcontent-ng-c1798067373=""
                              className="ng-tns-c1798067373-1"
                            >
                              Max:
                            </b>
                            <span
                              _ngcontent-ng-c1798067373=""
                              className="ng-tns-c1798067373-1"
                            >
                              100000
                            </span>
                          </span>
                        </div>
                      </div>
                    </div>
                    <div
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-1 back casino-odds-box suspended-box"
                    >
                      <span
                        _ngcontent-ng-c1798067373=""
                        className="casino-odds ng-tns-c1798067373-1"
                      >
                        1.97
                      </span>
                      <p
                        _ngcontent-ng-c1798067373=""
                        className="ng-tns-c1798067373-1"
                      >
                        {" "}
                        0{" "}
                      </p>
                    </div>
                  </div>
                  <div
                    _ngcontent-ng-c1798067373=""
                    className="casino-table-row ng-tns-c1798067373-1 ng-star-inserted"
                  >
                    <div
                      _ngcontent-ng-c1798067373=""
                      className="casino-nation-detail ng-tns-c1798067373-1"
                    >
                      <div
                        _ngcontent-ng-c1798067373=""
                        className="casino-nation-name ng-tns-c1798067373-1 ng-star-inserted"
                      >
                        {" "}
                        Odd{" "}
                      </div>

                      <div
                        _ngcontent-ng-c1798067373=""
                        className="min_max_collapse ng-tns-c1798067373-1 ng-star-inserted"
                      >
                        <a
                          _ngcontent-ng-c1798067373=""
                          data-bs-toggle="collapse"
                          role="button"
                          aria-expanded="false"
                          className="minmax-btn collapsed ng-tns-c1798067373-1"
                          href="#collapserangedtll4"
                          aria-controls="collapserangedtll4"
                        >
                          <i
                            _ngcontent-ng-c1798067373=""
                            className="fas fa-info-circle ng-tns-c1798067373-1"
                          />
                        </a>
                        <div
                          _ngcontent-ng-c1798067373=""
                          className="range-collpase collapse ng-tns-c1798067373-1"
                          id="collapserangedtll4"
                        >
                          <span
                            _ngcontent-ng-c1798067373=""
                            className="min_max_span ms-0 ng-tns-c1798067373-1"
                          >
                            <b
                              _ngcontent-ng-c1798067373=""
                              className="ng-tns-c1798067373-1"
                            >
                              Min:
                            </b>
                            <span
                              _ngcontent-ng-c1798067373=""
                              className="ng-tns-c1798067373-1"
                            >
                              100
                            </span>
                          </span>
                          <span
                            _ngcontent-ng-c1798067373=""
                            className="min_max_span ms-1 ng-tns-c1798067373-1"
                          >
                            <b
                              _ngcontent-ng-c1798067373=""
                              className="ng-tns-c1798067373-1"
                            >
                              Max:
                            </b>
                            <span
                              _ngcontent-ng-c1798067373=""
                              className="ng-tns-c1798067373-1"
                            >
                              100000
                            </span>
                          </span>
                        </div>
                      </div>
                    </div>
                    <div
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-1 back casino-odds-box suspended-box"
                    >
                      <span
                        _ngcontent-ng-c1798067373=""
                        className="casino-odds ng-tns-c1798067373-1"
                      >
                        1.83
                      </span>
                      <p
                        _ngcontent-ng-c1798067373=""
                        className="ng-tns-c1798067373-1"
                      >
                        {" "}
                        0{" "}
                      </p>
                    </div>
                  </div>
                  <div
                    _ngcontent-ng-c1798067373=""
                    className="casino-table-row ng-tns-c1798067373-1 ng-star-inserted"
                  >
                    <div
                      _ngcontent-ng-c1798067373=""
                      className="casino-nation-detail ng-tns-c1798067373-1"
                    >
                      <div
                        _ngcontent-ng-c1798067373=""
                        className="casino-nation-name ng-tns-c1798067373-1 ng-star-inserted"
                      >
                        {" "}
                        Even{" "}
                      </div>

                      <div
                        _ngcontent-ng-c1798067373=""
                        className="min_max_collapse ng-tns-c1798067373-1 ng-star-inserted"
                      >
                        <a
                          _ngcontent-ng-c1798067373=""
                          data-bs-toggle="collapse"
                          role="button"
                          aria-expanded="false"
                          className="minmax-btn collapsed ng-tns-c1798067373-1"
                          href="#collapserangedtll5"
                          aria-controls="collapserangedtll5"
                        >
                          <i
                            _ngcontent-ng-c1798067373=""
                            className="fas fa-info-circle ng-tns-c1798067373-1"
                          />
                        </a>
                        <div
                          _ngcontent-ng-c1798067373=""
                          className="range-collpase collapse ng-tns-c1798067373-1"
                          id="collapserangedtll5"
                        >
                          <span
                            _ngcontent-ng-c1798067373=""
                            className="min_max_span ms-0 ng-tns-c1798067373-1"
                          >
                            <b
                              _ngcontent-ng-c1798067373=""
                              className="ng-tns-c1798067373-1"
                            >
                              Min:
                            </b>
                            <span
                              _ngcontent-ng-c1798067373=""
                              className="ng-tns-c1798067373-1"
                            >
                              100
                            </span>
                          </span>
                          <span
                            _ngcontent-ng-c1798067373=""
                            className="min_max_span ms-1 ng-tns-c1798067373-1"
                          >
                            <b
                              _ngcontent-ng-c1798067373=""
                              className="ng-tns-c1798067373-1"
                            >
                              Max:
                            </b>
                            <span
                              _ngcontent-ng-c1798067373=""
                              className="ng-tns-c1798067373-1"
                            >
                              100000
                            </span>
                          </span>
                        </div>
                      </div>
                    </div>
                    <div
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-1 back casino-odds-box suspended-box"
                    >
                      <span
                        _ngcontent-ng-c1798067373=""
                        className="casino-odds ng-tns-c1798067373-1"
                      >
                        2.12
                      </span>
                      <p
                        _ngcontent-ng-c1798067373=""
                        className="ng-tns-c1798067373-1"
                      >
                        {" "}
                        0{" "}
                      </p>
                    </div>
                  </div>
                  <div
                    _ngcontent-ng-c1798067373=""
                    className="casino-table-row ng-tns-c1798067373-1 ng-star-inserted"
                  >
                    <div
                      _ngcontent-ng-c1798067373=""
                      className="casino-nation-detail ng-tns-c1798067373-1"
                    >
                      <div
                        _ngcontent-ng-c1798067373=""
                        className="casino-nation-name casino-nation-card ng-tns-c1798067373-1 ng-star-inserted"
                      >
                        <img
                          _ngcontent-ng-c1798067373=""
                          className="ng-tns-c1798067373-1 ng-star-inserted"
                          src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/A.webp"
                        />

                        <div
                          _ngcontent-ng-c1798067373=""
                          className="min_max_collapse ng-tns-c1798067373-1"
                        >
                          <a
                            _ngcontent-ng-c1798067373=""
                            data-bs-toggle="collapse"
                            role="button"
                            aria-expanded="false"
                            className="minmax-btn collapsed ng-tns-c1798067373-1"
                            href="#collapserangedtll6"
                            aria-controls="collapserangedtll6"
                          >
                            <i
                              _ngcontent-ng-c1798067373=""
                              className="fas fa-info-circle ng-tns-c1798067373-1"
                            />
                          </a>
                          <div
                            _ngcontent-ng-c1798067373=""
                            className="range-collpase collapse ng-tns-c1798067373-1"
                            id="collapserangedtll6"
                          >
                            <span
                              _ngcontent-ng-c1798067373=""
                              className="min_max_span ms-0 ng-tns-c1798067373-1"
                            >
                              <b
                                _ngcontent-ng-c1798067373=""
                                className="ng-tns-c1798067373-1"
                              >
                                Min:
                              </b>
                              <span
                                _ngcontent-ng-c1798067373=""
                                className="ng-tns-c1798067373-1"
                              >
                                100
                              </span>
                            </span>
                            <span
                              _ngcontent-ng-c1798067373=""
                              className="min_max_span ms-1 ng-tns-c1798067373-1"
                            >
                              <b
                                _ngcontent-ng-c1798067373=""
                                className="ng-tns-c1798067373-1"
                              >
                                Max:
                              </b>
                              <span
                                _ngcontent-ng-c1798067373=""
                                className="ng-tns-c1798067373-1"
                              >
                                100000
                              </span>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-1 back casino-odds-box suspended-box"
                    >
                      <span
                        _ngcontent-ng-c1798067373=""
                        className="casino-odds ng-tns-c1798067373-1"
                      >
                        12
                      </span>
                      <p
                        _ngcontent-ng-c1798067373=""
                        className="ng-tns-c1798067373-1"
                      >
                        {" "}
                        0{" "}
                      </p>
                    </div>
                  </div>
                  <div
                    _ngcontent-ng-c1798067373=""
                    className="casino-table-row ng-tns-c1798067373-1 ng-star-inserted"
                  >
                    <div
                      _ngcontent-ng-c1798067373=""
                      className="casino-nation-detail ng-tns-c1798067373-1"
                    >
                      <div
                        _ngcontent-ng-c1798067373=""
                        className="casino-nation-name casino-nation-card ng-tns-c1798067373-1 ng-star-inserted"
                      >
                        <img
                          _ngcontent-ng-c1798067373=""
                          className="ng-tns-c1798067373-1 ng-star-inserted"
                          src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/2.webp"
                        />

                        <div
                          _ngcontent-ng-c1798067373=""
                          className="min_max_collapse ng-tns-c1798067373-1"
                        >
                          <a
                            _ngcontent-ng-c1798067373=""
                            data-bs-toggle="collapse"
                            role="button"
                            aria-expanded="false"
                            className="minmax-btn collapsed ng-tns-c1798067373-1"
                            href="#collapserangedtll7"
                            aria-controls="collapserangedtll7"
                          >
                            <i
                              _ngcontent-ng-c1798067373=""
                              className="fas fa-info-circle ng-tns-c1798067373-1"
                            />
                          </a>
                          <div
                            _ngcontent-ng-c1798067373=""
                            className="range-collpase collapse ng-tns-c1798067373-1"
                            id="collapserangedtll7"
                          >
                            <span
                              _ngcontent-ng-c1798067373=""
                              className="min_max_span ms-0 ng-tns-c1798067373-1"
                            >
                              <b
                                _ngcontent-ng-c1798067373=""
                                className="ng-tns-c1798067373-1"
                              >
                                Min:
                              </b>
                              <span
                                _ngcontent-ng-c1798067373=""
                                className="ng-tns-c1798067373-1"
                              >
                                100
                              </span>
                            </span>
                            <span
                              _ngcontent-ng-c1798067373=""
                              className="min_max_span ms-1 ng-tns-c1798067373-1"
                            >
                              <b
                                _ngcontent-ng-c1798067373=""
                                className="ng-tns-c1798067373-1"
                              >
                                Max:
                              </b>
                              <span
                                _ngcontent-ng-c1798067373=""
                                className="ng-tns-c1798067373-1"
                              >
                                100000
                              </span>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-1 back casino-odds-box suspended-box"
                    >
                      <span
                        _ngcontent-ng-c1798067373=""
                        className="casino-odds ng-tns-c1798067373-1"
                      >
                        12
                      </span>
                      <p
                        _ngcontent-ng-c1798067373=""
                        className="ng-tns-c1798067373-1"
                      >
                        {" "}
                        0{" "}
                      </p>
                    </div>
                  </div>
                  <div
                    _ngcontent-ng-c1798067373=""
                    className="casino-table-row ng-tns-c1798067373-1 ng-star-inserted"
                  >
                    <div
                      _ngcontent-ng-c1798067373=""
                      className="casino-nation-detail ng-tns-c1798067373-1"
                    >
                      <div
                        _ngcontent-ng-c1798067373=""
                        className="casino-nation-name casino-nation-card ng-tns-c1798067373-1 ng-star-inserted"
                      >
                        <img
                          _ngcontent-ng-c1798067373=""
                          className="ng-tns-c1798067373-1 ng-star-inserted"
                          src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/3.webp"
                        />

                        <div
                          _ngcontent-ng-c1798067373=""
                          className="min_max_collapse ng-tns-c1798067373-1"
                        >
                          <a
                            _ngcontent-ng-c1798067373=""
                            data-bs-toggle="collapse"
                            role="button"
                            aria-expanded="false"
                            className="minmax-btn collapsed ng-tns-c1798067373-1"
                            href="#collapserangedtll8"
                            aria-controls="collapserangedtll8"
                          >
                            <i
                              _ngcontent-ng-c1798067373=""
                              className="fas fa-info-circle ng-tns-c1798067373-1"
                            />
                          </a>
                          <div
                            _ngcontent-ng-c1798067373=""
                            className="range-collpase collapse ng-tns-c1798067373-1"
                            id="collapserangedtll8"
                          >
                            <span
                              _ngcontent-ng-c1798067373=""
                              className="min_max_span ms-0 ng-tns-c1798067373-1"
                            >
                              <b
                                _ngcontent-ng-c1798067373=""
                                className="ng-tns-c1798067373-1"
                              >
                                Min:
                              </b>
                              <span
                                _ngcontent-ng-c1798067373=""
                                className="ng-tns-c1798067373-1"
                              >
                                100
                              </span>
                            </span>
                            <span
                              _ngcontent-ng-c1798067373=""
                              className="min_max_span ms-1 ng-tns-c1798067373-1"
                            >
                              <b
                                _ngcontent-ng-c1798067373=""
                                className="ng-tns-c1798067373-1"
                              >
                                Max:
                              </b>
                              <span
                                _ngcontent-ng-c1798067373=""
                                className="ng-tns-c1798067373-1"
                              >
                                100000
                              </span>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-1 back casino-odds-box suspended-box"
                    >
                      <span
                        _ngcontent-ng-c1798067373=""
                        className="casino-odds ng-tns-c1798067373-1"
                      >
                        12
                      </span>
                      <p
                        _ngcontent-ng-c1798067373=""
                        className="ng-tns-c1798067373-1"
                      >
                        {" "}
                        0{" "}
                      </p>
                    </div>
                  </div>
                  <div
                    _ngcontent-ng-c1798067373=""
                    className="casino-table-row ng-tns-c1798067373-1 ng-star-inserted"
                  >
                    <div
                      _ngcontent-ng-c1798067373=""
                      className="casino-nation-detail ng-tns-c1798067373-1"
                    >
                      <div
                        _ngcontent-ng-c1798067373=""
                        className="casino-nation-name casino-nation-card ng-tns-c1798067373-1 ng-star-inserted"
                      >
                        <img
                          _ngcontent-ng-c1798067373=""
                          className="ng-tns-c1798067373-1 ng-star-inserted"
                          src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/4.webp"
                        />

                        <div
                          _ngcontent-ng-c1798067373=""
                          className="min_max_collapse ng-tns-c1798067373-1"
                        >
                          <a
                            _ngcontent-ng-c1798067373=""
                            data-bs-toggle="collapse"
                            role="button"
                            aria-expanded="false"
                            className="minmax-btn collapsed ng-tns-c1798067373-1"
                            href="#collapserangedtll9"
                            aria-controls="collapserangedtll9"
                          >
                            <i
                              _ngcontent-ng-c1798067373=""
                              className="fas fa-info-circle ng-tns-c1798067373-1"
                            />
                          </a>
                          <div
                            _ngcontent-ng-c1798067373=""
                            className="range-collpase collapse ng-tns-c1798067373-1"
                            id="collapserangedtll9"
                          >
                            <span
                              _ngcontent-ng-c1798067373=""
                              className="min_max_span ms-0 ng-tns-c1798067373-1"
                            >
                              <b
                                _ngcontent-ng-c1798067373=""
                                className="ng-tns-c1798067373-1"
                              >
                                Min:
                              </b>
                              <span
                                _ngcontent-ng-c1798067373=""
                                className="ng-tns-c1798067373-1"
                              >
                                100
                              </span>
                            </span>
                            <span
                              _ngcontent-ng-c1798067373=""
                              className="min_max_span ms-1 ng-tns-c1798067373-1"
                            >
                              <b
                                _ngcontent-ng-c1798067373=""
                                className="ng-tns-c1798067373-1"
                              >
                                Max:
                              </b>
                              <span
                                _ngcontent-ng-c1798067373=""
                                className="ng-tns-c1798067373-1"
                              >
                                100000
                              </span>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-1 back casino-odds-box suspended-box"
                    >
                      <span
                        _ngcontent-ng-c1798067373=""
                        className="casino-odds ng-tns-c1798067373-1"
                      >
                        12
                      </span>
                      <p
                        _ngcontent-ng-c1798067373=""
                        className="ng-tns-c1798067373-1"
                      >
                        {" "}
                        0{" "}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div
                _ngcontent-ng-c1798067373=""
                className="casino-table-right-box ng-tns-c1798067373-1"
              >
                <div
                  _ngcontent-ng-c1798067373=""
                  className="casino-table-body ng-tns-c1798067373-1"
                >
                  <div
                    _ngcontent-ng-c1798067373=""
                    className="casino-table-row ng-tns-c1798067373-1 ng-star-inserted"
                  >
                    <div
                      _ngcontent-ng-c1798067373=""
                      className="casino-nation-detail ng-tns-c1798067373-1"
                    >
                      <div
                        _ngcontent-ng-c1798067373=""
                        className="casino-nation-name casino-nation-card ng-tns-c1798067373-1"
                      >
                        <img
                          _ngcontent-ng-c1798067373=""
                          className="ng-tns-c1798067373-1 ng-star-inserted"
                          src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/5.webp"
                        />

                        <div
                          _ngcontent-ng-c1798067373=""
                          className="min_max_collapse ng-tns-c1798067373-1"
                        >
                          <a
                            _ngcontent-ng-c1798067373=""
                            data-bs-toggle="collapse"
                            role="button"
                            aria-expanded="false"
                            className="minmax-btn collapsed ng-tns-c1798067373-1"
                            href="#collapserangedtll10"
                            aria-controls="collapserangedtll10"
                          >
                            <i
                              _ngcontent-ng-c1798067373=""
                              className="fas fa-info-circle ng-tns-c1798067373-1"
                            />
                          </a>
                          <div
                            _ngcontent-ng-c1798067373=""
                            className="range-collpase collapse ng-tns-c1798067373-1"
                            id="collapserangedtll10"
                          >
                            <span
                              _ngcontent-ng-c1798067373=""
                              className="min_max_span ms-0 ng-tns-c1798067373-1"
                            >
                              <b
                                _ngcontent-ng-c1798067373=""
                                className="ng-tns-c1798067373-1"
                              >
                                Min:
                              </b>
                              <span
                                _ngcontent-ng-c1798067373=""
                                className="ng-tns-c1798067373-1"
                              >
                                100
                              </span>
                            </span>
                            <span
                              _ngcontent-ng-c1798067373=""
                              className="min_max_span ms-1 ng-tns-c1798067373-1"
                            >
                              <b
                                _ngcontent-ng-c1798067373=""
                                className="ng-tns-c1798067373-1"
                              >
                                Max:
                              </b>
                              <span
                                _ngcontent-ng-c1798067373=""
                                className="ng-tns-c1798067373-1"
                              >
                                100000
                              </span>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-1 back casino-odds-box suspended-box"
                    >
                      <span
                        _ngcontent-ng-c1798067373=""
                        className="casino-odds ng-tns-c1798067373-1"
                      >
                        12
                      </span>
                      <p
                        _ngcontent-ng-c1798067373=""
                        className="ng-tns-c1798067373-1"
                      >
                        {" "}
                        0{" "}
                      </p>
                    </div>
                  </div>
                  <div
                    _ngcontent-ng-c1798067373=""
                    className="casino-table-row ng-tns-c1798067373-1 ng-star-inserted"
                  >
                    <div
                      _ngcontent-ng-c1798067373=""
                      className="casino-nation-detail ng-tns-c1798067373-1"
                    >
                      <div
                        _ngcontent-ng-c1798067373=""
                        className="casino-nation-name casino-nation-card ng-tns-c1798067373-1"
                      >
                        <img
                          _ngcontent-ng-c1798067373=""
                          className="ng-tns-c1798067373-1 ng-star-inserted"
                          src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/6.webp"
                        />

                        <div
                          _ngcontent-ng-c1798067373=""
                          className="min_max_collapse ng-tns-c1798067373-1"
                        >
                          <a
                            _ngcontent-ng-c1798067373=""
                            data-bs-toggle="collapse"
                            role="button"
                            aria-expanded="false"
                            className="minmax-btn collapsed ng-tns-c1798067373-1"
                            href="#collapserangedtll11"
                            aria-controls="collapserangedtll11"
                          >
                            <i
                              _ngcontent-ng-c1798067373=""
                              className="fas fa-info-circle ng-tns-c1798067373-1"
                            />
                          </a>
                          <div
                            _ngcontent-ng-c1798067373=""
                            className="range-collpase collapse ng-tns-c1798067373-1"
                            id="collapserangedtll11"
                          >
                            <span
                              _ngcontent-ng-c1798067373=""
                              className="min_max_span ms-0 ng-tns-c1798067373-1"
                            >
                              <b
                                _ngcontent-ng-c1798067373=""
                                className="ng-tns-c1798067373-1"
                              >
                                Min:
                              </b>
                              <span
                                _ngcontent-ng-c1798067373=""
                                className="ng-tns-c1798067373-1"
                              >
                                100
                              </span>
                            </span>
                            <span
                              _ngcontent-ng-c1798067373=""
                              className="min_max_span ms-1 ng-tns-c1798067373-1"
                            >
                              <b
                                _ngcontent-ng-c1798067373=""
                                className="ng-tns-c1798067373-1"
                              >
                                Max:
                              </b>
                              <span
                                _ngcontent-ng-c1798067373=""
                                className="ng-tns-c1798067373-1"
                              >
                                100000
                              </span>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-1 back casino-odds-box suspended-box"
                    >
                      <span
                        _ngcontent-ng-c1798067373=""
                        className="casino-odds ng-tns-c1798067373-1"
                      >
                        12
                      </span>
                      <p
                        _ngcontent-ng-c1798067373=""
                        className="ng-tns-c1798067373-1"
                      >
                        {" "}
                        0{" "}
                      </p>
                    </div>
                  </div>
                  <div
                    _ngcontent-ng-c1798067373=""
                    className="casino-table-row ng-tns-c1798067373-1 ng-star-inserted"
                  >
                    <div
                      _ngcontent-ng-c1798067373=""
                      className="casino-nation-detail ng-tns-c1798067373-1"
                    >
                      <div
                        _ngcontent-ng-c1798067373=""
                        className="casino-nation-name casino-nation-card ng-tns-c1798067373-1"
                      >
                        <img
                          _ngcontent-ng-c1798067373=""
                          className="ng-tns-c1798067373-1 ng-star-inserted"
                          src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/7.webp"
                        />

                        <div
                          _ngcontent-ng-c1798067373=""
                          className="min_max_collapse ng-tns-c1798067373-1"
                        >
                          <a
                            _ngcontent-ng-c1798067373=""
                            data-bs-toggle="collapse"
                            role="button"
                            aria-expanded="false"
                            className="minmax-btn collapsed ng-tns-c1798067373-1"
                            href="#collapserangedtll12"
                            aria-controls="collapserangedtll12"
                          >
                            <i
                              _ngcontent-ng-c1798067373=""
                              className="fas fa-info-circle ng-tns-c1798067373-1"
                            />
                          </a>
                          <div
                            _ngcontent-ng-c1798067373=""
                            className="range-collpase collapse ng-tns-c1798067373-1"
                            id="collapserangedtll12"
                          >
                            <span
                              _ngcontent-ng-c1798067373=""
                              className="min_max_span ms-0 ng-tns-c1798067373-1"
                            >
                              <b
                                _ngcontent-ng-c1798067373=""
                                className="ng-tns-c1798067373-1"
                              >
                                Min:
                              </b>
                              <span
                                _ngcontent-ng-c1798067373=""
                                className="ng-tns-c1798067373-1"
                              >
                                100
                              </span>
                            </span>
                            <span
                              _ngcontent-ng-c1798067373=""
                              className="min_max_span ms-1 ng-tns-c1798067373-1"
                            >
                              <b
                                _ngcontent-ng-c1798067373=""
                                className="ng-tns-c1798067373-1"
                              >
                                Max:
                              </b>
                              <span
                                _ngcontent-ng-c1798067373=""
                                className="ng-tns-c1798067373-1"
                              >
                                100000
                              </span>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-1 back casino-odds-box suspended-box"
                    >
                      <span
                        _ngcontent-ng-c1798067373=""
                        className="casino-odds ng-tns-c1798067373-1"
                      >
                        12
                      </span>
                      <p
                        _ngcontent-ng-c1798067373=""
                        className="ng-tns-c1798067373-1"
                      >
                        {" "}
                        0{" "}
                      </p>
                    </div>
                  </div>
                  <div
                    _ngcontent-ng-c1798067373=""
                    className="casino-table-row ng-tns-c1798067373-1 ng-star-inserted"
                  >
                    <div
                      _ngcontent-ng-c1798067373=""
                      className="casino-nation-detail ng-tns-c1798067373-1"
                    >
                      <div
                        _ngcontent-ng-c1798067373=""
                        className="casino-nation-name casino-nation-card ng-tns-c1798067373-1"
                      >
                        <img
                          _ngcontent-ng-c1798067373=""
                          className="ng-tns-c1798067373-1 ng-star-inserted"
                          src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/8.webp"
                        />

                        <div
                          _ngcontent-ng-c1798067373=""
                          className="min_max_collapse ng-tns-c1798067373-1"
                        >
                          <a
                            _ngcontent-ng-c1798067373=""
                            data-bs-toggle="collapse"
                            role="button"
                            aria-expanded="false"
                            className="minmax-btn collapsed ng-tns-c1798067373-1"
                            href="#collapserangedtll13"
                            aria-controls="collapserangedtll13"
                          >
                            <i
                              _ngcontent-ng-c1798067373=""
                              className="fas fa-info-circle ng-tns-c1798067373-1"
                            />
                          </a>
                          <div
                            _ngcontent-ng-c1798067373=""
                            className="range-collpase collapse ng-tns-c1798067373-1"
                            id="collapserangedtll13"
                          >
                            <span
                              _ngcontent-ng-c1798067373=""
                              className="min_max_span ms-0 ng-tns-c1798067373-1"
                            >
                              <b
                                _ngcontent-ng-c1798067373=""
                                className="ng-tns-c1798067373-1"
                              >
                                Min:
                              </b>
                              <span
                                _ngcontent-ng-c1798067373=""
                                className="ng-tns-c1798067373-1"
                              >
                                100
                              </span>
                            </span>
                            <span
                              _ngcontent-ng-c1798067373=""
                              className="min_max_span ms-1 ng-tns-c1798067373-1"
                            >
                              <b
                                _ngcontent-ng-c1798067373=""
                                className="ng-tns-c1798067373-1"
                              >
                                Max:
                              </b>
                              <span
                                _ngcontent-ng-c1798067373=""
                                className="ng-tns-c1798067373-1"
                              >
                                100000
                              </span>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-1 back casino-odds-box suspended-box"
                    >
                      <span
                        _ngcontent-ng-c1798067373=""
                        className="casino-odds ng-tns-c1798067373-1"
                      >
                        12
                      </span>
                      <p
                        _ngcontent-ng-c1798067373=""
                        className="ng-tns-c1798067373-1"
                      >
                        {" "}
                        0{" "}
                      </p>
                    </div>
                  </div>
                  <div
                    _ngcontent-ng-c1798067373=""
                    className="casino-table-row ng-tns-c1798067373-1 ng-star-inserted"
                  >
                    <div
                      _ngcontent-ng-c1798067373=""
                      className="casino-nation-detail ng-tns-c1798067373-1"
                    >
                      <div
                        _ngcontent-ng-c1798067373=""
                        className="casino-nation-name casino-nation-card ng-tns-c1798067373-1"
                      >
                        <img
                          _ngcontent-ng-c1798067373=""
                          className="ng-tns-c1798067373-1 ng-star-inserted"
                          src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/9.webp"
                        />

                        <div
                          _ngcontent-ng-c1798067373=""
                          className="min_max_collapse ng-tns-c1798067373-1"
                        >
                          <a
                            _ngcontent-ng-c1798067373=""
                            data-bs-toggle="collapse"
                            role="button"
                            aria-expanded="false"
                            className="minmax-btn collapsed ng-tns-c1798067373-1"
                            href="#collapserangedtll14"
                            aria-controls="collapserangedtll14"
                          >
                            <i
                              _ngcontent-ng-c1798067373=""
                              className="fas fa-info-circle ng-tns-c1798067373-1"
                            />
                          </a>
                          <div
                            _ngcontent-ng-c1798067373=""
                            className="range-collpase collapse ng-tns-c1798067373-1"
                            id="collapserangedtll14"
                          >
                            <span
                              _ngcontent-ng-c1798067373=""
                              className="min_max_span ms-0 ng-tns-c1798067373-1"
                            >
                              <b
                                _ngcontent-ng-c1798067373=""
                                className="ng-tns-c1798067373-1"
                              >
                                Min:
                              </b>
                              <span
                                _ngcontent-ng-c1798067373=""
                                className="ng-tns-c1798067373-1"
                              >
                                100
                              </span>
                            </span>
                            <span
                              _ngcontent-ng-c1798067373=""
                              className="min_max_span ms-1 ng-tns-c1798067373-1"
                            >
                              <b
                                _ngcontent-ng-c1798067373=""
                                className="ng-tns-c1798067373-1"
                              >
                                Max:
                              </b>
                              <span
                                _ngcontent-ng-c1798067373=""
                                className="ng-tns-c1798067373-1"
                              >
                                100000
                              </span>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-1 back casino-odds-box suspended-box"
                    >
                      <span
                        _ngcontent-ng-c1798067373=""
                        className="casino-odds ng-tns-c1798067373-1"
                      >
                        12
                      </span>
                      <p
                        _ngcontent-ng-c1798067373=""
                        className="ng-tns-c1798067373-1"
                      >
                        {" "}
                        0{" "}
                      </p>
                    </div>
                  </div>
                  <div
                    _ngcontent-ng-c1798067373=""
                    className="casino-table-row ng-tns-c1798067373-1 ng-star-inserted"
                  >
                    <div
                      _ngcontent-ng-c1798067373=""
                      className="casino-nation-detail ng-tns-c1798067373-1"
                    >
                      <div
                        _ngcontent-ng-c1798067373=""
                        className="casino-nation-name casino-nation-card ng-tns-c1798067373-1"
                      >
                        <img
                          _ngcontent-ng-c1798067373=""
                          className="ng-tns-c1798067373-1 ng-star-inserted"
                          src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/10.webp"
                        />

                        <div
                          _ngcontent-ng-c1798067373=""
                          className="min_max_collapse ng-tns-c1798067373-1"
                        >
                          <a
                            _ngcontent-ng-c1798067373=""
                            data-bs-toggle="collapse"
                            role="button"
                            aria-expanded="false"
                            className="minmax-btn collapsed ng-tns-c1798067373-1"
                            href="#collapserangedtll15"
                            aria-controls="collapserangedtll15"
                          >
                            <i
                              _ngcontent-ng-c1798067373=""
                              className="fas fa-info-circle ng-tns-c1798067373-1"
                            />
                          </a>
                          <div
                            _ngcontent-ng-c1798067373=""
                            className="range-collpase collapse ng-tns-c1798067373-1"
                            id="collapserangedtll15"
                          >
                            <span
                              _ngcontent-ng-c1798067373=""
                              className="min_max_span ms-0 ng-tns-c1798067373-1"
                            >
                              <b
                                _ngcontent-ng-c1798067373=""
                                className="ng-tns-c1798067373-1"
                              >
                                Min:
                              </b>
                              <span
                                _ngcontent-ng-c1798067373=""
                                className="ng-tns-c1798067373-1"
                              >
                                100
                              </span>
                            </span>
                            <span
                              _ngcontent-ng-c1798067373=""
                              className="min_max_span ms-1 ng-tns-c1798067373-1"
                            >
                              <b
                                _ngcontent-ng-c1798067373=""
                                className="ng-tns-c1798067373-1"
                              >
                                Max:
                              </b>
                              <span
                                _ngcontent-ng-c1798067373=""
                                className="ng-tns-c1798067373-1"
                              >
                                100000
                              </span>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-1 back casino-odds-box suspended-box"
                    >
                      <span
                        _ngcontent-ng-c1798067373=""
                        className="casino-odds ng-tns-c1798067373-1"
                      >
                        12
                      </span>
                      <p
                        _ngcontent-ng-c1798067373=""
                        className="ng-tns-c1798067373-1"
                      >
                        {" "}
                        0{" "}
                      </p>
                    </div>
                  </div>
                  <div
                    _ngcontent-ng-c1798067373=""
                    className="casino-table-row ng-tns-c1798067373-1 ng-star-inserted"
                  >
                    <div
                      _ngcontent-ng-c1798067373=""
                      className="casino-nation-detail ng-tns-c1798067373-1"
                    >
                      <div
                        _ngcontent-ng-c1798067373=""
                        className="casino-nation-name casino-nation-card ng-tns-c1798067373-1"
                      >
                        <img
                          _ngcontent-ng-c1798067373=""
                          className="ng-tns-c1798067373-1 ng-star-inserted"
                          src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/J.webp"
                        />

                        <div
                          _ngcontent-ng-c1798067373=""
                          className="min_max_collapse ng-tns-c1798067373-1"
                        >
                          <a
                            _ngcontent-ng-c1798067373=""
                            data-bs-toggle="collapse"
                            role="button"
                            aria-expanded="false"
                            className="minmax-btn collapsed ng-tns-c1798067373-1"
                            href="#collapserangedtll16"
                            aria-controls="collapserangedtll16"
                          >
                            <i
                              _ngcontent-ng-c1798067373=""
                              className="fas fa-info-circle ng-tns-c1798067373-1"
                            />
                          </a>
                          <div
                            _ngcontent-ng-c1798067373=""
                            className="range-collpase collapse ng-tns-c1798067373-1"
                            id="collapserangedtll16"
                          >
                            <span
                              _ngcontent-ng-c1798067373=""
                              className="min_max_span ms-0 ng-tns-c1798067373-1"
                            >
                              <b
                                _ngcontent-ng-c1798067373=""
                                className="ng-tns-c1798067373-1"
                              >
                                Min:
                              </b>
                              <span
                                _ngcontent-ng-c1798067373=""
                                className="ng-tns-c1798067373-1"
                              >
                                100
                              </span>
                            </span>
                            <span
                              _ngcontent-ng-c1798067373=""
                              className="min_max_span ms-1 ng-tns-c1798067373-1"
                            >
                              <b
                                _ngcontent-ng-c1798067373=""
                                className="ng-tns-c1798067373-1"
                              >
                                Max:
                              </b>
                              <span
                                _ngcontent-ng-c1798067373=""
                                className="ng-tns-c1798067373-1"
                              >
                                100000
                              </span>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-1 back casino-odds-box suspended-box"
                    >
                      <span
                        _ngcontent-ng-c1798067373=""
                        className="casino-odds ng-tns-c1798067373-1"
                      >
                        12
                      </span>
                      <p
                        _ngcontent-ng-c1798067373=""
                        className="ng-tns-c1798067373-1"
                      >
                        {" "}
                        0{" "}
                      </p>
                    </div>
                  </div>
                  <div
                    _ngcontent-ng-c1798067373=""
                    className="casino-table-row ng-tns-c1798067373-1 ng-star-inserted"
                  >
                    <div
                      _ngcontent-ng-c1798067373=""
                      className="casino-nation-detail ng-tns-c1798067373-1"
                    >
                      <div
                        _ngcontent-ng-c1798067373=""
                        className="casino-nation-name casino-nation-card ng-tns-c1798067373-1"
                      >
                        <img
                          _ngcontent-ng-c1798067373=""
                          className="ng-tns-c1798067373-1 ng-star-inserted"
                          src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/Q.webp"
                        />

                        <div
                          _ngcontent-ng-c1798067373=""
                          className="min_max_collapse ng-tns-c1798067373-1"
                        >
                          <a
                            _ngcontent-ng-c1798067373=""
                            data-bs-toggle="collapse"
                            role="button"
                            aria-expanded="false"
                            className="minmax-btn collapsed ng-tns-c1798067373-1"
                            href="#collapserangedtll17"
                            aria-controls="collapserangedtll17"
                          >
                            <i
                              _ngcontent-ng-c1798067373=""
                              className="fas fa-info-circle ng-tns-c1798067373-1"
                            />
                          </a>
                          <div
                            _ngcontent-ng-c1798067373=""
                            className="range-collpase collapse ng-tns-c1798067373-1"
                            id="collapserangedtll17"
                          >
                            <span
                              _ngcontent-ng-c1798067373=""
                              className="min_max_span ms-0 ng-tns-c1798067373-1"
                            >
                              <b
                                _ngcontent-ng-c1798067373=""
                                className="ng-tns-c1798067373-1"
                              >
                                Min:
                              </b>
                              <span
                                _ngcontent-ng-c1798067373=""
                                className="ng-tns-c1798067373-1"
                              >
                                100
                              </span>
                            </span>
                            <span
                              _ngcontent-ng-c1798067373=""
                              className="min_max_span ms-1 ng-tns-c1798067373-1"
                            >
                              <b
                                _ngcontent-ng-c1798067373=""
                                className="ng-tns-c1798067373-1"
                              >
                                Max:
                              </b>
                              <span
                                _ngcontent-ng-c1798067373=""
                                className="ng-tns-c1798067373-1"
                              >
                                100000
                              </span>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-1 back casino-odds-box suspended-box"
                    >
                      <span
                        _ngcontent-ng-c1798067373=""
                        className="casino-odds ng-tns-c1798067373-1"
                      >
                        12
                      </span>
                      <p
                        _ngcontent-ng-c1798067373=""
                        className="ng-tns-c1798067373-1"
                      >
                        {" "}
                        0{" "}
                      </p>
                    </div>
                  </div>
                  <div
                    _ngcontent-ng-c1798067373=""
                    className="casino-table-row ng-tns-c1798067373-1 ng-star-inserted"
                  >
                    <div
                      _ngcontent-ng-c1798067373=""
                      className="casino-nation-detail ng-tns-c1798067373-1"
                    >
                      <div
                        _ngcontent-ng-c1798067373=""
                        className="casino-nation-name casino-nation-card ng-tns-c1798067373-1"
                      >
                        <img
                          _ngcontent-ng-c1798067373=""
                          className="ng-tns-c1798067373-1 ng-star-inserted"
                          src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/K.webp"
                        />

                        <div
                          _ngcontent-ng-c1798067373=""
                          className="min_max_collapse ng-tns-c1798067373-1"
                        >
                          <a
                            _ngcontent-ng-c1798067373=""
                            data-bs-toggle="collapse"
                            role="button"
                            aria-expanded="false"
                            className="minmax-btn collapsed ng-tns-c1798067373-1"
                            href="#collapserangedtll18"
                            aria-controls="collapserangedtll18"
                          >
                            <i
                              _ngcontent-ng-c1798067373=""
                              className="fas fa-info-circle ng-tns-c1798067373-1"
                            />
                          </a>
                          <div
                            _ngcontent-ng-c1798067373=""
                            className="range-collpase collapse ng-tns-c1798067373-1"
                            id="collapserangedtll18"
                          >
                            <span
                              _ngcontent-ng-c1798067373=""
                              className="min_max_span ms-0 ng-tns-c1798067373-1"
                            >
                              <b
                                _ngcontent-ng-c1798067373=""
                                className="ng-tns-c1798067373-1"
                              >
                                Min:
                              </b>
                              <span
                                _ngcontent-ng-c1798067373=""
                                className="ng-tns-c1798067373-1"
                              >
                                100
                              </span>
                            </span>
                            <span
                              _ngcontent-ng-c1798067373=""
                              className="min_max_span ms-1 ng-tns-c1798067373-1"
                            >
                              <b
                                _ngcontent-ng-c1798067373=""
                                className="ng-tns-c1798067373-1"
                              >
                                Max:
                              </b>
                              <span
                                _ngcontent-ng-c1798067373=""
                                className="ng-tns-c1798067373-1"
                              >
                                100000
                              </span>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-1 back casino-odds-box suspended-box"
                    >
                      <span
                        _ngcontent-ng-c1798067373=""
                        className="casino-odds ng-tns-c1798067373-1"
                      >
                        12
                      </span>
                      <p
                        _ngcontent-ng-c1798067373=""
                        className="ng-tns-c1798067373-1"
                      >
                        {" "}
                        0{" "}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            _ngcontent-ng-c1798067373=""
            role="tabpanel"
            id="uncontrolled-tab-example-tabpane-1"
            aria-labelledby="uncontrolled-tab-example-tab-1"
            className="ng-tns-c1798067373-1 d-xl-none fade show tab-pane"
          >
            <div
              _ngcontent-ng-c1798067373=""
              className="casino-table-box ng-tns-c1798067373-1"
            >
              <div
                _ngcontent-ng-c1798067373=""
                className="casino-table-left-box ng-tns-c1798067373-1"
              >
                <div
                  _ngcontent-ng-c1798067373=""
                  className="casino-table-body ng-tns-c1798067373-1"
                >
                  <div
                    _ngcontent-ng-c1798067373=""
                    className="casino-table-row ng-tns-c1798067373-1 ng-star-inserted"
                  >
                    <div
                      _ngcontent-ng-c1798067373=""
                      className="casino-nation-detail ng-tns-c1798067373-1"
                    >
                      <div
                        _ngcontent-ng-c1798067373=""
                        className="casino-nation-name ng-tns-c1798067373-1 ng-star-inserted"
                      >
                        {" "}
                        Winner{" "}
                      </div>

                      <div
                        _ngcontent-ng-c1798067373=""
                        className="min_max_collapse ng-tns-c1798067373-1 ng-star-inserted"
                      >
                        <a
                          _ngcontent-ng-c1798067373=""
                          data-bs-toggle="collapse"
                          role="button"
                          aria-expanded="false"
                          className="minmax-btn collapsed ng-tns-c1798067373-1"
                          href="#collapserangedtll21"
                          aria-controls="collapserangedtll21"
                        >
                          <i
                            _ngcontent-ng-c1798067373=""
                            className="fas fa-info-circle ng-tns-c1798067373-1"
                          />
                        </a>
                        <div
                          _ngcontent-ng-c1798067373=""
                          className="range-collpase collapse ng-tns-c1798067373-1"
                          id="collapserangedtll21"
                        >
                          <span
                            _ngcontent-ng-c1798067373=""
                            className="min_max_span ms-0 ng-tns-c1798067373-1"
                          >
                            <b
                              _ngcontent-ng-c1798067373=""
                              className="ng-tns-c1798067373-1"
                            >
                              Min:
                            </b>
                            <span
                              _ngcontent-ng-c1798067373=""
                              className="ng-tns-c1798067373-1"
                            >
                              100
                            </span>
                          </span>
                          <span
                            _ngcontent-ng-c1798067373=""
                            className="min_max_span ms-1 ng-tns-c1798067373-1"
                          >
                            <b
                              _ngcontent-ng-c1798067373=""
                              className="ng-tns-c1798067373-1"
                            >
                              Max:
                            </b>
                            <span
                              _ngcontent-ng-c1798067373=""
                              className="ng-tns-c1798067373-1"
                            >
                              100000
                            </span>
                          </span>
                        </div>
                      </div>
                    </div>
                    <div
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-1 back casino-odds-box suspended-box"
                    >
                      <span
                        _ngcontent-ng-c1798067373=""
                        className="casino-odds ng-tns-c1798067373-1"
                      >
                        2.94
                      </span>
                      <p
                        _ngcontent-ng-c1798067373=""
                        className="ng-tns-c1798067373-1"
                      >
                        {" "}
                        0{" "}
                      </p>
                    </div>
                  </div>
                  <div
                    _ngcontent-ng-c1798067373=""
                    className="casino-table-row ng-tns-c1798067373-1 ng-star-inserted"
                  >
                    <div
                      _ngcontent-ng-c1798067373=""
                      className="casino-nation-detail ng-tns-c1798067373-1"
                    >
                      <div
                        _ngcontent-ng-c1798067373=""
                        className="casino-nation-name ng-tns-c1798067373-1 ng-star-inserted"
                      >
                        {" "}
                        Black
                        <span
                          _ngcontent-ng-c1798067373=""
                          className="card-icon ms-1 ng-tns-c1798067373-1"
                        >
                          <span
                            _ngcontent-ng-c1798067373=""
                            className="card-red ng-tns-c1798067373-1"
                          >
                            <img
                              _ngcontent-ng-c1798067373=""
                              className="ng-tns-c1798067373-1"
                              src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/SS.webp"
                            />
                          </span>
                        </span>
                        <span
                          _ngcontent-ng-c1798067373=""
                          className="card-icon ms-1 ng-tns-c1798067373-1"
                        >
                          <span
                            _ngcontent-ng-c1798067373=""
                            className="card-red ng-tns-c1798067373-1"
                          >
                            <img
                              _ngcontent-ng-c1798067373=""
                              className="ng-tns-c1798067373-1"
                              src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/CC.webp"
                            />
                          </span>
                        </span>
                      </div>

                      <div
                        _ngcontent-ng-c1798067373=""
                        className="min_max_collapse ng-tns-c1798067373-1 ng-star-inserted"
                      >
                        <a
                          _ngcontent-ng-c1798067373=""
                          data-bs-toggle="collapse"
                          role="button"
                          aria-expanded="false"
                          className="minmax-btn collapsed ng-tns-c1798067373-1"
                          href="#collapserangedtll22"
                          aria-controls="collapserangedtll22"
                        >
                          <i
                            _ngcontent-ng-c1798067373=""
                            className="fas fa-info-circle ng-tns-c1798067373-1"
                          />
                        </a>
                        <div
                          _ngcontent-ng-c1798067373=""
                          className="range-collpase collapse ng-tns-c1798067373-1"
                          id="collapserangedtll22"
                        >
                          <span
                            _ngcontent-ng-c1798067373=""
                            className="min_max_span ms-0 ng-tns-c1798067373-1"
                          >
                            <b
                              _ngcontent-ng-c1798067373=""
                              className="ng-tns-c1798067373-1"
                            >
                              Min:
                            </b>
                            <span
                              _ngcontent-ng-c1798067373=""
                              className="ng-tns-c1798067373-1"
                            >
                              100
                            </span>
                          </span>
                          <span
                            _ngcontent-ng-c1798067373=""
                            className="min_max_span ms-1 ng-tns-c1798067373-1"
                          >
                            <b
                              _ngcontent-ng-c1798067373=""
                              className="ng-tns-c1798067373-1"
                            >
                              Max:
                            </b>
                            <span
                              _ngcontent-ng-c1798067373=""
                              className="ng-tns-c1798067373-1"
                            >
                              100000
                            </span>
                          </span>
                        </div>
                      </div>
                    </div>
                    <div
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-1 back casino-odds-box suspended-box"
                    >
                      <span
                        _ngcontent-ng-c1798067373=""
                        className="casino-odds ng-tns-c1798067373-1"
                      >
                        1.97
                      </span>
                      <p
                        _ngcontent-ng-c1798067373=""
                        className="ng-tns-c1798067373-1"
                      >
                        {" "}
                        0{" "}
                      </p>
                    </div>
                  </div>
                  <div
                    _ngcontent-ng-c1798067373=""
                    className="casino-table-row ng-tns-c1798067373-1 ng-star-inserted"
                  >
                    <div
                      _ngcontent-ng-c1798067373=""
                      className="casino-nation-detail ng-tns-c1798067373-1"
                    >
                      <div
                        _ngcontent-ng-c1798067373=""
                        className="casino-nation-name ng-tns-c1798067373-1 ng-star-inserted"
                      >
                        {" "}
                        Red
                        <span
                          _ngcontent-ng-c1798067373=""
                          className="card-icon ms-1 ng-tns-c1798067373-1"
                        >
                          <span
                            _ngcontent-ng-c1798067373=""
                            className="card-red ng-tns-c1798067373-1"
                          >
                            <img
                              _ngcontent-ng-c1798067373=""
                              className="ng-tns-c1798067373-1"
                              src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/SS.webp"
                            />
                          </span>
                        </span>
                        <span
                          _ngcontent-ng-c1798067373=""
                          className="card-icon ms-1 ng-tns-c1798067373-1"
                        >
                          <span
                            _ngcontent-ng-c1798067373=""
                            className="card-red ng-tns-c1798067373-1"
                          >
                            <img
                              _ngcontent-ng-c1798067373=""
                              className="ng-tns-c1798067373-1"
                              src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/DD.webp"
                            />
                          </span>
                        </span>
                      </div>

                      <div
                        _ngcontent-ng-c1798067373=""
                        className="min_max_collapse ng-tns-c1798067373-1 ng-star-inserted"
                      >
                        <a
                          _ngcontent-ng-c1798067373=""
                          data-bs-toggle="collapse"
                          role="button"
                          aria-expanded="false"
                          className="minmax-btn collapsed ng-tns-c1798067373-1"
                          href="#collapserangedtll23"
                          aria-controls="collapserangedtll23"
                        >
                          <i
                            _ngcontent-ng-c1798067373=""
                            className="fas fa-info-circle ng-tns-c1798067373-1"
                          />
                        </a>
                        <div
                          _ngcontent-ng-c1798067373=""
                          className="range-collpase collapse ng-tns-c1798067373-1"
                          id="collapserangedtll23"
                        >
                          <span
                            _ngcontent-ng-c1798067373=""
                            className="min_max_span ms-0 ng-tns-c1798067373-1"
                          >
                            <b
                              _ngcontent-ng-c1798067373=""
                              className="ng-tns-c1798067373-1"
                            >
                              Min:
                            </b>
                            <span
                              _ngcontent-ng-c1798067373=""
                              className="ng-tns-c1798067373-1"
                            >
                              100
                            </span>
                          </span>
                          <span
                            _ngcontent-ng-c1798067373=""
                            className="min_max_span ms-1 ng-tns-c1798067373-1"
                          >
                            <b
                              _ngcontent-ng-c1798067373=""
                              className="ng-tns-c1798067373-1"
                            >
                              Max:
                            </b>
                            <span
                              _ngcontent-ng-c1798067373=""
                              className="ng-tns-c1798067373-1"
                            >
                              100000
                            </span>
                          </span>
                        </div>
                      </div>
                    </div>
                    <div
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-1 back casino-odds-box suspended-box"
                    >
                      <span
                        _ngcontent-ng-c1798067373=""
                        className="casino-odds ng-tns-c1798067373-1"
                      >
                        1.97
                      </span>
                      <p
                        _ngcontent-ng-c1798067373=""
                        className="ng-tns-c1798067373-1"
                      >
                        {" "}
                        0{" "}
                      </p>
                    </div>
                  </div>
                  <div
                    _ngcontent-ng-c1798067373=""
                    className="casino-table-row ng-tns-c1798067373-1 ng-star-inserted"
                  >
                    <div
                      _ngcontent-ng-c1798067373=""
                      className="casino-nation-detail ng-tns-c1798067373-1"
                    >
                      <div
                        _ngcontent-ng-c1798067373=""
                        className="casino-nation-name ng-tns-c1798067373-1 ng-star-inserted"
                      >
                        {" "}
                        Odd{" "}
                      </div>

                      <div
                        _ngcontent-ng-c1798067373=""
                        className="min_max_collapse ng-tns-c1798067373-1 ng-star-inserted"
                      >
                        <a
                          _ngcontent-ng-c1798067373=""
                          data-bs-toggle="collapse"
                          role="button"
                          aria-expanded="false"
                          className="minmax-btn collapsed ng-tns-c1798067373-1"
                          href="#collapserangedtll24"
                          aria-controls="collapserangedtll24"
                        >
                          <i
                            _ngcontent-ng-c1798067373=""
                            className="fas fa-info-circle ng-tns-c1798067373-1"
                          />
                        </a>
                        <div
                          _ngcontent-ng-c1798067373=""
                          className="range-collpase collapse ng-tns-c1798067373-1"
                          id="collapserangedtll24"
                        >
                          <span
                            _ngcontent-ng-c1798067373=""
                            className="min_max_span ms-0 ng-tns-c1798067373-1"
                          >
                            <b
                              _ngcontent-ng-c1798067373=""
                              className="ng-tns-c1798067373-1"
                            >
                              Min:
                            </b>
                            <span
                              _ngcontent-ng-c1798067373=""
                              className="ng-tns-c1798067373-1"
                            >
                              100
                            </span>
                          </span>
                          <span
                            _ngcontent-ng-c1798067373=""
                            className="min_max_span ms-1 ng-tns-c1798067373-1"
                          >
                            <b
                              _ngcontent-ng-c1798067373=""
                              className="ng-tns-c1798067373-1"
                            >
                              Max:
                            </b>
                            <span
                              _ngcontent-ng-c1798067373=""
                              className="ng-tns-c1798067373-1"
                            >
                              100000
                            </span>
                          </span>
                        </div>
                      </div>
                    </div>
                    <div
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-1 back casino-odds-box suspended-box"
                    >
                      <span
                        _ngcontent-ng-c1798067373=""
                        className="casino-odds ng-tns-c1798067373-1"
                      >
                        1.83
                      </span>
                      <p
                        _ngcontent-ng-c1798067373=""
                        className="ng-tns-c1798067373-1"
                      >
                        {" "}
                        0{" "}
                      </p>
                    </div>
                  </div>
                  <div
                    _ngcontent-ng-c1798067373=""
                    className="casino-table-row ng-tns-c1798067373-1 ng-star-inserted"
                  >
                    <div
                      _ngcontent-ng-c1798067373=""
                      className="casino-nation-detail ng-tns-c1798067373-1"
                    >
                      <div
                        _ngcontent-ng-c1798067373=""
                        className="casino-nation-name ng-tns-c1798067373-1 ng-star-inserted"
                      >
                        {" "}
                        Even{" "}
                      </div>

                      <div
                        _ngcontent-ng-c1798067373=""
                        className="min_max_collapse ng-tns-c1798067373-1 ng-star-inserted"
                      >
                        <a
                          _ngcontent-ng-c1798067373=""
                          data-bs-toggle="collapse"
                          role="button"
                          aria-expanded="false"
                          className="minmax-btn collapsed ng-tns-c1798067373-1"
                          href="#collapserangedtll25"
                          aria-controls="collapserangedtll25"
                        >
                          <i
                            _ngcontent-ng-c1798067373=""
                            className="fas fa-info-circle ng-tns-c1798067373-1"
                          />
                        </a>
                        <div
                          _ngcontent-ng-c1798067373=""
                          className="range-collpase collapse ng-tns-c1798067373-1"
                          id="collapserangedtll25"
                        >
                          <span
                            _ngcontent-ng-c1798067373=""
                            className="min_max_span ms-0 ng-tns-c1798067373-1"
                          >
                            <b
                              _ngcontent-ng-c1798067373=""
                              className="ng-tns-c1798067373-1"
                            >
                              Min:
                            </b>
                            <span
                              _ngcontent-ng-c1798067373=""
                              className="ng-tns-c1798067373-1"
                            >
                              100
                            </span>
                          </span>
                          <span
                            _ngcontent-ng-c1798067373=""
                            className="min_max_span ms-1 ng-tns-c1798067373-1"
                          >
                            <b
                              _ngcontent-ng-c1798067373=""
                              className="ng-tns-c1798067373-1"
                            >
                              Max:
                            </b>
                            <span
                              _ngcontent-ng-c1798067373=""
                              className="ng-tns-c1798067373-1"
                            >
                              100000
                            </span>
                          </span>
                        </div>
                      </div>
                    </div>
                    <div
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-1 back casino-odds-box suspended-box"
                    >
                      <span
                        _ngcontent-ng-c1798067373=""
                        className="casino-odds ng-tns-c1798067373-1"
                      >
                        2.12
                      </span>
                      <p
                        _ngcontent-ng-c1798067373=""
                        className="ng-tns-c1798067373-1"
                      >
                        {" "}
                        0{" "}
                      </p>
                    </div>
                  </div>
                  <div
                    _ngcontent-ng-c1798067373=""
                    className="casino-table-row ng-tns-c1798067373-1 ng-star-inserted"
                  >
                    <div
                      _ngcontent-ng-c1798067373=""
                      className="casino-nation-detail ng-tns-c1798067373-1"
                    >
                      <div
                        _ngcontent-ng-c1798067373=""
                        className="casino-nation-name casino-nation-card ng-tns-c1798067373-1 ng-star-inserted"
                      >
                        <img
                          _ngcontent-ng-c1798067373=""
                          className="ng-tns-c1798067373-1 ng-star-inserted"
                          src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/A.webp"
                        />

                        <div
                          _ngcontent-ng-c1798067373=""
                          className="min_max_collapse ng-tns-c1798067373-1"
                        >
                          <a
                            _ngcontent-ng-c1798067373=""
                            data-bs-toggle="collapse"
                            role="button"
                            aria-expanded="false"
                            className="minmax-btn collapsed ng-tns-c1798067373-1"
                            href="#collapserangedtll26"
                            aria-controls="collapserangedtll26"
                          >
                            <i
                              _ngcontent-ng-c1798067373=""
                              className="fas fa-info-circle ng-tns-c1798067373-1"
                            />
                          </a>
                          <div
                            _ngcontent-ng-c1798067373=""
                            className="range-collpase collapse ng-tns-c1798067373-1"
                            id="collapserangedtll26"
                          >
                            <span
                              _ngcontent-ng-c1798067373=""
                              className="min_max_span ms-0 ng-tns-c1798067373-1"
                            >
                              <b
                                _ngcontent-ng-c1798067373=""
                                className="ng-tns-c1798067373-1"
                              >
                                Min:
                              </b>
                              <span
                                _ngcontent-ng-c1798067373=""
                                className="ng-tns-c1798067373-1"
                              >
                                100
                              </span>
                            </span>
                            <span
                              _ngcontent-ng-c1798067373=""
                              className="min_max_span ms-1 ng-tns-c1798067373-1"
                            >
                              <b
                                _ngcontent-ng-c1798067373=""
                                className="ng-tns-c1798067373-1"
                              >
                                Max:
                              </b>
                              <span
                                _ngcontent-ng-c1798067373=""
                                className="ng-tns-c1798067373-1"
                              >
                                100000
                              </span>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-1 back casino-odds-box suspended-box"
                    >
                      <span
                        _ngcontent-ng-c1798067373=""
                        className="casino-odds ng-tns-c1798067373-1"
                      >
                        12
                      </span>
                      <p
                        _ngcontent-ng-c1798067373=""
                        className="ng-tns-c1798067373-1"
                      >
                        {" "}
                        0{" "}
                      </p>
                    </div>
                  </div>
                  <div
                    _ngcontent-ng-c1798067373=""
                    className="casino-table-row ng-tns-c1798067373-1 ng-star-inserted"
                  >
                    <div
                      _ngcontent-ng-c1798067373=""
                      className="casino-nation-detail ng-tns-c1798067373-1"
                    >
                      <div
                        _ngcontent-ng-c1798067373=""
                        className="casino-nation-name casino-nation-card ng-tns-c1798067373-1 ng-star-inserted"
                      >
                        <img
                          _ngcontent-ng-c1798067373=""
                          className="ng-tns-c1798067373-1 ng-star-inserted"
                          src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/2.webp"
                        />

                        <div
                          _ngcontent-ng-c1798067373=""
                          className="min_max_collapse ng-tns-c1798067373-1"
                        >
                          <a
                            _ngcontent-ng-c1798067373=""
                            data-bs-toggle="collapse"
                            role="button"
                            aria-expanded="false"
                            className="minmax-btn collapsed ng-tns-c1798067373-1"
                            href="#collapserangedtll27"
                            aria-controls="collapserangedtll27"
                          >
                            <i
                              _ngcontent-ng-c1798067373=""
                              className="fas fa-info-circle ng-tns-c1798067373-1"
                            />
                          </a>
                          <div
                            _ngcontent-ng-c1798067373=""
                            className="range-collpase collapse ng-tns-c1798067373-1"
                            id="collapserangedtll27"
                          >
                            <span
                              _ngcontent-ng-c1798067373=""
                              className="min_max_span ms-0 ng-tns-c1798067373-1"
                            >
                              <b
                                _ngcontent-ng-c1798067373=""
                                className="ng-tns-c1798067373-1"
                              >
                                Min:
                              </b>
                              <span
                                _ngcontent-ng-c1798067373=""
                                className="ng-tns-c1798067373-1"
                              >
                                100
                              </span>
                            </span>
                            <span
                              _ngcontent-ng-c1798067373=""
                              className="min_max_span ms-1 ng-tns-c1798067373-1"
                            >
                              <b
                                _ngcontent-ng-c1798067373=""
                                className="ng-tns-c1798067373-1"
                              >
                                Max:
                              </b>
                              <span
                                _ngcontent-ng-c1798067373=""
                                className="ng-tns-c1798067373-1"
                              >
                                100000
                              </span>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-1 back casino-odds-box suspended-box"
                    >
                      <span
                        _ngcontent-ng-c1798067373=""
                        className="casino-odds ng-tns-c1798067373-1"
                      >
                        12
                      </span>
                      <p
                        _ngcontent-ng-c1798067373=""
                        className="ng-tns-c1798067373-1"
                      >
                        {" "}
                        0{" "}
                      </p>
                    </div>
                  </div>
                  <div
                    _ngcontent-ng-c1798067373=""
                    className="casino-table-row ng-tns-c1798067373-1 ng-star-inserted"
                  >
                    <div
                      _ngcontent-ng-c1798067373=""
                      className="casino-nation-detail ng-tns-c1798067373-1"
                    >
                      <div
                        _ngcontent-ng-c1798067373=""
                        className="casino-nation-name casino-nation-card ng-tns-c1798067373-1 ng-star-inserted"
                      >
                        <img
                          _ngcontent-ng-c1798067373=""
                          className="ng-tns-c1798067373-1 ng-star-inserted"
                          src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/3.webp"
                        />

                        <div
                          _ngcontent-ng-c1798067373=""
                          className="min_max_collapse ng-tns-c1798067373-1"
                        >
                          <a
                            _ngcontent-ng-c1798067373=""
                            data-bs-toggle="collapse"
                            role="button"
                            aria-expanded="false"
                            className="minmax-btn collapsed ng-tns-c1798067373-1"
                            href="#collapserangedtll28"
                            aria-controls="collapserangedtll28"
                          >
                            <i
                              _ngcontent-ng-c1798067373=""
                              className="fas fa-info-circle ng-tns-c1798067373-1"
                            />
                          </a>
                          <div
                            _ngcontent-ng-c1798067373=""
                            className="range-collpase collapse ng-tns-c1798067373-1"
                            id="collapserangedtll28"
                          >
                            <span
                              _ngcontent-ng-c1798067373=""
                              className="min_max_span ms-0 ng-tns-c1798067373-1"
                            >
                              <b
                                _ngcontent-ng-c1798067373=""
                                className="ng-tns-c1798067373-1"
                              >
                                Min:
                              </b>
                              <span
                                _ngcontent-ng-c1798067373=""
                                className="ng-tns-c1798067373-1"
                              >
                                100
                              </span>
                            </span>
                            <span
                              _ngcontent-ng-c1798067373=""
                              className="min_max_span ms-1 ng-tns-c1798067373-1"
                            >
                              <b
                                _ngcontent-ng-c1798067373=""
                                className="ng-tns-c1798067373-1"
                              >
                                Max:
                              </b>
                              <span
                                _ngcontent-ng-c1798067373=""
                                className="ng-tns-c1798067373-1"
                              >
                                100000
                              </span>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-1 back casino-odds-box suspended-box"
                    >
                      <span
                        _ngcontent-ng-c1798067373=""
                        className="casino-odds ng-tns-c1798067373-1"
                      >
                        12
                      </span>
                      <p
                        _ngcontent-ng-c1798067373=""
                        className="ng-tns-c1798067373-1"
                      >
                        {" "}
                        0{" "}
                      </p>
                    </div>
                  </div>
                  <div
                    _ngcontent-ng-c1798067373=""
                    className="casino-table-row ng-tns-c1798067373-1 ng-star-inserted"
                  >
                    <div
                      _ngcontent-ng-c1798067373=""
                      className="casino-nation-detail ng-tns-c1798067373-1"
                    >
                      <div
                        _ngcontent-ng-c1798067373=""
                        className="casino-nation-name casino-nation-card ng-tns-c1798067373-1 ng-star-inserted"
                      >
                        <img
                          _ngcontent-ng-c1798067373=""
                          className="ng-tns-c1798067373-1 ng-star-inserted"
                          src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/4.webp"
                        />

                        <div
                          _ngcontent-ng-c1798067373=""
                          className="min_max_collapse ng-tns-c1798067373-1"
                        >
                          <a
                            _ngcontent-ng-c1798067373=""
                            data-bs-toggle="collapse"
                            role="button"
                            aria-expanded="false"
                            className="minmax-btn collapsed ng-tns-c1798067373-1"
                            href="#collapserangedtll29"
                            aria-controls="collapserangedtll29"
                          >
                            <i
                              _ngcontent-ng-c1798067373=""
                              className="fas fa-info-circle ng-tns-c1798067373-1"
                            />
                          </a>
                          <div
                            _ngcontent-ng-c1798067373=""
                            className="range-collpase collapse ng-tns-c1798067373-1"
                            id="collapserangedtll29"
                          >
                            <span
                              _ngcontent-ng-c1798067373=""
                              className="min_max_span ms-0 ng-tns-c1798067373-1"
                            >
                              <b
                                _ngcontent-ng-c1798067373=""
                                className="ng-tns-c1798067373-1"
                              >
                                Min:
                              </b>
                              <span
                                _ngcontent-ng-c1798067373=""
                                className="ng-tns-c1798067373-1"
                              >
                                100
                              </span>
                            </span>
                            <span
                              _ngcontent-ng-c1798067373=""
                              className="min_max_span ms-1 ng-tns-c1798067373-1"
                            >
                              <b
                                _ngcontent-ng-c1798067373=""
                                className="ng-tns-c1798067373-1"
                              >
                                Max:
                              </b>
                              <span
                                _ngcontent-ng-c1798067373=""
                                className="ng-tns-c1798067373-1"
                              >
                                100000
                              </span>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-1 back casino-odds-box suspended-box"
                    >
                      <span
                        _ngcontent-ng-c1798067373=""
                        className="casino-odds ng-tns-c1798067373-1"
                      >
                        12
                      </span>
                      <p
                        _ngcontent-ng-c1798067373=""
                        className="ng-tns-c1798067373-1"
                      >
                        {" "}
                        0{" "}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div
                _ngcontent-ng-c1798067373=""
                className="casino-table-right-box ng-tns-c1798067373-1"
              >
                <div
                  _ngcontent-ng-c1798067373=""
                  className="casino-table-body ng-tns-c1798067373-1"
                >
                  <div
                    _ngcontent-ng-c1798067373=""
                    className="casino-table-row ng-tns-c1798067373-1 ng-star-inserted"
                  >
                    <div
                      _ngcontent-ng-c1798067373=""
                      className="casino-nation-detail ng-tns-c1798067373-1"
                    >
                      <div
                        _ngcontent-ng-c1798067373=""
                        className="casino-nation-name casino-nation-card ng-tns-c1798067373-1"
                      >
                        <img
                          _ngcontent-ng-c1798067373=""
                          className="ng-tns-c1798067373-1 ng-star-inserted"
                          src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/5.webp"
                        />

                        <div
                          _ngcontent-ng-c1798067373=""
                          className="min_max_collapse ng-tns-c1798067373-1"
                        >
                          <a
                            _ngcontent-ng-c1798067373=""
                            data-bs-toggle="collapse"
                            role="button"
                            aria-expanded="false"
                            className="minmax-btn collapsed ng-tns-c1798067373-1"
                            href="#collapserangedtll30"
                            aria-controls="collapserangedtll30"
                          >
                            <i
                              _ngcontent-ng-c1798067373=""
                              className="fas fa-info-circle ng-tns-c1798067373-1"
                            />
                          </a>
                          <div
                            _ngcontent-ng-c1798067373=""
                            className="range-collpase collapse ng-tns-c1798067373-1"
                            id="collapserangedtll30"
                          >
                            <span
                              _ngcontent-ng-c1798067373=""
                              className="min_max_span ms-0 ng-tns-c1798067373-1"
                            >
                              <b
                                _ngcontent-ng-c1798067373=""
                                className="ng-tns-c1798067373-1"
                              >
                                Min:
                              </b>
                              <span
                                _ngcontent-ng-c1798067373=""
                                className="ng-tns-c1798067373-1"
                              >
                                100
                              </span>
                            </span>
                            <span
                              _ngcontent-ng-c1798067373=""
                              className="min_max_span ms-1 ng-tns-c1798067373-1"
                            >
                              <b
                                _ngcontent-ng-c1798067373=""
                                className="ng-tns-c1798067373-1"
                              >
                                Max:
                              </b>
                              <span
                                _ngcontent-ng-c1798067373=""
                                className="ng-tns-c1798067373-1"
                              >
                                100000
                              </span>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-1 back casino-odds-box suspended-box"
                    >
                      <span
                        _ngcontent-ng-c1798067373=""
                        className="casino-odds ng-tns-c1798067373-1"
                      >
                        12
                      </span>
                      <p
                        _ngcontent-ng-c1798067373=""
                        className="ng-tns-c1798067373-1"
                      >
                        {" "}
                        0{" "}
                      </p>
                    </div>
                  </div>
                  <div
                    _ngcontent-ng-c1798067373=""
                    className="casino-table-row ng-tns-c1798067373-1 ng-star-inserted"
                  >
                    <div
                      _ngcontent-ng-c1798067373=""
                      className="casino-nation-detail ng-tns-c1798067373-1"
                    >
                      <div
                        _ngcontent-ng-c1798067373=""
                        className="casino-nation-name casino-nation-card ng-tns-c1798067373-1"
                      >
                        <img
                          _ngcontent-ng-c1798067373=""
                          className="ng-tns-c1798067373-1 ng-star-inserted"
                          src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/6.webp"
                        />

                        <div
                          _ngcontent-ng-c1798067373=""
                          className="min_max_collapse ng-tns-c1798067373-1"
                        >
                          <a
                            _ngcontent-ng-c1798067373=""
                            data-bs-toggle="collapse"
                            role="button"
                            aria-expanded="false"
                            className="minmax-btn collapsed ng-tns-c1798067373-1"
                            href="#collapserangedtll31"
                            aria-controls="collapserangedtll31"
                          >
                            <i
                              _ngcontent-ng-c1798067373=""
                              className="fas fa-info-circle ng-tns-c1798067373-1"
                            />
                          </a>
                          <div
                            _ngcontent-ng-c1798067373=""
                            className="range-collpase collapse ng-tns-c1798067373-1"
                            id="collapserangedtll31"
                          >
                            <span
                              _ngcontent-ng-c1798067373=""
                              className="min_max_span ms-0 ng-tns-c1798067373-1"
                            >
                              <b
                                _ngcontent-ng-c1798067373=""
                                className="ng-tns-c1798067373-1"
                              >
                                Min:
                              </b>
                              <span
                                _ngcontent-ng-c1798067373=""
                                className="ng-tns-c1798067373-1"
                              >
                                100
                              </span>
                            </span>
                            <span
                              _ngcontent-ng-c1798067373=""
                              className="min_max_span ms-1 ng-tns-c1798067373-1"
                            >
                              <b
                                _ngcontent-ng-c1798067373=""
                                className="ng-tns-c1798067373-1"
                              >
                                Max:
                              </b>
                              <span
                                _ngcontent-ng-c1798067373=""
                                className="ng-tns-c1798067373-1"
                              >
                                100000
                              </span>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-1 back casino-odds-box suspended-box"
                    >
                      <span
                        _ngcontent-ng-c1798067373=""
                        className="casino-odds ng-tns-c1798067373-1"
                      >
                        12
                      </span>
                      <p
                        _ngcontent-ng-c1798067373=""
                        className="ng-tns-c1798067373-1"
                      >
                        {" "}
                        0{" "}
                      </p>
                    </div>
                  </div>
                  <div
                    _ngcontent-ng-c1798067373=""
                    className="casino-table-row ng-tns-c1798067373-1 ng-star-inserted"
                  >
                    <div
                      _ngcontent-ng-c1798067373=""
                      className="casino-nation-detail ng-tns-c1798067373-1"
                    >
                      <div
                        _ngcontent-ng-c1798067373=""
                        className="casino-nation-name casino-nation-card ng-tns-c1798067373-1"
                      >
                        <img
                          _ngcontent-ng-c1798067373=""
                          className="ng-tns-c1798067373-1 ng-star-inserted"
                          src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/7.webp"
                        />

                        <div
                          _ngcontent-ng-c1798067373=""
                          className="min_max_collapse ng-tns-c1798067373-1"
                        >
                          <a
                            _ngcontent-ng-c1798067373=""
                            data-bs-toggle="collapse"
                            role="button"
                            aria-expanded="false"
                            className="minmax-btn collapsed ng-tns-c1798067373-1"
                            href="#collapserangedtll32"
                            aria-controls="collapserangedtll32"
                          >
                            <i
                              _ngcontent-ng-c1798067373=""
                              className="fas fa-info-circle ng-tns-c1798067373-1"
                            />
                          </a>
                          <div
                            _ngcontent-ng-c1798067373=""
                            className="range-collpase collapse ng-tns-c1798067373-1"
                            id="collapserangedtll32"
                          >
                            <span
                              _ngcontent-ng-c1798067373=""
                              className="min_max_span ms-0 ng-tns-c1798067373-1"
                            >
                              <b
                                _ngcontent-ng-c1798067373=""
                                className="ng-tns-c1798067373-1"
                              >
                                Min:
                              </b>
                              <span
                                _ngcontent-ng-c1798067373=""
                                className="ng-tns-c1798067373-1"
                              >
                                100
                              </span>
                            </span>
                            <span
                              _ngcontent-ng-c1798067373=""
                              className="min_max_span ms-1 ng-tns-c1798067373-1"
                            >
                              <b
                                _ngcontent-ng-c1798067373=""
                                className="ng-tns-c1798067373-1"
                              >
                                Max:
                              </b>
                              <span
                                _ngcontent-ng-c1798067373=""
                                className="ng-tns-c1798067373-1"
                              >
                                100000
                              </span>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-1 back casino-odds-box suspended-box"
                    >
                      <span
                        _ngcontent-ng-c1798067373=""
                        className="casino-odds ng-tns-c1798067373-1"
                      >
                        12
                      </span>
                      <p
                        _ngcontent-ng-c1798067373=""
                        className="ng-tns-c1798067373-1"
                      >
                        {" "}
                        0{" "}
                      </p>
                    </div>
                  </div>
                  <div
                    _ngcontent-ng-c1798067373=""
                    className="casino-table-row ng-tns-c1798067373-1 ng-star-inserted"
                  >
                    <div
                      _ngcontent-ng-c1798067373=""
                      className="casino-nation-detail ng-tns-c1798067373-1"
                    >
                      <div
                        _ngcontent-ng-c1798067373=""
                        className="casino-nation-name casino-nation-card ng-tns-c1798067373-1"
                      >
                        <img
                          _ngcontent-ng-c1798067373=""
                          className="ng-tns-c1798067373-1 ng-star-inserted"
                          src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/8.webp"
                        />

                        <div
                          _ngcontent-ng-c1798067373=""
                          className="min_max_collapse ng-tns-c1798067373-1"
                        >
                          <a
                            _ngcontent-ng-c1798067373=""
                            data-bs-toggle="collapse"
                            role="button"
                            aria-expanded="false"
                            className="minmax-btn collapsed ng-tns-c1798067373-1"
                            href="#collapserangedtll33"
                            aria-controls="collapserangedtll33"
                          >
                            <i
                              _ngcontent-ng-c1798067373=""
                              className="fas fa-info-circle ng-tns-c1798067373-1"
                            />
                          </a>
                          <div
                            _ngcontent-ng-c1798067373=""
                            className="range-collpase collapse ng-tns-c1798067373-1"
                            id="collapserangedtll33"
                          >
                            <span
                              _ngcontent-ng-c1798067373=""
                              className="min_max_span ms-0 ng-tns-c1798067373-1"
                            >
                              <b
                                _ngcontent-ng-c1798067373=""
                                className="ng-tns-c1798067373-1"
                              >
                                Min:
                              </b>
                              <span
                                _ngcontent-ng-c1798067373=""
                                className="ng-tns-c1798067373-1"
                              >
                                100
                              </span>
                            </span>
                            <span
                              _ngcontent-ng-c1798067373=""
                              className="min_max_span ms-1 ng-tns-c1798067373-1"
                            >
                              <b
                                _ngcontent-ng-c1798067373=""
                                className="ng-tns-c1798067373-1"
                              >
                                Max:
                              </b>
                              <span
                                _ngcontent-ng-c1798067373=""
                                className="ng-tns-c1798067373-1"
                              >
                                100000
                              </span>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-1 back casino-odds-box suspended-box"
                    >
                      <span
                        _ngcontent-ng-c1798067373=""
                        className="casino-odds ng-tns-c1798067373-1"
                      >
                        12
                      </span>
                      <p
                        _ngcontent-ng-c1798067373=""
                        className="ng-tns-c1798067373-1"
                      >
                        {" "}
                        0{" "}
                      </p>
                    </div>
                  </div>
                  <div
                    _ngcontent-ng-c1798067373=""
                    className="casino-table-row ng-tns-c1798067373-1 ng-star-inserted"
                  >
                    <div
                      _ngcontent-ng-c1798067373=""
                      className="casino-nation-detail ng-tns-c1798067373-1"
                    >
                      <div
                        _ngcontent-ng-c1798067373=""
                        className="casino-nation-name casino-nation-card ng-tns-c1798067373-1"
                      >
                        <img
                          _ngcontent-ng-c1798067373=""
                          className="ng-tns-c1798067373-1 ng-star-inserted"
                          src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/9.webp"
                        />

                        <div
                          _ngcontent-ng-c1798067373=""
                          className="min_max_collapse ng-tns-c1798067373-1"
                        >
                          <a
                            _ngcontent-ng-c1798067373=""
                            data-bs-toggle="collapse"
                            role="button"
                            aria-expanded="false"
                            className="minmax-btn collapsed ng-tns-c1798067373-1"
                            href="#collapserangedtll34"
                            aria-controls="collapserangedtll34"
                          >
                            <i
                              _ngcontent-ng-c1798067373=""
                              className="fas fa-info-circle ng-tns-c1798067373-1"
                            />
                          </a>
                          <div
                            _ngcontent-ng-c1798067373=""
                            className="range-collpase collapse ng-tns-c1798067373-1"
                            id="collapserangedtll34"
                          >
                            <span
                              _ngcontent-ng-c1798067373=""
                              className="min_max_span ms-0 ng-tns-c1798067373-1"
                            >
                              <b
                                _ngcontent-ng-c1798067373=""
                                className="ng-tns-c1798067373-1"
                              >
                                Min:
                              </b>
                              <span
                                _ngcontent-ng-c1798067373=""
                                className="ng-tns-c1798067373-1"
                              >
                                100
                              </span>
                            </span>
                            <span
                              _ngcontent-ng-c1798067373=""
                              className="min_max_span ms-1 ng-tns-c1798067373-1"
                            >
                              <b
                                _ngcontent-ng-c1798067373=""
                                className="ng-tns-c1798067373-1"
                              >
                                Max:
                              </b>
                              <span
                                _ngcontent-ng-c1798067373=""
                                className="ng-tns-c1798067373-1"
                              >
                                100000
                              </span>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-1 back casino-odds-box suspended-box"
                    >
                      <span
                        _ngcontent-ng-c1798067373=""
                        className="casino-odds ng-tns-c1798067373-1"
                      >
                        12
                      </span>
                      <p
                        _ngcontent-ng-c1798067373=""
                        className="ng-tns-c1798067373-1"
                      >
                        {" "}
                        0{" "}
                      </p>
                    </div>
                  </div>
                  <div
                    _ngcontent-ng-c1798067373=""
                    className="casino-table-row ng-tns-c1798067373-1 ng-star-inserted"
                  >
                    <div
                      _ngcontent-ng-c1798067373=""
                      className="casino-nation-detail ng-tns-c1798067373-1"
                    >
                      <div
                        _ngcontent-ng-c1798067373=""
                        className="casino-nation-name casino-nation-card ng-tns-c1798067373-1"
                      >
                        <img
                          _ngcontent-ng-c1798067373=""
                          className="ng-tns-c1798067373-1 ng-star-inserted"
                          src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/10.webp"
                        />

                        <div
                          _ngcontent-ng-c1798067373=""
                          className="min_max_collapse ng-tns-c1798067373-1"
                        >
                          <a
                            _ngcontent-ng-c1798067373=""
                            data-bs-toggle="collapse"
                            role="button"
                            aria-expanded="false"
                            className="minmax-btn collapsed ng-tns-c1798067373-1"
                            href="#collapserangedtll35"
                            aria-controls="collapserangedtll35"
                          >
                            <i
                              _ngcontent-ng-c1798067373=""
                              className="fas fa-info-circle ng-tns-c1798067373-1"
                            />
                          </a>
                          <div
                            _ngcontent-ng-c1798067373=""
                            className="range-collpase collapse ng-tns-c1798067373-1"
                            id="collapserangedtll35"
                          >
                            <span
                              _ngcontent-ng-c1798067373=""
                              className="min_max_span ms-0 ng-tns-c1798067373-1"
                            >
                              <b
                                _ngcontent-ng-c1798067373=""
                                className="ng-tns-c1798067373-1"
                              >
                                Min:
                              </b>
                              <span
                                _ngcontent-ng-c1798067373=""
                                className="ng-tns-c1798067373-1"
                              >
                                100
                              </span>
                            </span>
                            <span
                              _ngcontent-ng-c1798067373=""
                              className="min_max_span ms-1 ng-tns-c1798067373-1"
                            >
                              <b
                                _ngcontent-ng-c1798067373=""
                                className="ng-tns-c1798067373-1"
                              >
                                Max:
                              </b>
                              <span
                                _ngcontent-ng-c1798067373=""
                                className="ng-tns-c1798067373-1"
                              >
                                100000
                              </span>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-1 back casino-odds-box suspended-box"
                    >
                      <span
                        _ngcontent-ng-c1798067373=""
                        className="casino-odds ng-tns-c1798067373-1"
                      >
                        12
                      </span>
                      <p
                        _ngcontent-ng-c1798067373=""
                        className="ng-tns-c1798067373-1"
                      >
                        {" "}
                        0{" "}
                      </p>
                    </div>
                  </div>
                  <div
                    _ngcontent-ng-c1798067373=""
                    className="casino-table-row ng-tns-c1798067373-1 ng-star-inserted"
                  >
                    <div
                      _ngcontent-ng-c1798067373=""
                      className="casino-nation-detail ng-tns-c1798067373-1"
                    >
                      <div
                        _ngcontent-ng-c1798067373=""
                        className="casino-nation-name casino-nation-card ng-tns-c1798067373-1"
                      >
                        <img
                          _ngcontent-ng-c1798067373=""
                          className="ng-tns-c1798067373-1 ng-star-inserted"
                          src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/J.webp"
                        />

                        <div
                          _ngcontent-ng-c1798067373=""
                          className="min_max_collapse ng-tns-c1798067373-1"
                        >
                          <a
                            _ngcontent-ng-c1798067373=""
                            data-bs-toggle="collapse"
                            role="button"
                            aria-expanded="false"
                            className="minmax-btn collapsed ng-tns-c1798067373-1"
                            href="#collapserangedtll36"
                            aria-controls="collapserangedtll36"
                          >
                            <i
                              _ngcontent-ng-c1798067373=""
                              className="fas fa-info-circle ng-tns-c1798067373-1"
                            />
                          </a>
                          <div
                            _ngcontent-ng-c1798067373=""
                            className="range-collpase collapse ng-tns-c1798067373-1"
                            id="collapserangedtll36"
                          >
                            <span
                              _ngcontent-ng-c1798067373=""
                              className="min_max_span ms-0 ng-tns-c1798067373-1"
                            >
                              <b
                                _ngcontent-ng-c1798067373=""
                                className="ng-tns-c1798067373-1"
                              >
                                Min:
                              </b>
                              <span
                                _ngcontent-ng-c1798067373=""
                                className="ng-tns-c1798067373-1"
                              >
                                100
                              </span>
                            </span>
                            <span
                              _ngcontent-ng-c1798067373=""
                              className="min_max_span ms-1 ng-tns-c1798067373-1"
                            >
                              <b
                                _ngcontent-ng-c1798067373=""
                                className="ng-tns-c1798067373-1"
                              >
                                Max:
                              </b>
                              <span
                                _ngcontent-ng-c1798067373=""
                                className="ng-tns-c1798067373-1"
                              >
                                100000
                              </span>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-1 back casino-odds-box suspended-box"
                    >
                      <span
                        _ngcontent-ng-c1798067373=""
                        className="casino-odds ng-tns-c1798067373-1"
                      >
                        12
                      </span>
                      <p
                        _ngcontent-ng-c1798067373=""
                        className="ng-tns-c1798067373-1"
                      >
                        {" "}
                        0{" "}
                      </p>
                    </div>
                  </div>
                  <div
                    _ngcontent-ng-c1798067373=""
                    className="casino-table-row ng-tns-c1798067373-1 ng-star-inserted"
                  >
                    <div
                      _ngcontent-ng-c1798067373=""
                      className="casino-nation-detail ng-tns-c1798067373-1"
                    >
                      <div
                        _ngcontent-ng-c1798067373=""
                        className="casino-nation-name casino-nation-card ng-tns-c1798067373-1"
                      >
                        <img
                          _ngcontent-ng-c1798067373=""
                          className="ng-tns-c1798067373-1 ng-star-inserted"
                          src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/Q.webp"
                        />

                        <div
                          _ngcontent-ng-c1798067373=""
                          className="min_max_collapse ng-tns-c1798067373-1"
                        >
                          <a
                            _ngcontent-ng-c1798067373=""
                            data-bs-toggle="collapse"
                            role="button"
                            aria-expanded="false"
                            className="minmax-btn collapsed ng-tns-c1798067373-1"
                            href="#collapserangedtll37"
                            aria-controls="collapserangedtll37"
                          >
                            <i
                              _ngcontent-ng-c1798067373=""
                              className="fas fa-info-circle ng-tns-c1798067373-1"
                            />
                          </a>
                          <div
                            _ngcontent-ng-c1798067373=""
                            className="range-collpase collapse ng-tns-c1798067373-1"
                            id="collapserangedtll37"
                          >
                            <span
                              _ngcontent-ng-c1798067373=""
                              className="min_max_span ms-0 ng-tns-c1798067373-1"
                            >
                              <b
                                _ngcontent-ng-c1798067373=""
                                className="ng-tns-c1798067373-1"
                              >
                                Min:
                              </b>
                              <span
                                _ngcontent-ng-c1798067373=""
                                className="ng-tns-c1798067373-1"
                              >
                                100
                              </span>
                            </span>
                            <span
                              _ngcontent-ng-c1798067373=""
                              className="min_max_span ms-1 ng-tns-c1798067373-1"
                            >
                              <b
                                _ngcontent-ng-c1798067373=""
                                className="ng-tns-c1798067373-1"
                              >
                                Max:
                              </b>
                              <span
                                _ngcontent-ng-c1798067373=""
                                className="ng-tns-c1798067373-1"
                              >
                                100000
                              </span>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-1 back casino-odds-box suspended-box"
                    >
                      <span
                        _ngcontent-ng-c1798067373=""
                        className="casino-odds ng-tns-c1798067373-1"
                      >
                        12
                      </span>
                      <p
                        _ngcontent-ng-c1798067373=""
                        className="ng-tns-c1798067373-1"
                      >
                        {" "}
                        0{" "}
                      </p>
                    </div>
                  </div>
                  <div
                    _ngcontent-ng-c1798067373=""
                    className="casino-table-row ng-tns-c1798067373-1 ng-star-inserted"
                  >
                    <div
                      _ngcontent-ng-c1798067373=""
                      className="casino-nation-detail ng-tns-c1798067373-1"
                    >
                      <div
                        _ngcontent-ng-c1798067373=""
                        className="casino-nation-name casino-nation-card ng-tns-c1798067373-1"
                      >
                        <img
                          _ngcontent-ng-c1798067373=""
                          className="ng-tns-c1798067373-1 ng-star-inserted"
                          src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/K.webp"
                        />

                        <div
                          _ngcontent-ng-c1798067373=""
                          className="min_max_collapse ng-tns-c1798067373-1"
                        >
                          <a
                            _ngcontent-ng-c1798067373=""
                            data-bs-toggle="collapse"
                            role="button"
                            aria-expanded="false"
                            className="minmax-btn collapsed ng-tns-c1798067373-1"
                            href="#collapserangedtll38"
                            aria-controls="collapserangedtll38"
                          >
                            <i
                              _ngcontent-ng-c1798067373=""
                              className="fas fa-info-circle ng-tns-c1798067373-1"
                            />
                          </a>
                          <div
                            _ngcontent-ng-c1798067373=""
                            className="range-collpase collapse ng-tns-c1798067373-1"
                            id="collapserangedtll38"
                          >
                            <span
                              _ngcontent-ng-c1798067373=""
                              className="min_max_span ms-0 ng-tns-c1798067373-1"
                            >
                              <b
                                _ngcontent-ng-c1798067373=""
                                className="ng-tns-c1798067373-1"
                              >
                                Min:
                              </b>
                              <span
                                _ngcontent-ng-c1798067373=""
                                className="ng-tns-c1798067373-1"
                              >
                                100
                              </span>
                            </span>
                            <span
                              _ngcontent-ng-c1798067373=""
                              className="min_max_span ms-1 ng-tns-c1798067373-1"
                            >
                              <b
                                _ngcontent-ng-c1798067373=""
                                className="ng-tns-c1798067373-1"
                              >
                                Max:
                              </b>
                              <span
                                _ngcontent-ng-c1798067373=""
                                className="ng-tns-c1798067373-1"
                              >
                                100000
                              </span>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-1 back casino-odds-box suspended-box"
                    >
                      <span
                        _ngcontent-ng-c1798067373=""
                        className="casino-odds ng-tns-c1798067373-1"
                      >
                        12
                      </span>
                      <p
                        _ngcontent-ng-c1798067373=""
                        className="ng-tns-c1798067373-1"
                      >
                        {" "}
                        0{" "}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            _ngcontent-ng-c1798067373=""
            role="tabpanel"
            id="uncontrolled-tab-example-tabpane-2"
            aria-labelledby="uncontrolled-tab-example-tab-2"
            className="ng-tns-c1798067373-1 d-xl-none fade show tab-pane"
          >
            <div
              _ngcontent-ng-c1798067373=""
              className="casino-table-box ng-tns-c1798067373-1"
            >
              <div
                _ngcontent-ng-c1798067373=""
                className="casino-table-left-box ng-tns-c1798067373-1"
              >
                <div
                  _ngcontent-ng-c1798067373=""
                  className="casino-table-body ng-tns-c1798067373-1"
                >
                  <div
                    _ngcontent-ng-c1798067373=""
                    className="casino-table-row ng-tns-c1798067373-1 ng-star-inserted"
                  >
                    <div
                      _ngcontent-ng-c1798067373=""
                      className="casino-nation-detail ng-tns-c1798067373-1"
                    >
                      <div
                        _ngcontent-ng-c1798067373=""
                        className="casino-nation-name ng-tns-c1798067373-1 ng-star-inserted"
                      >
                        {" "}
                        Winner{" "}
                      </div>

                      <div
                        _ngcontent-ng-c1798067373=""
                        className="min_max_collapse ng-tns-c1798067373-1 ng-star-inserted"
                      >
                        <a
                          _ngcontent-ng-c1798067373=""
                          data-bs-toggle="collapse"
                          role="button"
                          aria-expanded="false"
                          className="minmax-btn collapsed ng-tns-c1798067373-1"
                          href="#collapserangedtll41"
                          aria-controls="collapserangedtll41"
                        >
                          <i
                            _ngcontent-ng-c1798067373=""
                            className="fas fa-info-circle ng-tns-c1798067373-1"
                          />
                        </a>
                        <div
                          _ngcontent-ng-c1798067373=""
                          className="range-collpase collapse ng-tns-c1798067373-1"
                          id="collapserangedtll41"
                        >
                          <span
                            _ngcontent-ng-c1798067373=""
                            className="min_max_span ms-0 ng-tns-c1798067373-1"
                          >
                            <b
                              _ngcontent-ng-c1798067373=""
                              className="ng-tns-c1798067373-1"
                            >
                              Min:
                            </b>
                            <span
                              _ngcontent-ng-c1798067373=""
                              className="ng-tns-c1798067373-1"
                            >
                              100
                            </span>
                          </span>
                          <span
                            _ngcontent-ng-c1798067373=""
                            className="min_max_span ms-1 ng-tns-c1798067373-1"
                          >
                            <b
                              _ngcontent-ng-c1798067373=""
                              className="ng-tns-c1798067373-1"
                            >
                              Max:
                            </b>
                            <span
                              _ngcontent-ng-c1798067373=""
                              className="ng-tns-c1798067373-1"
                            >
                              100000
                            </span>
                          </span>
                        </div>
                      </div>
                    </div>
                    <div
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-1 back casino-odds-box suspended-box"
                    >
                      <span
                        _ngcontent-ng-c1798067373=""
                        className="casino-odds ng-tns-c1798067373-1"
                      >
                        2.94
                      </span>
                      <p
                        _ngcontent-ng-c1798067373=""
                        className="ng-tns-c1798067373-1"
                      >
                        {" "}
                        0{" "}
                      </p>
                    </div>
                  </div>
                  <div
                    _ngcontent-ng-c1798067373=""
                    className="casino-table-row ng-tns-c1798067373-1 ng-star-inserted"
                  >
                    <div
                      _ngcontent-ng-c1798067373=""
                      className="casino-nation-detail ng-tns-c1798067373-1"
                    >
                      <div
                        _ngcontent-ng-c1798067373=""
                        className="casino-nation-name ng-tns-c1798067373-1 ng-star-inserted"
                      >
                        {" "}
                        Black
                        <span
                          _ngcontent-ng-c1798067373=""
                          className="card-icon ms-1 ng-tns-c1798067373-1"
                        >
                          <span
                            _ngcontent-ng-c1798067373=""
                            className="card-red ng-tns-c1798067373-1"
                          >
                            <img
                              _ngcontent-ng-c1798067373=""
                              className="ng-tns-c1798067373-1"
                              src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/SS.webp"
                            />
                          </span>
                        </span>
                        <span
                          _ngcontent-ng-c1798067373=""
                          className="card-icon ms-1 ng-tns-c1798067373-1"
                        >
                          <span
                            _ngcontent-ng-c1798067373=""
                            className="card-red ng-tns-c1798067373-1"
                          >
                            <img
                              _ngcontent-ng-c1798067373=""
                              className="ng-tns-c1798067373-1"
                              src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/CC.webp"
                            />
                          </span>
                        </span>
                      </div>

                      <div
                        _ngcontent-ng-c1798067373=""
                        className="min_max_collapse ng-tns-c1798067373-1 ng-star-inserted"
                      >
                        <a
                          _ngcontent-ng-c1798067373=""
                          data-bs-toggle="collapse"
                          role="button"
                          aria-expanded="false"
                          className="minmax-btn collapsed ng-tns-c1798067373-1"
                          href="#collapserangedtll42"
                          aria-controls="collapserangedtll42"
                        >
                          <i
                            _ngcontent-ng-c1798067373=""
                            className="fas fa-info-circle ng-tns-c1798067373-1"
                          />
                        </a>
                        <div
                          _ngcontent-ng-c1798067373=""
                          className="range-collpase collapse ng-tns-c1798067373-1"
                          id="collapserangedtll42"
                        >
                          <span
                            _ngcontent-ng-c1798067373=""
                            className="min_max_span ms-0 ng-tns-c1798067373-1"
                          >
                            <b
                              _ngcontent-ng-c1798067373=""
                              className="ng-tns-c1798067373-1"
                            >
                              Min:
                            </b>
                            <span
                              _ngcontent-ng-c1798067373=""
                              className="ng-tns-c1798067373-1"
                            >
                              100
                            </span>
                          </span>
                          <span
                            _ngcontent-ng-c1798067373=""
                            className="min_max_span ms-1 ng-tns-c1798067373-1"
                          >
                            <b
                              _ngcontent-ng-c1798067373=""
                              className="ng-tns-c1798067373-1"
                            >
                              Max:
                            </b>
                            <span
                              _ngcontent-ng-c1798067373=""
                              className="ng-tns-c1798067373-1"
                            >
                              100000
                            </span>
                          </span>
                        </div>
                      </div>
                    </div>
                    <div
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-1 back casino-odds-box suspended-box"
                    >
                      <span
                        _ngcontent-ng-c1798067373=""
                        className="casino-odds ng-tns-c1798067373-1"
                      >
                        1.97
                      </span>
                      <p
                        _ngcontent-ng-c1798067373=""
                        className="ng-tns-c1798067373-1"
                      >
                        {" "}
                        0{" "}
                      </p>
                    </div>
                  </div>
                  <div
                    _ngcontent-ng-c1798067373=""
                    className="casino-table-row ng-tns-c1798067373-1 ng-star-inserted"
                  >
                    <div
                      _ngcontent-ng-c1798067373=""
                      className="casino-nation-detail ng-tns-c1798067373-1"
                    >
                      <div
                        _ngcontent-ng-c1798067373=""
                        className="casino-nation-name ng-tns-c1798067373-1 ng-star-inserted"
                      >
                        {" "}
                        Red
                        <span
                          _ngcontent-ng-c1798067373=""
                          className="card-icon ms-1 ng-tns-c1798067373-1"
                        >
                          <span
                            _ngcontent-ng-c1798067373=""
                            className="card-red ng-tns-c1798067373-1"
                          >
                            <img
                              _ngcontent-ng-c1798067373=""
                              className="ng-tns-c1798067373-1"
                              src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/SS.webp"
                            />
                          </span>
                        </span>
                        <span
                          _ngcontent-ng-c1798067373=""
                          className="card-icon ms-1 ng-tns-c1798067373-1"
                        >
                          <span
                            _ngcontent-ng-c1798067373=""
                            className="card-red ng-tns-c1798067373-1"
                          >
                            <img
                              _ngcontent-ng-c1798067373=""
                              className="ng-tns-c1798067373-1"
                              src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/DD.webp"
                            />
                          </span>
                        </span>
                      </div>

                      <div
                        _ngcontent-ng-c1798067373=""
                        className="min_max_collapse ng-tns-c1798067373-1 ng-star-inserted"
                      >
                        <a
                          _ngcontent-ng-c1798067373=""
                          data-bs-toggle="collapse"
                          role="button"
                          aria-expanded="false"
                          className="minmax-btn collapsed ng-tns-c1798067373-1"
                          href="#collapserangedtll43"
                          aria-controls="collapserangedtll43"
                        >
                          <i
                            _ngcontent-ng-c1798067373=""
                            className="fas fa-info-circle ng-tns-c1798067373-1"
                          />
                        </a>
                        <div
                          _ngcontent-ng-c1798067373=""
                          className="range-collpase collapse ng-tns-c1798067373-1"
                          id="collapserangedtll43"
                        >
                          <span
                            _ngcontent-ng-c1798067373=""
                            className="min_max_span ms-0 ng-tns-c1798067373-1"
                          >
                            <b
                              _ngcontent-ng-c1798067373=""
                              className="ng-tns-c1798067373-1"
                            >
                              Min:
                            </b>
                            <span
                              _ngcontent-ng-c1798067373=""
                              className="ng-tns-c1798067373-1"
                            >
                              100
                            </span>
                          </span>
                          <span
                            _ngcontent-ng-c1798067373=""
                            className="min_max_span ms-1 ng-tns-c1798067373-1"
                          >
                            <b
                              _ngcontent-ng-c1798067373=""
                              className="ng-tns-c1798067373-1"
                            >
                              Max:
                            </b>
                            <span
                              _ngcontent-ng-c1798067373=""
                              className="ng-tns-c1798067373-1"
                            >
                              100000
                            </span>
                          </span>
                        </div>
                      </div>
                    </div>
                    <div
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-1 back casino-odds-box suspended-box"
                    >
                      <span
                        _ngcontent-ng-c1798067373=""
                        className="casino-odds ng-tns-c1798067373-1"
                      >
                        1.97
                      </span>
                      <p
                        _ngcontent-ng-c1798067373=""
                        className="ng-tns-c1798067373-1"
                      >
                        {" "}
                        0{" "}
                      </p>
                    </div>
                  </div>
                  <div
                    _ngcontent-ng-c1798067373=""
                    className="casino-table-row ng-tns-c1798067373-1 ng-star-inserted"
                  >
                    <div
                      _ngcontent-ng-c1798067373=""
                      className="casino-nation-detail ng-tns-c1798067373-1"
                    >
                      <div
                        _ngcontent-ng-c1798067373=""
                        className="casino-nation-name ng-tns-c1798067373-1 ng-star-inserted"
                      >
                        {" "}
                        Odd{" "}
                      </div>

                      <div
                        _ngcontent-ng-c1798067373=""
                        className="min_max_collapse ng-tns-c1798067373-1 ng-star-inserted"
                      >
                        <a
                          _ngcontent-ng-c1798067373=""
                          data-bs-toggle="collapse"
                          role="button"
                          aria-expanded="false"
                          className="minmax-btn collapsed ng-tns-c1798067373-1"
                          href="#collapserangedtll44"
                          aria-controls="collapserangedtll44"
                        >
                          <i
                            _ngcontent-ng-c1798067373=""
                            className="fas fa-info-circle ng-tns-c1798067373-1"
                          />
                        </a>
                        <div
                          _ngcontent-ng-c1798067373=""
                          className="range-collpase collapse ng-tns-c1798067373-1"
                          id="collapserangedtll44"
                        >
                          <span
                            _ngcontent-ng-c1798067373=""
                            className="min_max_span ms-0 ng-tns-c1798067373-1"
                          >
                            <b
                              _ngcontent-ng-c1798067373=""
                              className="ng-tns-c1798067373-1"
                            >
                              Min:
                            </b>
                            <span
                              _ngcontent-ng-c1798067373=""
                              className="ng-tns-c1798067373-1"
                            >
                              100
                            </span>
                          </span>
                          <span
                            _ngcontent-ng-c1798067373=""
                            className="min_max_span ms-1 ng-tns-c1798067373-1"
                          >
                            <b
                              _ngcontent-ng-c1798067373=""
                              className="ng-tns-c1798067373-1"
                            >
                              Max:
                            </b>
                            <span
                              _ngcontent-ng-c1798067373=""
                              className="ng-tns-c1798067373-1"
                            >
                              100000
                            </span>
                          </span>
                        </div>
                      </div>
                    </div>
                    <div
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-1 back casino-odds-box suspended-box"
                    >
                      <span
                        _ngcontent-ng-c1798067373=""
                        className="casino-odds ng-tns-c1798067373-1"
                      >
                        1.83
                      </span>
                      <p
                        _ngcontent-ng-c1798067373=""
                        className="ng-tns-c1798067373-1"
                      >
                        {" "}
                        0{" "}
                      </p>
                    </div>
                  </div>
                  <div
                    _ngcontent-ng-c1798067373=""
                    className="casino-table-row ng-tns-c1798067373-1 ng-star-inserted"
                  >
                    <div
                      _ngcontent-ng-c1798067373=""
                      className="casino-nation-detail ng-tns-c1798067373-1"
                    >
                      <div
                        _ngcontent-ng-c1798067373=""
                        className="casino-nation-name ng-tns-c1798067373-1 ng-star-inserted"
                      >
                        {" "}
                        Even{" "}
                      </div>

                      <div
                        _ngcontent-ng-c1798067373=""
                        className="min_max_collapse ng-tns-c1798067373-1 ng-star-inserted"
                      >
                        <a
                          _ngcontent-ng-c1798067373=""
                          data-bs-toggle="collapse"
                          role="button"
                          aria-expanded="false"
                          className="minmax-btn collapsed ng-tns-c1798067373-1"
                          href="#collapserangedtll45"
                          aria-controls="collapserangedtll45"
                        >
                          <i
                            _ngcontent-ng-c1798067373=""
                            className="fas fa-info-circle ng-tns-c1798067373-1"
                          />
                        </a>
                        <div
                          _ngcontent-ng-c1798067373=""
                          className="range-collpase collapse ng-tns-c1798067373-1"
                          id="collapserangedtll45"
                        >
                          <span
                            _ngcontent-ng-c1798067373=""
                            className="min_max_span ms-0 ng-tns-c1798067373-1"
                          >
                            <b
                              _ngcontent-ng-c1798067373=""
                              className="ng-tns-c1798067373-1"
                            >
                              Min:
                            </b>
                            <span
                              _ngcontent-ng-c1798067373=""
                              className="ng-tns-c1798067373-1"
                            >
                              100
                            </span>
                          </span>
                          <span
                            _ngcontent-ng-c1798067373=""
                            className="min_max_span ms-1 ng-tns-c1798067373-1"
                          >
                            <b
                              _ngcontent-ng-c1798067373=""
                              className="ng-tns-c1798067373-1"
                            >
                              Max:
                            </b>
                            <span
                              _ngcontent-ng-c1798067373=""
                              className="ng-tns-c1798067373-1"
                            >
                              100000
                            </span>
                          </span>
                        </div>
                      </div>
                    </div>
                    <div
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-1 back casino-odds-box suspended-box"
                    >
                      <span
                        _ngcontent-ng-c1798067373=""
                        className="casino-odds ng-tns-c1798067373-1"
                      >
                        2.12
                      </span>
                      <p
                        _ngcontent-ng-c1798067373=""
                        className="ng-tns-c1798067373-1"
                      >
                        {" "}
                        0{" "}
                      </p>
                    </div>
                  </div>
                  <div
                    _ngcontent-ng-c1798067373=""
                    className="casino-table-row ng-tns-c1798067373-1 ng-star-inserted"
                  >
                    <div
                      _ngcontent-ng-c1798067373=""
                      className="casino-nation-detail ng-tns-c1798067373-1"
                    >
                      <div
                        _ngcontent-ng-c1798067373=""
                        className="casino-nation-name casino-nation-card ng-tns-c1798067373-1 ng-star-inserted"
                      >
                        <img
                          _ngcontent-ng-c1798067373=""
                          className="ng-tns-c1798067373-1 ng-star-inserted"
                          src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/A.webp"
                        />

                        <div
                          _ngcontent-ng-c1798067373=""
                          className="min_max_collapse ng-tns-c1798067373-1"
                        >
                          <a
                            _ngcontent-ng-c1798067373=""
                            data-bs-toggle="collapse"
                            role="button"
                            aria-expanded="false"
                            className="minmax-btn collapsed ng-tns-c1798067373-1"
                            href="#collapserangedtll46"
                            aria-controls="collapserangedtll46"
                          >
                            <i
                              _ngcontent-ng-c1798067373=""
                              className="fas fa-info-circle ng-tns-c1798067373-1"
                            />
                          </a>
                          <div
                            _ngcontent-ng-c1798067373=""
                            className="range-collpase collapse ng-tns-c1798067373-1"
                            id="collapserangedtll46"
                          >
                            <span
                              _ngcontent-ng-c1798067373=""
                              className="min_max_span ms-0 ng-tns-c1798067373-1"
                            >
                              <b
                                _ngcontent-ng-c1798067373=""
                                className="ng-tns-c1798067373-1"
                              >
                                Min:
                              </b>
                              <span
                                _ngcontent-ng-c1798067373=""
                                className="ng-tns-c1798067373-1"
                              >
                                100
                              </span>
                            </span>
                            <span
                              _ngcontent-ng-c1798067373=""
                              className="min_max_span ms-1 ng-tns-c1798067373-1"
                            >
                              <b
                                _ngcontent-ng-c1798067373=""
                                className="ng-tns-c1798067373-1"
                              >
                                Max:
                              </b>
                              <span
                                _ngcontent-ng-c1798067373=""
                                className="ng-tns-c1798067373-1"
                              >
                                100000
                              </span>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-1 back casino-odds-box suspended-box"
                    >
                      <span
                        _ngcontent-ng-c1798067373=""
                        className="casino-odds ng-tns-c1798067373-1"
                      >
                        12
                      </span>
                      <p
                        _ngcontent-ng-c1798067373=""
                        className="ng-tns-c1798067373-1"
                      >
                        {" "}
                        0{" "}
                      </p>
                    </div>
                  </div>
                  <div
                    _ngcontent-ng-c1798067373=""
                    className="casino-table-row ng-tns-c1798067373-1 ng-star-inserted"
                  >
                    <div
                      _ngcontent-ng-c1798067373=""
                      className="casino-nation-detail ng-tns-c1798067373-1"
                    >
                      <div
                        _ngcontent-ng-c1798067373=""
                        className="casino-nation-name casino-nation-card ng-tns-c1798067373-1 ng-star-inserted"
                      >
                        <img
                          _ngcontent-ng-c1798067373=""
                          className="ng-tns-c1798067373-1 ng-star-inserted"
                          src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/2.webp"
                        />

                        <div
                          _ngcontent-ng-c1798067373=""
                          className="min_max_collapse ng-tns-c1798067373-1"
                        >
                          <a
                            _ngcontent-ng-c1798067373=""
                            data-bs-toggle="collapse"
                            role="button"
                            aria-expanded="false"
                            className="minmax-btn collapsed ng-tns-c1798067373-1"
                            href="#collapserangedtll47"
                            aria-controls="collapserangedtll47"
                          >
                            <i
                              _ngcontent-ng-c1798067373=""
                              className="fas fa-info-circle ng-tns-c1798067373-1"
                            />
                          </a>
                          <div
                            _ngcontent-ng-c1798067373=""
                            className="range-collpase collapse ng-tns-c1798067373-1"
                            id="collapserangedtll47"
                          >
                            <span
                              _ngcontent-ng-c1798067373=""
                              className="min_max_span ms-0 ng-tns-c1798067373-1"
                            >
                              <b
                                _ngcontent-ng-c1798067373=""
                                className="ng-tns-c1798067373-1"
                              >
                                Min:
                              </b>
                              <span
                                _ngcontent-ng-c1798067373=""
                                className="ng-tns-c1798067373-1"
                              >
                                100
                              </span>
                            </span>
                            <span
                              _ngcontent-ng-c1798067373=""
                              className="min_max_span ms-1 ng-tns-c1798067373-1"
                            >
                              <b
                                _ngcontent-ng-c1798067373=""
                                className="ng-tns-c1798067373-1"
                              >
                                Max:
                              </b>
                              <span
                                _ngcontent-ng-c1798067373=""
                                className="ng-tns-c1798067373-1"
                              >
                                100000
                              </span>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-1 back casino-odds-box suspended-box"
                    >
                      <span
                        _ngcontent-ng-c1798067373=""
                        className="casino-odds ng-tns-c1798067373-1"
                      >
                        12
                      </span>
                      <p
                        _ngcontent-ng-c1798067373=""
                        className="ng-tns-c1798067373-1"
                      >
                        {" "}
                        0{" "}
                      </p>
                    </div>
                  </div>
                  <div
                    _ngcontent-ng-c1798067373=""
                    className="casino-table-row ng-tns-c1798067373-1 ng-star-inserted"
                  >
                    <div
                      _ngcontent-ng-c1798067373=""
                      className="casino-nation-detail ng-tns-c1798067373-1"
                    >
                      <div
                        _ngcontent-ng-c1798067373=""
                        className="casino-nation-name casino-nation-card ng-tns-c1798067373-1 ng-star-inserted"
                      >
                        <img
                          _ngcontent-ng-c1798067373=""
                          className="ng-tns-c1798067373-1 ng-star-inserted"
                          src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/3.webp"
                        />

                        <div
                          _ngcontent-ng-c1798067373=""
                          className="min_max_collapse ng-tns-c1798067373-1"
                        >
                          <a
                            _ngcontent-ng-c1798067373=""
                            data-bs-toggle="collapse"
                            role="button"
                            aria-expanded="false"
                            className="minmax-btn collapsed ng-tns-c1798067373-1"
                            href="#collapserangedtll48"
                            aria-controls="collapserangedtll48"
                          >
                            <i
                              _ngcontent-ng-c1798067373=""
                              className="fas fa-info-circle ng-tns-c1798067373-1"
                            />
                          </a>
                          <div
                            _ngcontent-ng-c1798067373=""
                            className="range-collpase collapse ng-tns-c1798067373-1"
                            id="collapserangedtll48"
                          >
                            <span
                              _ngcontent-ng-c1798067373=""
                              className="min_max_span ms-0 ng-tns-c1798067373-1"
                            >
                              <b
                                _ngcontent-ng-c1798067373=""
                                className="ng-tns-c1798067373-1"
                              >
                                Min:
                              </b>
                              <span
                                _ngcontent-ng-c1798067373=""
                                className="ng-tns-c1798067373-1"
                              >
                                100
                              </span>
                            </span>
                            <span
                              _ngcontent-ng-c1798067373=""
                              className="min_max_span ms-1 ng-tns-c1798067373-1"
                            >
                              <b
                                _ngcontent-ng-c1798067373=""
                                className="ng-tns-c1798067373-1"
                              >
                                Max:
                              </b>
                              <span
                                _ngcontent-ng-c1798067373=""
                                className="ng-tns-c1798067373-1"
                              >
                                100000
                              </span>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-1 back casino-odds-box suspended-box"
                    >
                      <span
                        _ngcontent-ng-c1798067373=""
                        className="casino-odds ng-tns-c1798067373-1"
                      >
                        12
                      </span>
                      <p
                        _ngcontent-ng-c1798067373=""
                        className="ng-tns-c1798067373-1"
                      >
                        {" "}
                        0{" "}
                      </p>
                    </div>
                  </div>
                  <div
                    _ngcontent-ng-c1798067373=""
                    className="casino-table-row ng-tns-c1798067373-1 ng-star-inserted"
                  >
                    <div
                      _ngcontent-ng-c1798067373=""
                      className="casino-nation-detail ng-tns-c1798067373-1"
                    >
                      <div
                        _ngcontent-ng-c1798067373=""
                        className="casino-nation-name casino-nation-card ng-tns-c1798067373-1 ng-star-inserted"
                      >
                        <img
                          _ngcontent-ng-c1798067373=""
                          className="ng-tns-c1798067373-1 ng-star-inserted"
                          src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/4.webp"
                        />

                        <div
                          _ngcontent-ng-c1798067373=""
                          className="min_max_collapse ng-tns-c1798067373-1"
                        >
                          <a
                            _ngcontent-ng-c1798067373=""
                            data-bs-toggle="collapse"
                            role="button"
                            aria-expanded="false"
                            className="minmax-btn collapsed ng-tns-c1798067373-1"
                            href="#collapserangedtll49"
                            aria-controls="collapserangedtll49"
                          >
                            <i
                              _ngcontent-ng-c1798067373=""
                              className="fas fa-info-circle ng-tns-c1798067373-1"
                            />
                          </a>
                          <div
                            _ngcontent-ng-c1798067373=""
                            className="range-collpase collapse ng-tns-c1798067373-1"
                            id="collapserangedtll49"
                          >
                            <span
                              _ngcontent-ng-c1798067373=""
                              className="min_max_span ms-0 ng-tns-c1798067373-1"
                            >
                              <b
                                _ngcontent-ng-c1798067373=""
                                className="ng-tns-c1798067373-1"
                              >
                                Min:
                              </b>
                              <span
                                _ngcontent-ng-c1798067373=""
                                className="ng-tns-c1798067373-1"
                              >
                                100
                              </span>
                            </span>
                            <span
                              _ngcontent-ng-c1798067373=""
                              className="min_max_span ms-1 ng-tns-c1798067373-1"
                            >
                              <b
                                _ngcontent-ng-c1798067373=""
                                className="ng-tns-c1798067373-1"
                              >
                                Max:
                              </b>
                              <span
                                _ngcontent-ng-c1798067373=""
                                className="ng-tns-c1798067373-1"
                              >
                                100000
                              </span>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-1 back casino-odds-box suspended-box"
                    >
                      <span
                        _ngcontent-ng-c1798067373=""
                        className="casino-odds ng-tns-c1798067373-1"
                      >
                        12
                      </span>
                      <p
                        _ngcontent-ng-c1798067373=""
                        className="ng-tns-c1798067373-1"
                      >
                        {" "}
                        0{" "}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div
                _ngcontent-ng-c1798067373=""
                className="casino-table-right-box ng-tns-c1798067373-1"
              >
                <div
                  _ngcontent-ng-c1798067373=""
                  className="casino-table-body ng-tns-c1798067373-1"
                >
                  <div
                    _ngcontent-ng-c1798067373=""
                    className="casino-table-row ng-tns-c1798067373-1 ng-star-inserted"
                  >
                    <div
                      _ngcontent-ng-c1798067373=""
                      className="casino-nation-detail ng-tns-c1798067373-1"
                    >
                      <div
                        _ngcontent-ng-c1798067373=""
                        className="casino-nation-name casino-nation-card ng-tns-c1798067373-1"
                      >
                        <img
                          _ngcontent-ng-c1798067373=""
                          className="ng-tns-c1798067373-1 ng-star-inserted"
                          src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/5.webp"
                        />

                        <div
                          _ngcontent-ng-c1798067373=""
                          className="min_max_collapse ng-tns-c1798067373-1"
                        >
                          <a
                            _ngcontent-ng-c1798067373=""
                            data-bs-toggle="collapse"
                            role="button"
                            aria-expanded="false"
                            className="minmax-btn collapsed ng-tns-c1798067373-1"
                            href="#collapserangedtll50"
                            aria-controls="collapserangedtll50"
                          >
                            <i
                              _ngcontent-ng-c1798067373=""
                              className="fas fa-info-circle ng-tns-c1798067373-1"
                            />
                          </a>
                          <div
                            _ngcontent-ng-c1798067373=""
                            className="range-collpase collapse ng-tns-c1798067373-1"
                            id="collapserangedtll50"
                          >
                            <span
                              _ngcontent-ng-c1798067373=""
                              className="min_max_span ms-0 ng-tns-c1798067373-1"
                            >
                              <b
                                _ngcontent-ng-c1798067373=""
                                className="ng-tns-c1798067373-1"
                              >
                                Min:
                              </b>
                              <span
                                _ngcontent-ng-c1798067373=""
                                className="ng-tns-c1798067373-1"
                              >
                                100
                              </span>
                            </span>
                            <span
                              _ngcontent-ng-c1798067373=""
                              className="min_max_span ms-1 ng-tns-c1798067373-1"
                            >
                              <b
                                _ngcontent-ng-c1798067373=""
                                className="ng-tns-c1798067373-1"
                              >
                                Max:
                              </b>
                              <span
                                _ngcontent-ng-c1798067373=""
                                className="ng-tns-c1798067373-1"
                              >
                                100000
                              </span>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-1 back casino-odds-box suspended-box"
                    >
                      <span
                        _ngcontent-ng-c1798067373=""
                        className="casino-odds ng-tns-c1798067373-1"
                      >
                        12
                      </span>
                      <p
                        _ngcontent-ng-c1798067373=""
                        className="ng-tns-c1798067373-1"
                      >
                        {" "}
                        0{" "}
                      </p>
                    </div>
                  </div>
                  <div
                    _ngcontent-ng-c1798067373=""
                    className="casino-table-row ng-tns-c1798067373-1 ng-star-inserted"
                  >
                    <div
                      _ngcontent-ng-c1798067373=""
                      className="casino-nation-detail ng-tns-c1798067373-1"
                    >
                      <div
                        _ngcontent-ng-c1798067373=""
                        className="casino-nation-name casino-nation-card ng-tns-c1798067373-1"
                      >
                        <img
                          _ngcontent-ng-c1798067373=""
                          className="ng-tns-c1798067373-1 ng-star-inserted"
                          src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/6.webp"
                        />

                        <div
                          _ngcontent-ng-c1798067373=""
                          className="min_max_collapse ng-tns-c1798067373-1"
                        >
                          <a
                            _ngcontent-ng-c1798067373=""
                            data-bs-toggle="collapse"
                            role="button"
                            aria-expanded="false"
                            className="minmax-btn collapsed ng-tns-c1798067373-1"
                            href="#collapserangedtll51"
                            aria-controls="collapserangedtll51"
                          >
                            <i
                              _ngcontent-ng-c1798067373=""
                              className="fas fa-info-circle ng-tns-c1798067373-1"
                            />
                          </a>
                          <div
                            _ngcontent-ng-c1798067373=""
                            className="range-collpase collapse ng-tns-c1798067373-1"
                            id="collapserangedtll51"
                          >
                            <span
                              _ngcontent-ng-c1798067373=""
                              className="min_max_span ms-0 ng-tns-c1798067373-1"
                            >
                              <b
                                _ngcontent-ng-c1798067373=""
                                className="ng-tns-c1798067373-1"
                              >
                                Min:
                              </b>
                              <span
                                _ngcontent-ng-c1798067373=""
                                className="ng-tns-c1798067373-1"
                              >
                                100
                              </span>
                            </span>
                            <span
                              _ngcontent-ng-c1798067373=""
                              className="min_max_span ms-1 ng-tns-c1798067373-1"
                            >
                              <b
                                _ngcontent-ng-c1798067373=""
                                className="ng-tns-c1798067373-1"
                              >
                                Max:
                              </b>
                              <span
                                _ngcontent-ng-c1798067373=""
                                className="ng-tns-c1798067373-1"
                              >
                                100000
                              </span>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-1 back casino-odds-box suspended-box"
                    >
                      <span
                        _ngcontent-ng-c1798067373=""
                        className="casino-odds ng-tns-c1798067373-1"
                      >
                        12
                      </span>
                      <p
                        _ngcontent-ng-c1798067373=""
                        className="ng-tns-c1798067373-1"
                      >
                        {" "}
                        0{" "}
                      </p>
                    </div>
                  </div>
                  <div
                    _ngcontent-ng-c1798067373=""
                    className="casino-table-row ng-tns-c1798067373-1 ng-star-inserted"
                  >
                    <div
                      _ngcontent-ng-c1798067373=""
                      className="casino-nation-detail ng-tns-c1798067373-1"
                    >
                      <div
                        _ngcontent-ng-c1798067373=""
                        className="casino-nation-name casino-nation-card ng-tns-c1798067373-1"
                      >
                        <img
                          _ngcontent-ng-c1798067373=""
                          className="ng-tns-c1798067373-1 ng-star-inserted"
                          src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/7.webp"
                        />

                        <div
                          _ngcontent-ng-c1798067373=""
                          className="min_max_collapse ng-tns-c1798067373-1"
                        >
                          <a
                            _ngcontent-ng-c1798067373=""
                            data-bs-toggle="collapse"
                            role="button"
                            aria-expanded="false"
                            className="minmax-btn collapsed ng-tns-c1798067373-1"
                            href="#collapserangedtll52"
                            aria-controls="collapserangedtll52"
                          >
                            <i
                              _ngcontent-ng-c1798067373=""
                              className="fas fa-info-circle ng-tns-c1798067373-1"
                            />
                          </a>
                          <div
                            _ngcontent-ng-c1798067373=""
                            className="range-collpase collapse ng-tns-c1798067373-1"
                            id="collapserangedtll52"
                          >
                            <span
                              _ngcontent-ng-c1798067373=""
                              className="min_max_span ms-0 ng-tns-c1798067373-1"
                            >
                              <b
                                _ngcontent-ng-c1798067373=""
                                className="ng-tns-c1798067373-1"
                              >
                                Min:
                              </b>
                              <span
                                _ngcontent-ng-c1798067373=""
                                className="ng-tns-c1798067373-1"
                              >
                                100
                              </span>
                            </span>
                            <span
                              _ngcontent-ng-c1798067373=""
                              className="min_max_span ms-1 ng-tns-c1798067373-1"
                            >
                              <b
                                _ngcontent-ng-c1798067373=""
                                className="ng-tns-c1798067373-1"
                              >
                                Max:
                              </b>
                              <span
                                _ngcontent-ng-c1798067373=""
                                className="ng-tns-c1798067373-1"
                              >
                                100000
                              </span>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-1 back casino-odds-box suspended-box"
                    >
                      <span
                        _ngcontent-ng-c1798067373=""
                        className="casino-odds ng-tns-c1798067373-1"
                      >
                        12
                      </span>
                      <p
                        _ngcontent-ng-c1798067373=""
                        className="ng-tns-c1798067373-1"
                      >
                        {" "}
                        0{" "}
                      </p>
                    </div>
                  </div>
                  <div
                    _ngcontent-ng-c1798067373=""
                    className="casino-table-row ng-tns-c1798067373-1 ng-star-inserted"
                  >
                    <div
                      _ngcontent-ng-c1798067373=""
                      className="casino-nation-detail ng-tns-c1798067373-1"
                    >
                      <div
                        _ngcontent-ng-c1798067373=""
                        className="casino-nation-name casino-nation-card ng-tns-c1798067373-1"
                      >
                        <img
                          _ngcontent-ng-c1798067373=""
                          className="ng-tns-c1798067373-1 ng-star-inserted"
                          src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/8.webp"
                        />

                        <div
                          _ngcontent-ng-c1798067373=""
                          className="min_max_collapse ng-tns-c1798067373-1"
                        >
                          <a
                            _ngcontent-ng-c1798067373=""
                            data-bs-toggle="collapse"
                            role="button"
                            aria-expanded="false"
                            className="minmax-btn collapsed ng-tns-c1798067373-1"
                            href="#collapserangedtll53"
                            aria-controls="collapserangedtll53"
                          >
                            <i
                              _ngcontent-ng-c1798067373=""
                              className="fas fa-info-circle ng-tns-c1798067373-1"
                            />
                          </a>
                          <div
                            _ngcontent-ng-c1798067373=""
                            className="range-collpase collapse ng-tns-c1798067373-1"
                            id="collapserangedtll53"
                          >
                            <span
                              _ngcontent-ng-c1798067373=""
                              className="min_max_span ms-0 ng-tns-c1798067373-1"
                            >
                              <b
                                _ngcontent-ng-c1798067373=""
                                className="ng-tns-c1798067373-1"
                              >
                                Min:
                              </b>
                              <span
                                _ngcontent-ng-c1798067373=""
                                className="ng-tns-c1798067373-1"
                              >
                                100
                              </span>
                            </span>
                            <span
                              _ngcontent-ng-c1798067373=""
                              className="min_max_span ms-1 ng-tns-c1798067373-1"
                            >
                              <b
                                _ngcontent-ng-c1798067373=""
                                className="ng-tns-c1798067373-1"
                              >
                                Max:
                              </b>
                              <span
                                _ngcontent-ng-c1798067373=""
                                className="ng-tns-c1798067373-1"
                              >
                                100000
                              </span>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-1 back casino-odds-box suspended-box"
                    >
                      <span
                        _ngcontent-ng-c1798067373=""
                        className="casino-odds ng-tns-c1798067373-1"
                      >
                        12
                      </span>
                      <p
                        _ngcontent-ng-c1798067373=""
                        className="ng-tns-c1798067373-1"
                      >
                        {" "}
                        0{" "}
                      </p>
                    </div>
                  </div>
                  <div
                    _ngcontent-ng-c1798067373=""
                    className="casino-table-row ng-tns-c1798067373-1 ng-star-inserted"
                  >
                    <div
                      _ngcontent-ng-c1798067373=""
                      className="casino-nation-detail ng-tns-c1798067373-1"
                    >
                      <div
                        _ngcontent-ng-c1798067373=""
                        className="casino-nation-name casino-nation-card ng-tns-c1798067373-1"
                      >
                        <img
                          _ngcontent-ng-c1798067373=""
                          className="ng-tns-c1798067373-1 ng-star-inserted"
                          src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/9.webp"
                        />

                        <div
                          _ngcontent-ng-c1798067373=""
                          className="min_max_collapse ng-tns-c1798067373-1"
                        >
                          <a
                            _ngcontent-ng-c1798067373=""
                            data-bs-toggle="collapse"
                            role="button"
                            aria-expanded="false"
                            className="minmax-btn collapsed ng-tns-c1798067373-1"
                            href="#collapserangedtll54"
                            aria-controls="collapserangedtll54"
                          >
                            <i
                              _ngcontent-ng-c1798067373=""
                              className="fas fa-info-circle ng-tns-c1798067373-1"
                            />
                          </a>
                          <div
                            _ngcontent-ng-c1798067373=""
                            className="range-collpase collapse ng-tns-c1798067373-1"
                            id="collapserangedtll54"
                          >
                            <span
                              _ngcontent-ng-c1798067373=""
                              className="min_max_span ms-0 ng-tns-c1798067373-1"
                            >
                              <b
                                _ngcontent-ng-c1798067373=""
                                className="ng-tns-c1798067373-1"
                              >
                                Min:
                              </b>
                              <span
                                _ngcontent-ng-c1798067373=""
                                className="ng-tns-c1798067373-1"
                              >
                                100
                              </span>
                            </span>
                            <span
                              _ngcontent-ng-c1798067373=""
                              className="min_max_span ms-1 ng-tns-c1798067373-1"
                            >
                              <b
                                _ngcontent-ng-c1798067373=""
                                className="ng-tns-c1798067373-1"
                              >
                                Max:
                              </b>
                              <span
                                _ngcontent-ng-c1798067373=""
                                className="ng-tns-c1798067373-1"
                              >
                                100000
                              </span>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-1 back casino-odds-box suspended-box"
                    >
                      <span
                        _ngcontent-ng-c1798067373=""
                        className="casino-odds ng-tns-c1798067373-1"
                      >
                        12
                      </span>
                      <p
                        _ngcontent-ng-c1798067373=""
                        className="ng-tns-c1798067373-1"
                      >
                        {" "}
                        0{" "}
                      </p>
                    </div>
                  </div>
                  <div
                    _ngcontent-ng-c1798067373=""
                    className="casino-table-row ng-tns-c1798067373-1 ng-star-inserted"
                  >
                    <div
                      _ngcontent-ng-c1798067373=""
                      className="casino-nation-detail ng-tns-c1798067373-1"
                    >
                      <div
                        _ngcontent-ng-c1798067373=""
                        className="casino-nation-name casino-nation-card ng-tns-c1798067373-1"
                      >
                        <img
                          _ngcontent-ng-c1798067373=""
                          className="ng-tns-c1798067373-1 ng-star-inserted"
                          src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/10.webp"
                        />

                        <div
                          _ngcontent-ng-c1798067373=""
                          className="min_max_collapse ng-tns-c1798067373-1"
                        >
                          <a
                            _ngcontent-ng-c1798067373=""
                            data-bs-toggle="collapse"
                            role="button"
                            aria-expanded="false"
                            className="minmax-btn collapsed ng-tns-c1798067373-1"
                            href="#collapserangedtll55"
                            aria-controls="collapserangedtll55"
                          >
                            <i
                              _ngcontent-ng-c1798067373=""
                              className="fas fa-info-circle ng-tns-c1798067373-1"
                            />
                          </a>
                          <div
                            _ngcontent-ng-c1798067373=""
                            className="range-collpase collapse ng-tns-c1798067373-1"
                            id="collapserangedtll55"
                          >
                            <span
                              _ngcontent-ng-c1798067373=""
                              className="min_max_span ms-0 ng-tns-c1798067373-1"
                            >
                              <b
                                _ngcontent-ng-c1798067373=""
                                className="ng-tns-c1798067373-1"
                              >
                                Min:
                              </b>
                              <span
                                _ngcontent-ng-c1798067373=""
                                className="ng-tns-c1798067373-1"
                              >
                                100
                              </span>
                            </span>
                            <span
                              _ngcontent-ng-c1798067373=""
                              className="min_max_span ms-1 ng-tns-c1798067373-1"
                            >
                              <b
                                _ngcontent-ng-c1798067373=""
                                className="ng-tns-c1798067373-1"
                              >
                                Max:
                              </b>
                              <span
                                _ngcontent-ng-c1798067373=""
                                className="ng-tns-c1798067373-1"
                              >
                                100000
                              </span>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-1 back casino-odds-box suspended-box"
                    >
                      <span
                        _ngcontent-ng-c1798067373=""
                        className="casino-odds ng-tns-c1798067373-1"
                      >
                        12
                      </span>
                      <p
                        _ngcontent-ng-c1798067373=""
                        className="ng-tns-c1798067373-1"
                      >
                        {" "}
                        0{" "}
                      </p>
                    </div>
                  </div>
                  <div
                    _ngcontent-ng-c1798067373=""
                    className="casino-table-row ng-tns-c1798067373-1 ng-star-inserted"
                  >
                    <div
                      _ngcontent-ng-c1798067373=""
                      className="casino-nation-detail ng-tns-c1798067373-1"
                    >
                      <div
                        _ngcontent-ng-c1798067373=""
                        className="casino-nation-name casino-nation-card ng-tns-c1798067373-1"
                      >
                        <img
                          _ngcontent-ng-c1798067373=""
                          className="ng-tns-c1798067373-1 ng-star-inserted"
                          src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/J.webp"
                        />

                        <div
                          _ngcontent-ng-c1798067373=""
                          className="min_max_collapse ng-tns-c1798067373-1"
                        >
                          <a
                            _ngcontent-ng-c1798067373=""
                            data-bs-toggle="collapse"
                            role="button"
                            aria-expanded="false"
                            className="minmax-btn collapsed ng-tns-c1798067373-1"
                            href="#collapserangedtll56"
                            aria-controls="collapserangedtll56"
                          >
                            <i
                              _ngcontent-ng-c1798067373=""
                              className="fas fa-info-circle ng-tns-c1798067373-1"
                            />
                          </a>
                          <div
                            _ngcontent-ng-c1798067373=""
                            className="range-collpase collapse ng-tns-c1798067373-1"
                            id="collapserangedtll56"
                          >
                            <span
                              _ngcontent-ng-c1798067373=""
                              className="min_max_span ms-0 ng-tns-c1798067373-1"
                            >
                              <b
                                _ngcontent-ng-c1798067373=""
                                className="ng-tns-c1798067373-1"
                              >
                                Min:
                              </b>
                              <span
                                _ngcontent-ng-c1798067373=""
                                className="ng-tns-c1798067373-1"
                              >
                                100
                              </span>
                            </span>
                            <span
                              _ngcontent-ng-c1798067373=""
                              className="min_max_span ms-1 ng-tns-c1798067373-1"
                            >
                              <b
                                _ngcontent-ng-c1798067373=""
                                className="ng-tns-c1798067373-1"
                              >
                                Max:
                              </b>
                              <span
                                _ngcontent-ng-c1798067373=""
                                className="ng-tns-c1798067373-1"
                              >
                                100000
                              </span>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-1 back casino-odds-box suspended-box"
                    >
                      <span
                        _ngcontent-ng-c1798067373=""
                        className="casino-odds ng-tns-c1798067373-1"
                      >
                        12
                      </span>
                      <p
                        _ngcontent-ng-c1798067373=""
                        className="ng-tns-c1798067373-1"
                      >
                        {" "}
                        0{" "}
                      </p>
                    </div>
                  </div>
                  <div
                    _ngcontent-ng-c1798067373=""
                    className="casino-table-row ng-tns-c1798067373-1 ng-star-inserted"
                  >
                    <div
                      _ngcontent-ng-c1798067373=""
                      className="casino-nation-detail ng-tns-c1798067373-1"
                    >
                      <div
                        _ngcontent-ng-c1798067373=""
                        className="casino-nation-name casino-nation-card ng-tns-c1798067373-1"
                      >
                        <img
                          _ngcontent-ng-c1798067373=""
                          className="ng-tns-c1798067373-1 ng-star-inserted"
                          src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/Q.webp"
                        />

                        <div
                          _ngcontent-ng-c1798067373=""
                          className="min_max_collapse ng-tns-c1798067373-1"
                        >
                          <a
                            _ngcontent-ng-c1798067373=""
                            data-bs-toggle="collapse"
                            role="button"
                            aria-expanded="false"
                            className="minmax-btn collapsed ng-tns-c1798067373-1"
                            href="#collapserangedtll57"
                            aria-controls="collapserangedtll57"
                          >
                            <i
                              _ngcontent-ng-c1798067373=""
                              className="fas fa-info-circle ng-tns-c1798067373-1"
                            />
                          </a>
                          <div
                            _ngcontent-ng-c1798067373=""
                            className="range-collpase collapse ng-tns-c1798067373-1"
                            id="collapserangedtll57"
                          >
                            <span
                              _ngcontent-ng-c1798067373=""
                              className="min_max_span ms-0 ng-tns-c1798067373-1"
                            >
                              <b
                                _ngcontent-ng-c1798067373=""
                                className="ng-tns-c1798067373-1"
                              >
                                Min:
                              </b>
                              <span
                                _ngcontent-ng-c1798067373=""
                                className="ng-tns-c1798067373-1"
                              >
                                100
                              </span>
                            </span>
                            <span
                              _ngcontent-ng-c1798067373=""
                              className="min_max_span ms-1 ng-tns-c1798067373-1"
                            >
                              <b
                                _ngcontent-ng-c1798067373=""
                                className="ng-tns-c1798067373-1"
                              >
                                Max:
                              </b>
                              <span
                                _ngcontent-ng-c1798067373=""
                                className="ng-tns-c1798067373-1"
                              >
                                100000
                              </span>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-1 back casino-odds-box suspended-box"
                    >
                      <span
                        _ngcontent-ng-c1798067373=""
                        className="casino-odds ng-tns-c1798067373-1"
                      >
                        12
                      </span>
                      <p
                        _ngcontent-ng-c1798067373=""
                        className="ng-tns-c1798067373-1"
                      >
                        {" "}
                        0{" "}
                      </p>
                    </div>
                  </div>
                  <div
                    _ngcontent-ng-c1798067373=""
                    className="casino-table-row ng-tns-c1798067373-1 ng-star-inserted"
                  >
                    <div
                      _ngcontent-ng-c1798067373=""
                      className="casino-nation-detail ng-tns-c1798067373-1"
                    >
                      <div
                        _ngcontent-ng-c1798067373=""
                        className="casino-nation-name casino-nation-card ng-tns-c1798067373-1"
                      >
                        <img
                          _ngcontent-ng-c1798067373=""
                          className="ng-tns-c1798067373-1 ng-star-inserted"
                          src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/K.webp"
                        />

                        <div
                          _ngcontent-ng-c1798067373=""
                          className="min_max_collapse ng-tns-c1798067373-1"
                        >
                          <a
                            _ngcontent-ng-c1798067373=""
                            data-bs-toggle="collapse"
                            role="button"
                            aria-expanded="false"
                            className="minmax-btn collapsed ng-tns-c1798067373-1"
                            href="#collapserangedtll58"
                            aria-controls="collapserangedtll58"
                          >
                            <i
                              _ngcontent-ng-c1798067373=""
                              className="fas fa-info-circle ng-tns-c1798067373-1"
                            />
                          </a>
                          <div
                            _ngcontent-ng-c1798067373=""
                            className="range-collpase collapse ng-tns-c1798067373-1"
                            id="collapserangedtll58"
                          >
                            <span
                              _ngcontent-ng-c1798067373=""
                              className="min_max_span ms-0 ng-tns-c1798067373-1"
                            >
                              <b
                                _ngcontent-ng-c1798067373=""
                                className="ng-tns-c1798067373-1"
                              >
                                Min:
                              </b>
                              <span
                                _ngcontent-ng-c1798067373=""
                                className="ng-tns-c1798067373-1"
                              >
                                100
                              </span>
                            </span>
                            <span
                              _ngcontent-ng-c1798067373=""
                              className="min_max_span ms-1 ng-tns-c1798067373-1"
                            >
                              <b
                                _ngcontent-ng-c1798067373=""
                                className="ng-tns-c1798067373-1"
                              >
                                Max:
                              </b>
                              <span
                                _ngcontent-ng-c1798067373=""
                                className="ng-tns-c1798067373-1"
                              >
                                100000
                              </span>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-1 back casino-odds-box suspended-box"
                    >
                      <span
                        _ngcontent-ng-c1798067373=""
                        className="casino-odds ng-tns-c1798067373-1"
                      >
                        12
                      </span>
                      <p
                        _ngcontent-ng-c1798067373=""
                        className="ng-tns-c1798067373-1"
                      >
                        {" "}
                        0{" "}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </CasinoPage>
  );
}
