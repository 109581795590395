import React from "react";
import "./index.scoped.css";
import {
  getCasinoBetsAPI,
  getCasinoLastTenResultAPI,
  getCasinoOddsAPI,
} from "../../service/casino";
import { useQuery } from "react-query";
import CasinoVideo from "./components/CasinoVideo";
import { CASINO_VIDEO, getOddForEntity, setBet } from "./CasinoGeneric";
import { useDispatch } from "react-redux";
import PlaceBet from "./components/PlaceBet";
import TimerGeneric from "./components/TimerGeneric";
import Timer from "./components/Timer";
import PlacedBet from "./components/PlacedBet";
import CasinoPage from "./components/CasinoPage";
export default function SuperOver() {
  const GAME_ID = "SUPEROVER";
  const dispatch = useDispatch();
  const casinoData = useQuery(
    "getCasinoOdds",
    () => getCasinoOddsAPI(GAME_ID),
    {
      refetchInterval: 800,
    }
  );
  const lastTenResultData = useQuery(
    "getResult",
    () => getCasinoLastTenResultAPI(GAME_ID),
    {
      refetchInterval: 1500,
    }
  );
  const casinoBets = useQuery("casinoBets", () => getCasinoBetsAPI(GAME_ID), {
    refetchInterval: 2500,
  });
  return (
    <>
      <CasinoPage
        casino={casinoData?.data}
        VIDEO_ID={CASINO_VIDEO.superOver}
        GAME_ID={GAME_ID}
        shortName={"super-over"}
        gameName={"Super Over"}
      >
        <div
          _ngcontent-ng-c1798067373=""
          className="ng-tns-c1798067373-4 ng-star-inserted"
        >
          <div
            _ngcontent-ng-c1798067373=""
            className="casino-detail ng-tns-c1798067373-4"
          >
            <div
              _ngcontent-ng-c1798067373=""
              className="desktop-design d-lg-block d-none ng-tns-c1798067373-4"
            >
              <div
                _ngcontent-ng-c1798067373=""
                className="game-market market-4 ng-tns-c1798067373-4"
              >
                <div
                  _ngcontent-ng-c1798067373=""
                  className="market-title ng-tns-c1798067373-4"
                >
                  <span
                    _ngcontent-ng-c1798067373=""
                    className="ng-tns-c1798067373-4"
                  >
                    Bookmaker Market
                  </span>
                  <a
                    _ngcontent-ng-c1798067373=""
                    href="javascript:void(0);"
                    data-bs-toggle="modal"
                    data-bs-target="#BookMarkerModal"
                    type="button"
                    className="rules_btn ng-tns-c1798067373-4"
                  >
                    <i
                      _ngcontent-ng-c1798067373=""
                      className="fas fa-info-circle ng-tns-c1798067373-4"
                    />
                  </a>
                </div>
                <div
                  _ngcontent-ng-c1798067373=""
                  className="market-header ng-tns-c1798067373-4"
                >
                  <div
                    _ngcontent-ng-c1798067373=""
                    className="market-nation-detail ng-tns-c1798067373-4"
                  >
                    <span
                      _ngcontent-ng-c1798067373=""
                      className="market-nation-name ng-tns-c1798067373-4"
                    />
                  </div>
                  <div
                    _ngcontent-ng-c1798067373=""
                    className="market-odd-box no-border d-none d-md-block ng-tns-c1798067373-4"
                  />
                  <div
                    _ngcontent-ng-c1798067373=""
                    className="market-odd-box no-border d-none d-md-block ng-tns-c1798067373-4"
                  />
                  <div
                    _ngcontent-ng-c1798067373=""
                    className="market-odd-box back ng-tns-c1798067373-4"
                  >
                    <b
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-4"
                    >
                      Back
                    </b>
                  </div>
                  <div
                    _ngcontent-ng-c1798067373=""
                    className="market-odd-box lay ng-tns-c1798067373-4"
                  >
                    <b
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-4"
                    >
                      Lay
                    </b>
                  </div>
                  <div
                    _ngcontent-ng-c1798067373=""
                    className="market-odd-box no-border ng-tns-c1798067373-4"
                  />
                  <div
                    _ngcontent-ng-c1798067373=""
                    className="market-odd-box no-border ng-tns-c1798067373-4"
                  />
                </div>
                <div
                  _ngcontent-ng-c1798067373=""
                  data-title="OPEN"
                  className="market-body ng-tns-c1798067373-4"
                >
                  <div
                    _ngcontent-ng-c1798067373=""
                    data-title="ACTIVE"
                    className="market-row ng-tns-c1798067373-4 ng-star-inserted"
                  >
                    <div
                      _ngcontent-ng-c1798067373=""
                      className="market-nation-detail ng-tns-c1798067373-4"
                    >
                      <span
                        _ngcontent-ng-c1798067373=""
                        className="market-nation-name ng-tns-c1798067373-4"
                      >
                        ENG
                      </span>
                      <div
                        _ngcontent-ng-c1798067373=""
                        className="market-nation-book ng-tns-c1798067373-4"
                      >
                        <span
                          _ngcontent-ng-c1798067373=""
                          id="book_1"
                          className="float-left book ng-tns-c1798067373-4"
                          style={{ color: "black" }}
                        >
                          <span
                            _ngcontent-ng-c1798067373=""
                            className="ng-tns-c1798067373-4"
                          >
                            {" "}
                            0{" "}
                          </span>
                        </span>
                      </div>
                    </div>
                    <div
                      _ngcontent-ng-c1798067373=""
                      data-title="SUSPENDED"
                      className={`Market-odd-row-box ng-tns-c1798067373-4 ${
                        casinoData?.data?.response.autoTime < 5
                          ? "suspended-table"
                          : ""
                      }`}
                    >
                      <div
                        _ngcontent-ng-c1798067373=""
                        className="back2 market-odd-box ng-tns-c1798067373-4"
                      >
                        <span
                          _ngcontent-ng-c1798067373=""
                          className="market-odd ng-tns-c1798067373-4"
                        />
                        <span
                          _ngcontent-ng-c1798067373=""
                          className="market-volume ng-tns-c1798067373-4"
                        />
                      </div>
                      <div
                        _ngcontent-ng-c1798067373=""
                        className="back1 market-odd-box ng-tns-c1798067373-4"
                      >
                        <span
                          _ngcontent-ng-c1798067373=""
                          className="market-odd ng-tns-c1798067373-4"
                        />
                        <span
                          _ngcontent-ng-c1798067373=""
                          className="market-volume ng-tns-c1798067373-4"
                        />
                      </div>
                      <div
                        _ngcontent-ng-c1798067373=""
                        className="back market-odd-box ng-tns-c1798067373-4"
                        onClick={() =>
                          setBet("ENG", casinoData, dispatch, "back")
                        }
                      >
                        <span
                          _ngcontent-ng-c1798067373=""
                          className="market-odd ng-tns-c1798067373-4"
                        >
                          {" "}
                          {getOddForEntity("ENG", casinoData, true)}
                        </span>
                        <span
                          _ngcontent-ng-c1798067373=""
                          className="market-volume ng-tns-c1798067373-4"
                        >
                          {" "}
                          {getOddForEntity("ENG", casinoData, true, true)}
                        </span>
                      </div>
                      <div
                        _ngcontent-ng-c1798067373=""
                        className="lay market-odd-box ng-tns-c1798067373-4"
                        onClick={() =>
                          setBet("ENG", casinoData, dispatch, "lay")
                        }
                      >
                        <span
                          _ngcontent-ng-c1798067373=""
                          className="market-odd ng-tns-c1798067373-4"
                        >
                          {" "}
                          {getOddForEntity("ENG", casinoData, false)}
                        </span>
                        <span
                          _ngcontent-ng-c1798067373=""
                          className="market-volume ng-tns-c1798067373-4"
                        >
                          {" "}
                          {getOddForEntity("ENG", casinoData, false, true)}
                        </span>
                      </div>
                      <div
                        _ngcontent-ng-c1798067373=""
                        className="lay1 market-odd-box ng-tns-c1798067373-4"
                      >
                        <span
                          _ngcontent-ng-c1798067373=""
                          className="market-odd ng-tns-c1798067373-4"
                        />
                        <span
                          _ngcontent-ng-c1798067373=""
                          className="market-volume ng-tns-c1798067373-4"
                        />
                      </div>
                      <div
                        _ngcontent-ng-c1798067373=""
                        className="lay2 market-odd-box ng-tns-c1798067373-4"
                      >
                        <span
                          _ngcontent-ng-c1798067373=""
                          className="market-odd ng-tns-c1798067373-4"
                        />
                        <span
                          _ngcontent-ng-c1798067373=""
                          className="market-volume ng-tns-c1798067373-4"
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    _ngcontent-ng-c1798067373=""
                    data-title="ACTIVE"
                    className="market-row ng-tns-c1798067373-4 ng-star-inserted"
                  >
                    <div
                      _ngcontent-ng-c1798067373=""
                      className="market-nation-detail ng-tns-c1798067373-4"
                    >
                      <span
                        _ngcontent-ng-c1798067373=""
                        className="market-nation-name ng-tns-c1798067373-4"
                      >
                        RSA
                      </span>
                      <div
                        _ngcontent-ng-c1798067373=""
                        className="market-nation-book ng-tns-c1798067373-4"
                      >
                        <span
                          _ngcontent-ng-c1798067373=""
                          id="book_1"
                          className="float-left book ng-tns-c1798067373-4"
                          style={{ color: "black" }}
                        >
                          <span
                            _ngcontent-ng-c1798067373=""
                            className="ng-tns-c1798067373-4"
                          >
                            {" "}
                            0{" "}
                          </span>
                        </span>
                      </div>
                    </div>
                    <div
                      _ngcontent-ng-c1798067373=""
                      data-title="SUSPENDED"
                      className={`Market-odd-row-box ng-tns-c1798067373-4 ${
                        casinoData?.data?.response.autoTime < 5
                          ? "suspended-table"
                          : ""
                      }`}
                    >
                      <div
                        _ngcontent-ng-c1798067373=""
                        className="back2 market-odd-box ng-tns-c1798067373-4"
                      >
                        <span
                          _ngcontent-ng-c1798067373=""
                          className="market-odd ng-tns-c1798067373-4"
                        />
                        <span
                          _ngcontent-ng-c1798067373=""
                          className="market-volume ng-tns-c1798067373-4"
                        />
                      </div>
                      <div
                        _ngcontent-ng-c1798067373=""
                        className="back1 market-odd-box ng-tns-c1798067373-4"
                      >
                        <span
                          _ngcontent-ng-c1798067373=""
                          className="market-odd ng-tns-c1798067373-4"
                        />
                        <span
                          _ngcontent-ng-c1798067373=""
                          className="market-volume ng-tns-c1798067373-4"
                        />
                      </div>
                      <div
                        _ngcontent-ng-c1798067373=""
                        className="back market-odd-box ng-tns-c1798067373-4"
                        onClick={() =>
                          setBet("RSA", casinoData, dispatch, "back")
                        }
                      >
                        <span
                          _ngcontent-ng-c1798067373=""
                          className="market-odd ng-tns-c1798067373-4"
                        >
                          {" "}
                          {getOddForEntity("RSA", casinoData, true)}
                        </span>
                        <span
                          _ngcontent-ng-c1798067373=""
                          className="market-volume ng-tns-c1798067373-4"
                        >
                          {" "}
                          {getOddForEntity("RSA", casinoData, true, true)}
                        </span>
                      </div>
                      <div
                        _ngcontent-ng-c1798067373=""
                        className="lay market-odd-box ng-tns-c1798067373-4"
                        onClick={() =>
                          setBet("RSA", casinoData, dispatch, "lay")
                        }
                      >
                        <span
                          _ngcontent-ng-c1798067373=""
                          className="market-odd ng-tns-c1798067373-4"
                        >
                          {" "}
                          {getOddForEntity("RSA", casinoData, false)}
                        </span>
                        <span
                          _ngcontent-ng-c1798067373=""
                          className="market-volume ng-tns-c1798067373-4"
                        >
                          {" "}
                          {getOddForEntity("RSA", casinoData, false, true)}
                        </span>
                      </div>
                      <div
                        _ngcontent-ng-c1798067373=""
                        className="lay1 market-odd-box ng-tns-c1798067373-4"
                      >
                        <span
                          _ngcontent-ng-c1798067373=""
                          className="market-odd ng-tns-c1798067373-4"
                        />
                        <span
                          _ngcontent-ng-c1798067373=""
                          className="market-volume ng-tns-c1798067373-4"
                        />
                      </div>
                      <div
                        _ngcontent-ng-c1798067373=""
                        className="lay2 market-odd-box ng-tns-c1798067373-4"
                      >
                        <span
                          _ngcontent-ng-c1798067373=""
                          className="market-odd ng-tns-c1798067373-4"
                        />
                        <span
                          _ngcontent-ng-c1798067373=""
                          className="market-volume ng-tns-c1798067373-4"
                        />
                      </div>
                    </div>
                  </div>
                  {/**/}
                </div>
              </div>
              <div
                _ngcontent-ng-c1798067373=""
                className="game-market market-6 fancy-market-design ng-tns-c1798067373-4"
              >
                <div
                  _ngcontent-ng-c1798067373=""
                  className="row row10 ng-tns-c1798067373-4"
                >
                  {/**/}
                </div>
              </div>
            </div>
            <div
              _ngcontent-ng-c1798067373=""
              className="mobile-design d-lg-none d-block ng-tns-c1798067373-4"
            >
              <div
                _ngcontent-ng-c1798067373=""
                className="game-market market-4 ng-tns-c1798067373-4"
              >
                <div
                  _ngcontent-ng-c1798067373=""
                  className="market-title ng-tns-c1798067373-4"
                >
                  <span
                    _ngcontent-ng-c1798067373=""
                    className="ng-tns-c1798067373-4"
                  >
                    Bookmaker Market
                  </span>
                  <a
                    _ngcontent-ng-c1798067373=""
                    data-bs-toggle="modal"
                    data-bs-target="#BookMarkerModal"
                    href="javascript:void(0);"
                    className="rules_btn ng-tns-c1798067373-4"
                  >
                    <i
                      _ngcontent-ng-c1798067373=""
                      className="fas fa-info-circle ng-tns-c1798067373-4"
                    />
                  </a>
                </div>
                <div
                  _ngcontent-ng-c1798067373=""
                  className="market-header ng-tns-c1798067373-4"
                >
                  <div
                    _ngcontent-ng-c1798067373=""
                    className="market-nation-detail ng-tns-c1798067373-4"
                  >
                    <span
                      _ngcontent-ng-c1798067373=""
                      className="market-nation-name ng-tns-c1798067373-4"
                    >
                      {" "}
                      Max:{" "}
                      <span
                        _ngcontent-ng-c1798067373=""
                        className="ng-tns-c1798067373-4"
                      >
                        <span
                          _ngcontent-ng-c1798067373=""
                          className="ng-tns-c1798067373-4 ng-star-inserted"
                        >
                          {" "}
                          500000{" "}
                        </span>
                        {/**/}
                        {/**/}
                      </span>
                    </span>
                  </div>
                  <div
                    _ngcontent-ng-c1798067373=""
                    className="market-odd-box no-border d-none d-md-block ng-tns-c1798067373-4"
                  />
                  <div
                    _ngcontent-ng-c1798067373=""
                    className="market-odd-box no-border d-none d-md-block ng-tns-c1798067373-4"
                  />
                  <div
                    _ngcontent-ng-c1798067373=""
                    className="market-odd-box back ng-tns-c1798067373-4"
                  >
                    <b
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-4"
                    >
                      Back
                    </b>
                  </div>
                  <div
                    _ngcontent-ng-c1798067373=""
                    className="market-odd-box lay ng-tns-c1798067373-4"
                  >
                    <b
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-4"
                    >
                      Lay
                    </b>
                  </div>
                  <div
                    _ngcontent-ng-c1798067373=""
                    className="market-odd-box no-border ng-tns-c1798067373-4"
                  />
                  <div
                    _ngcontent-ng-c1798067373=""
                    className="market-odd-box no-border ng-tns-c1798067373-4"
                  />
                </div>
                <div
                  _ngcontent-ng-c1798067373=""
                  data-title="OPEN"
                  className="market-body ng-tns-c1798067373-4"
                >
                  <div
                    _ngcontent-ng-c1798067373=""
                    data-title="ACTIVE"
                    className="market-row ng-tns-c1798067373-4 ng-star-inserted"
                  >
                    <div
                      _ngcontent-ng-c1798067373=""
                      className="market-nation-detail ng-tns-c1798067373-4"
                    >
                      <span
                        _ngcontent-ng-c1798067373=""
                        className="market-nation-name ng-tns-c1798067373-4"
                      >
                        ENG
                      </span>
                      <div
                        _ngcontent-ng-c1798067373=""
                        className="market-nation-book ng-tns-c1798067373-4"
                      >
                        <span
                          _ngcontent-ng-c1798067373=""
                          id="book_1"
                          className="float-left book ng-tns-c1798067373-4"
                          style={{ color: "black" }}
                        >
                          <span
                            _ngcontent-ng-c1798067373=""
                            className="ng-tns-c1798067373-4"
                          >
                            {" "}
                            0{" "}
                          </span>
                        </span>
                      </div>
                    </div>
                    <div
                      _ngcontent-ng-c1798067373=""
                      data-title="SUSPENDED"
                      className="Market-odd-row-box ng-tns-c1798067373-4 suspended-table"
                    >
                      <div
                        _ngcontent-ng-c1798067373=""
                        type="button"
                        data-bs-toggle="modal"
                        className="market-odd-box back2 ng-tns-c1798067373-4"
                      >
                        <span
                          _ngcontent-ng-c1798067373=""
                          className="market-odd ng-tns-c1798067373-4"
                        />
                        <span
                          _ngcontent-ng-c1798067373=""
                          className="market-volume ng-tns-c1798067373-4"
                        />
                      </div>
                      <div
                        _ngcontent-ng-c1798067373=""
                        type="button"
                        data-bs-toggle="modal"
                        className="market-odd-box back1 ng-tns-c1798067373-4"
                      >
                        <span
                          _ngcontent-ng-c1798067373=""
                          className="market-odd ng-tns-c1798067373-4"
                        />
                        <span
                          _ngcontent-ng-c1798067373=""
                          className="market-volume ng-tns-c1798067373-4"
                        />
                      </div>
                      <div
                        _ngcontent-ng-c1798067373=""
                        className="back market-odd-box ng-tns-c1798067373-4"
                      >
                        <span
                          _ngcontent-ng-c1798067373=""
                          className="market-odd ng-tns-c1798067373-4"
                        >
                          {" "}
                          0
                        </span>
                        <span
                          _ngcontent-ng-c1798067373=""
                          className="market-volume ng-tns-c1798067373-4"
                        >
                          {" "}
                          0{" "}
                        </span>
                      </div>
                      <div
                        _ngcontent-ng-c1798067373=""
                        className="lay market-odd-box ng-tns-c1798067373-4"
                      >
                        <span
                          _ngcontent-ng-c1798067373=""
                          className="market-odd ng-tns-c1798067373-4"
                        >
                          {" "}
                          0
                        </span>
                        <span
                          _ngcontent-ng-c1798067373=""
                          className="market-volume ng-tns-c1798067373-4"
                        >
                          {" "}
                          0{" "}
                        </span>
                      </div>
                      <div
                        _ngcontent-ng-c1798067373=""
                        type="button"
                        data-bs-toggle="modal"
                        className="market-odd-box lay1 ng-tns-c1798067373-4"
                      >
                        <span
                          _ngcontent-ng-c1798067373=""
                          className="market-odd ng-tns-c1798067373-4"
                        />
                        <span
                          _ngcontent-ng-c1798067373=""
                          className="market-volume ng-tns-c1798067373-4"
                        />
                      </div>
                      <div
                        _ngcontent-ng-c1798067373=""
                        type="button"
                        data-bs-toggle="modal"
                        className="market-odd-box lay2 ng-tns-c1798067373-4"
                      >
                        <span
                          _ngcontent-ng-c1798067373=""
                          className="market-odd ng-tns-c1798067373-4"
                        />
                        <span
                          _ngcontent-ng-c1798067373=""
                          className="market-volume ng-tns-c1798067373-4"
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    _ngcontent-ng-c1798067373=""
                    data-title="ACTIVE"
                    className="market-row ng-tns-c1798067373-4 ng-star-inserted"
                  >
                    <div
                      _ngcontent-ng-c1798067373=""
                      className="market-nation-detail ng-tns-c1798067373-4"
                    >
                      <span
                        _ngcontent-ng-c1798067373=""
                        className="market-nation-name ng-tns-c1798067373-4"
                      >
                        RSA
                      </span>
                      <div
                        _ngcontent-ng-c1798067373=""
                        className="market-nation-book ng-tns-c1798067373-4"
                      >
                        <span
                          _ngcontent-ng-c1798067373=""
                          id="book_1"
                          className="float-left book ng-tns-c1798067373-4"
                          style={{ color: "black" }}
                        >
                          <span
                            _ngcontent-ng-c1798067373=""
                            className="ng-tns-c1798067373-4"
                          >
                            {" "}
                            0{" "}
                          </span>
                        </span>
                      </div>
                    </div>
                    <div
                      _ngcontent-ng-c1798067373=""
                      data-title="SUSPENDED"
                      className="Market-odd-row-box ng-tns-c1798067373-4 suspended-table"
                    >
                      <div
                        _ngcontent-ng-c1798067373=""
                        type="button"
                        data-bs-toggle="modal"
                        className="market-odd-box back2 ng-tns-c1798067373-4"
                      >
                        <span
                          _ngcontent-ng-c1798067373=""
                          className="market-odd ng-tns-c1798067373-4"
                        />
                        <span
                          _ngcontent-ng-c1798067373=""
                          className="market-volume ng-tns-c1798067373-4"
                        />
                      </div>
                      <div
                        _ngcontent-ng-c1798067373=""
                        type="button"
                        data-bs-toggle="modal"
                        className="market-odd-box back1 ng-tns-c1798067373-4"
                      >
                        <span
                          _ngcontent-ng-c1798067373=""
                          className="market-odd ng-tns-c1798067373-4"
                        />
                        <span
                          _ngcontent-ng-c1798067373=""
                          className="market-volume ng-tns-c1798067373-4"
                        />
                      </div>
                      <div
                        _ngcontent-ng-c1798067373=""
                        className="back market-odd-box ng-tns-c1798067373-4"
                      >
                        <span
                          _ngcontent-ng-c1798067373=""
                          className="market-odd ng-tns-c1798067373-4"
                        >
                          {" "}
                          0
                        </span>
                        <span
                          _ngcontent-ng-c1798067373=""
                          className="market-volume ng-tns-c1798067373-4"
                        >
                          {" "}
                          0{" "}
                        </span>
                      </div>
                      <div
                        _ngcontent-ng-c1798067373=""
                        className="lay market-odd-box ng-tns-c1798067373-4"
                      >
                        <span
                          _ngcontent-ng-c1798067373=""
                          className="market-odd ng-tns-c1798067373-4"
                        >
                          {" "}
                          0
                        </span>
                        <span
                          _ngcontent-ng-c1798067373=""
                          className="market-volume ng-tns-c1798067373-4"
                        >
                          {" "}
                          0{" "}
                        </span>
                      </div>
                      <div
                        _ngcontent-ng-c1798067373=""
                        type="button"
                        data-bs-toggle="modal"
                        className="market-odd-box lay1 ng-tns-c1798067373-4"
                      >
                        <span
                          _ngcontent-ng-c1798067373=""
                          className="market-odd ng-tns-c1798067373-4"
                        />
                        <span
                          _ngcontent-ng-c1798067373=""
                          className="market-volume ng-tns-c1798067373-4"
                        />
                      </div>
                      <div
                        _ngcontent-ng-c1798067373=""
                        type="button"
                        data-bs-toggle="modal"
                        className="market-odd-box lay2 ng-tns-c1798067373-4"
                      >
                        <span
                          _ngcontent-ng-c1798067373=""
                          className="market-odd ng-tns-c1798067373-4"
                        />
                        <span
                          _ngcontent-ng-c1798067373=""
                          className="market-volume ng-tns-c1798067373-4"
                        />
                      </div>
                    </div>
                  </div>
                  {/**/}
                </div>
              </div>
              <div
                _ngcontent-ng-c1798067373=""
                className="game-market market-6 fancy-monile-ddesign ng-tns-c1798067373-4"
              >
                <div
                  _ngcontent-ng-c1798067373=""
                  className="row row10 ng-tns-c1798067373-4"
                >
                  {/**/}
                </div>
              </div>
            </div>
          </div>
        </div>
      </CasinoPage>
      <div
        _ngcontent-ng-c1798067373=""
        className="sidebar right-sidebar casino-right-sidebar ng-tns-c1798067373-4"
      >
        <div
          _ngcontent-ng-c1798067373=""
          className="sidebar-box place-bet-container d-xl-block d-none ng-tns-c1798067373-4"
        >
          {/**/}
          <div
            _ngcontent-ng-c1798067373=""
            className="sidebar-title ng-tns-c1798067373-4"
          >
            <h4 _ngcontent-ng-c1798067373="" className="ng-tns-c1798067373-4">
              Place Bet
            </h4>
          </div>
          {/**/}
        </div>
        <div
          _ngcontent-ng-c1798067373=""
          className="sidebar-box my-bet-container d-xl-block d-none ng-tns-c1798067373-4"
        >
          <div
            _ngcontent-ng-c1798067373=""
            className="sidebar-title ng-tns-c1798067373-4"
          >
            <h4 _ngcontent-ng-c1798067373="" className="ng-tns-c1798067373-4">
              My Bet
            </h4>
            <a
              _ngcontent-ng-c1798067373=""
              className="sideviewmore ng-tns-c1798067373-4"
            >
              View more
            </a>
          </div>
          <div
            _ngcontent-ng-c1798067373=""
            className="my-bets ng-tns-c1798067373-4"
          >
            <div
              _ngcontent-ng-c1798067373=""
              className="table-responsive w-100 ng-tns-c1798067373-4"
            >
              <table
                _ngcontent-ng-c1798067373=""
                className="table ng-tns-c1798067373-4"
              >
                <thead
                  _ngcontent-ng-c1798067373=""
                  className="ng-tns-c1798067373-4"
                >
                  <tr
                    _ngcontent-ng-c1798067373=""
                    className="ng-tns-c1798067373-4"
                  >
                    <th
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-4"
                    >
                      Matched Bet
                    </th>
                    <th
                      _ngcontent-ng-c1798067373=""
                      className="text-end ng-tns-c1798067373-4"
                    >
                      Odds
                    </th>
                    <th
                      _ngcontent-ng-c1798067373=""
                      className="text-end ng-tns-c1798067373-4"
                    >
                      Stake
                    </th>
                  </tr>
                </thead>
                {/**/}
              </table>
            </div>
          </div>
        </div>
        <div
          _ngcontent-ng-c1798067373=""
          className="sidebar-box sidebar-box-rules my-bet-container ng-tns-c1798067373-4"
        >
          <app-casino-game-rules
            _ngcontent-ng-c1798067373=""
            className="ng-tns-c1798067373-4"
            _nghost-ng-c1761710207=""
          >
            {/**/}
            {/**/}
            <div
              _ngcontent-ng-c1761710207=""
              className="sidebar-title super-over-title ng-star-inserted"
            >
              <h4 _ngcontent-ng-c1761710207="">
                {" "}
                ENG VS RSA <br _ngcontent-ng-c1761710207="" /> INNING'S CARD
                RULES
              </h4>
            </div>
            <div
              _ngcontent-ng-c1761710207=""
              className="my-bets super-over-my-bets ng-star-inserted"
            >
              <div
                _ngcontent-ng-c1761710207=""
                className="table-responsive super-over-rule"
              >
                <table _ngcontent-ng-c1761710207="" className="table">
                  <thead _ngcontent-ng-c1761710207="">
                    <tr _ngcontent-ng-c1761710207="">
                      <th _ngcontent-ng-c1761710207="">Cards</th>
                      <th _ngcontent-ng-c1761710207="" className="text-center">
                        Count
                      </th>
                      <th _ngcontent-ng-c1761710207="" className="text-end">
                        Value
                      </th>
                    </tr>
                  </thead>
                  <tbody _ngcontent-ng-c1761710207="">
                    <tr _ngcontent-ng-c1761710207="">
                      <td _ngcontent-ng-c1761710207="">
                        <img
                          _ngcontent-ng-c1761710207=""
                          src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/cricketv/A.webp"
                        />
                        <span _ngcontent-ng-c1761710207="" className="ms-2">
                          X
                        </span>
                      </td>
                      <td _ngcontent-ng-c1761710207="" className="text-center">
                        5
                      </td>
                      <td _ngcontent-ng-c1761710207="" className="text-end">
                        <img
                          _ngcontent-ng-c1761710207=""
                          src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/superover/balls/ball1.webp"
                        />
                      </td>
                    </tr>
                    <tr _ngcontent-ng-c1761710207="">
                      <td _ngcontent-ng-c1761710207="">
                        <img
                          _ngcontent-ng-c1761710207=""
                          src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/cricketv/2.webp"
                        />
                        <span _ngcontent-ng-c1761710207="" className="ms-2">
                          X
                        </span>
                      </td>
                      <td _ngcontent-ng-c1761710207="" className="text-center">
                        5
                      </td>
                      <td _ngcontent-ng-c1761710207="" className="text-end">
                        <img
                          _ngcontent-ng-c1761710207=""
                          src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/superover/balls/ball2.webp"
                        />
                      </td>
                    </tr>
                    <tr _ngcontent-ng-c1761710207="">
                      <td _ngcontent-ng-c1761710207="">
                        <img
                          _ngcontent-ng-c1761710207=""
                          src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/cricketv/3.webp"
                        />
                        <span _ngcontent-ng-c1761710207="" className="ms-2">
                          X
                        </span>
                      </td>
                      <td _ngcontent-ng-c1761710207="" className="text-center">
                        5
                      </td>
                      <td _ngcontent-ng-c1761710207="" className="text-end">
                        <img
                          _ngcontent-ng-c1761710207=""
                          src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/superover/balls/ball3.webp"
                        />
                      </td>
                    </tr>
                    <tr _ngcontent-ng-c1761710207="">
                      <td _ngcontent-ng-c1761710207="">
                        <img
                          _ngcontent-ng-c1761710207=""
                          src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/cricketv/4.webp"
                        />
                        <span _ngcontent-ng-c1761710207="" className="ms-2">
                          X
                        </span>
                      </td>
                      <td _ngcontent-ng-c1761710207="" className="text-center">
                        5
                      </td>
                      <td _ngcontent-ng-c1761710207="" className="text-end">
                        <img
                          _ngcontent-ng-c1761710207=""
                          src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/superover/balls/ball4.webp"
                        />
                      </td>
                    </tr>
                    <tr _ngcontent-ng-c1761710207="">
                      <td _ngcontent-ng-c1761710207="">
                        <img
                          _ngcontent-ng-c1761710207=""
                          src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/cricketv/6.webp"
                        />
                        <span _ngcontent-ng-c1761710207="" className="ms-2">
                          X
                        </span>
                      </td>
                      <td _ngcontent-ng-c1761710207="" className="text-center">
                        5
                      </td>
                      <td _ngcontent-ng-c1761710207="" className="text-end">
                        <img
                          _ngcontent-ng-c1761710207=""
                          src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/superover/balls/ball6.webp"
                        />
                      </td>
                    </tr>
                    <tr _ngcontent-ng-c1761710207="">
                      <td _ngcontent-ng-c1761710207="">
                        <img
                          _ngcontent-ng-c1761710207=""
                          src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/cricketv/10.webp"
                        />
                        <span _ngcontent-ng-c1761710207="" className="ms-2">
                          X
                        </span>
                      </td>
                      <td _ngcontent-ng-c1761710207="" className="text-center">
                        5
                      </td>
                      <td _ngcontent-ng-c1761710207="" className="text-end">
                        <img
                          _ngcontent-ng-c1761710207=""
                          src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/superover/balls/ball0.webp"
                        />
                      </td>
                    </tr>
                    <tr _ngcontent-ng-c1761710207="">
                      <td _ngcontent-ng-c1761710207="">
                        <img
                          _ngcontent-ng-c1761710207=""
                          src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/cricketv/K.webp"
                        />
                        <span _ngcontent-ng-c1761710207="" className="ms-2">
                          X
                        </span>
                      </td>
                      <td _ngcontent-ng-c1761710207="" className="text-center">
                        5
                      </td>
                      <td _ngcontent-ng-c1761710207="" className="text-end">
                        <span _ngcontent-ng-c1761710207="">Wicket</span>
                        <img
                          _ngcontent-ng-c1761710207=""
                          src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/superover/balls/wicket.webp"
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            {/**/}
            {/**/}
            {/**/}
          </app-casino-game-rules>
        </div>
      </div>
    </>
  );
}
