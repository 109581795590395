import React, { useEffect } from "react";
import { CASINO_VIDEO_URL, DEFAULT_BET_AMOUNT } from "../../../utils/constants";
import "../index.scoped.css";
import { CASINO_VIDEO, getProfit } from "../CasinoGeneric";
import { useDispatch, useSelector } from "react-redux";
import { getButtonValuesAPI } from "../../../service/AuthService";
import { casinoPL, cricketMatchOddsPL } from "../../game/profitLoss";
import FlipCountdown from "@rumess/react-flip-countdown";
import {
  casinoBetAPI,
  getCasinoBetsAPI,
  getCasinoLastTenResultAPI,
} from "../../../service/casino";
import toast from "react-hot-toast";
import { useQuery } from "react-query";
import TeenPattiRules from "./TeenPattiRules";
export default function CasinoPage({
  gameName,
  GAME_ID,
  children,
  casino,
  VIDEO_ID,
  shortName,
  rule = false,
}) {
  const modalRef = React.useRef();
  const selectedEventForBet = useSelector(
    (state) => state.gameData.selectedEventForBet
  );
  const [selectedTab, setSelectedTab] = React.useState(0);
  const [buttons, setButtons] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const dispatch = useDispatch();
  const ruleMap = {
    tp20: <TeenPattiRules />,
  };
  const handleAmountChange = (amount = DEFAULT_BET_AMOUNT) => {
    let calculation;

    calculation = casinoPL(
      selectedEventForBet?.selectedOdd,
      amount,
      selectedEventForBet?.type
    );
    dispatch({
      type: "gameData/setSelectedEventForBet",
      payload: {
        ...selectedEventForBet,
        amount,
        profit: calculation.profit,
        loss: calculation.loss,
      },
    });
  };
  const getButtonValues = async () => {
    const { response, code } = await getButtonValuesAPI();
    if (code == 200) {
      const convertedData = {
        casino: [],
        sport: [],
      };
      for (const key in response) {
        if (key.startsWith("buttonValueCasino")) {
          const index = key.replace("buttonValueCasino", "");
          const label = response["buttonNameCasino" + index];
          const value = response[key];
          convertedData.casino.push({
            label,
            value,
          });
        } else if (key.startsWith("buttonValue")) {
          const index = key.replace("buttonValue", "");
          const label = response["buttonName" + index];
          const value = response[key];
          convertedData.sport.push({
            label,
            value,
          });
        }
      }
      localStorage.setItem("buttonValues", JSON.stringify(convertedData));
      setButtons(convertedData);
    }
  };
  let game = "casino";
  const betPlace = async () => {
    let delay = 1500;
    setIsLoading(true);
    setTimeout(async () => {
      let selectedAmount_ = selectedEventForBet?.amount;
      if (selectedEventForBet.selectedOdd <= 0) {
        toast.error("Please select a Valid Odd");
        setIsLoading(false);
        return;
      }
      let payload = {
        amount: selectedAmount_,
        marketId: selectedEventForBet?.marketId,
        profit: getProfit(selectedAmount_, game, selectedEventForBet)?.profit,
        loss: getProfit(selectedAmount_, game, selectedEventForBet)?.loss,
        runnerName: selectedEventForBet?.runnerName,
        selectionId: selectedEventForBet?.selectedId,
        casinoGames: GAME_ID,
        back: selectedEventForBet?.selectedOdd,
        type: selectedEventForBet?.type,
      };
      if (selectedEventForBet?.type === "lay") {
        delete payload.back;
        payload.lay = selectedEventForBet?.selectedOdd;
      }
      if (
        GAME_ID === "CRICKET_V3" ||
        GAME_ID === "SUPEROVER" ||
        GAME_ID === "RACE20"
      ) {
        let calculation = cricketMatchOddsPL(
          selectedEventForBet?.selectedOdd,
          selectedAmount_,
          selectedEventForBet?.type
        );
        payload.profit = parseFloat(calculation?.profit).toFixed(2);

        payload.loss = parseFloat(calculation?.loss).toFixed(2);
      }
      let { data, response, code } = await casinoBetAPI(payload);
      if (code == 200) {
        toast.success("Bet Placed");
      } else if (code == 500) {
        toast.error(response);
      } else if (code == "ERR_BAD_RESPONSE") {
        toast.error("Something Went Wrong");
      } else {
        toast.error("Something Went Wrong");
      }

      dispatch({
        type: "gameData/setSelectedEventForBet",
        payload: null,
      });
    }, delay);
    setIsLoading(false);
  };
  const casinoBets = useQuery("casinoBets", () => getCasinoBetsAPI(GAME_ID), {
    refetchInterval: 2500,
  });
  useEffect(() => {
    //close modal on outside click
    document.addEventListener("mousedown", handleOutSideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutSideClick);
    };
  }, []);
  const handleOutSideClick = (e) => {
    if (modalRef.current && !modalRef.current.contains(e.target)) {
      dispatch({
        type: "gameData/setSelectedEventForBet",
        payload: null,
      });
    }
  };

  const lastTenResultData = useQuery(
    "getResult",
    () => getCasinoLastTenResultAPI(GAME_ID),
    {
      refetchInterval: 1500,
    }
  );
  const isMobile = window.innerWidth < 1024;
  React.useEffect(() => {
    getButtonValues();
  }, []);
  const splitDigit = (isFirst) => {
    if (!casino || !casino?.response || !casino?.response?.autoTime)
      return "00";
    let digits = casino?.response?.autoTime?.split("");
    if (digits.length === 1) {
      digits.unshift("0");
    }
    return isFirst ? digits[1] : digits[0];
  };
  const casinoCardMap = (_) => {
    let cardMap = {
      baccarat: "",
      "ab ab3": casino?.response?.cards &&
        casino?.response?.cards.length > 0 &&
        casino?.response?.cards[0].length > 0 &&
        casino?.response?.cards[1].length > 0 && (
          <div
            _ngcontent-ng-c1798067373=""
            className="casino-video-cards ng-tns-c1798067373-3 ng-star-inserted"
          >
            <div
              _ngcontent-ng-c1798067373=""
              className="ab-cards-container ng-tns-c1798067373-3 ng-star-inserted"
            >
              <div
                _ngcontent-ng-c1798067373=""
                className="row row5 align-items-center ng-tns-c1798067373-3"
              >
                <div
                  _ngcontent-ng-c1798067373=""
                  className="col-12 ng-tns-c1798067373-3"
                >
                  <div
                    _ngcontent-ng-c1798067373=""
                    className="row row5 mb-1 ng-tns-c1798067373-3 ng-star-inserted"
                  >
                    <div
                      _ngcontent-ng-c1798067373=""
                      className="col-12 ng-tns-c1798067373-3"
                    >
                      Andar
                    </div>
                    <div
                      _ngcontent-ng-c1798067373=""
                      className="col-lg-12 col-12 ng-tns-c1798067373-3"
                    >
                      <div
                        _ngcontent-ng-c1798067373=""
                        className="casino-result-cards col-lg-12 ng-tns-c1798067373-3"
                      >
                        {/**/}
                        {/**/}
                        <button
                          _ngcontent-ng-c1798067373=""
                          type="button"
                          className="ab-pervious d-lg-none d-block ng-tns-c1798067373-3 ng-star-inserted"
                        >
                          <img
                            _ngcontent-ng-c1798067373=""
                            className="ng-tns-c1798067373-3"
                            src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/left-arrow-whi.webp"
                          />
                        </button>
                        {/**/}
                        <button
                          _ngcontent-ng-c1798067373=""
                          type="button"
                          className="ab-next d-lg-none d-block ng-tns-c1798067373-3 ng-star-inserted"
                        >
                          <img
                            _ngcontent-ng-c1798067373=""
                            className="ng-tns-c1798067373-3"
                            src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/right_arrow_white.webp"
                          />
                        </button>
                        {/**/}
                        <div
                          _ngcontent-ng-c1798067373=""
                          className="casino-result-cardimg andharbahar1 ng-tns-c1798067373-3"
                        >
                          <div
                            _ngcontent-ng-c1798067373=""
                            className="andharbahar1_slide ng-tns-c1798067373-3"
                          >
                            {casino?.response?.cards &&
                              casino?.response?.cards[0]?.map((item) => (
                                <div
                                  _ngcontent-ng-c1798067373=""
                                  className="slide ng-tns-c1798067373-3 ng-star-inserted"
                                >
                                  <img
                                    _ngcontent-ng-c1798067373=""
                                    className="ng-tns-c1798067373-3"
                                    src={`https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/${item}.webp`}
                                  />
                                </div>
                              ))}

                            {/**/}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/**/}
                  <div
                    _ngcontent-ng-c1798067373=""
                    className="row row5 ng-tns-c1798067373-3 ng-star-inserted"
                  >
                    <div
                      _ngcontent-ng-c1798067373=""
                      className="col-12 ng-tns-c1798067373-3"
                    >
                      Bahar
                    </div>
                    <div
                      _ngcontent-ng-c1798067373=""
                      className="col-lg-12 col-12 ng-tns-c1798067373-3"
                    >
                      <div
                        _ngcontent-ng-c1798067373=""
                        className="casino-result-cards col-lg-12 ng-tns-c1798067373-3"
                      >
                        {/**/}
                        {/**/}
                        <button
                          _ngcontent-ng-c1798067373=""
                          type="button"
                          className="ab-pervious d-lg-none d-block ng-tns-c1798067373-3 ng-star-inserted"
                        >
                          <img
                            _ngcontent-ng-c1798067373=""
                            className="ng-tns-c1798067373-3"
                            src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/left-arrow-whi.webp"
                          />
                        </button>
                        {/**/}
                        <button
                          _ngcontent-ng-c1798067373=""
                          type="button"
                          className="ab-next d-lg-none d-block ng-tns-c1798067373-3 ng-star-inserted"
                        >
                          <img
                            _ngcontent-ng-c1798067373=""
                            className="ng-tns-c1798067373-3"
                            src="https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/right_arrow_white.webp"
                          />
                        </button>
                        {/**/}
                        <div
                          _ngcontent-ng-c1798067373=""
                          className="casino-result-cardimg andharbahar1 ng-tns-c1798067373-3"
                        >
                          <div
                            _ngcontent-ng-c1798067373=""
                            className="andharbahar1_slide ng-tns-c1798067373-3"
                          >
                            {casino?.response?.cards &&
                              casino?.response?.cards[1]?.map((item) => (
                                <div
                                  _ngcontent-ng-c1798067373=""
                                  className="slide ng-tns-c1798067373-3 ng-star-inserted"
                                >
                                  <img
                                    _ngcontent-ng-c1798067373=""
                                    className="ng-tns-c1798067373-3"
                                    src={`https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/${item}.webp`}
                                  />
                                </div>
                              ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/**/}
                </div>
              </div>
            </div>
            {/**/}
          </div>
        ),
      teenpatti20: casino?.response?.cards &&
        casino?.response?.cards.length > 0 && (
          <div
            _ngcontent-ng-c1798067373=""
            className="casino-video-cards ng-tns-c1798067373-12 ng-star-inserted"
          >
            <div
              _ngcontent-ng-c1798067373=""
              className="ng-tns-c1798067373-12 ng-star-inserted"
            >
              <h5
                _ngcontent-ng-c1798067373=""
                className="ng-tns-c1798067373-12 ng-star-inserted"
              >
                PLAYER A
              </h5>
              <div
                _ngcontent-ng-c1798067373=""
                className="flip-card-container ng-tns-c1798067373-12 ng-star-inserted"
              >
                {casino?.response?.cards &&
                  casino?.response?.cards[0]?.map((item) => (
                    <div
                      _ngcontent-ng-c1798067373=""
                      className="me-lg-2 me-1 ng-tns-c1798067373-12 ng-star-inserted"
                    >
                      <img
                        _ngcontent-ng-c1798067373=""
                        className="ng-tns-c1798067373-12"
                        src={`https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/${item}.webp`}
                      />
                    </div>
                  ))}
              </div>
            </div>
            <div
              _ngcontent-ng-c1798067373=""
              className="ng-tns-c1798067373-12 ng-star-inserted"
            >
              <h5
                _ngcontent-ng-c1798067373=""
                className="ng-tns-c1798067373-12 ng-star-inserted"
              >
                PLAYER B
              </h5>
              <div
                _ngcontent-ng-c1798067373=""
                className="flip-card-container ng-tns-c1798067373-12 ng-star-inserted"
              >
                {casino?.response?.cards &&
                  casino?.response?.cards[1]?.map((item) => (
                    <div
                      _ngcontent-ng-c1798067373=""
                      className="me-lg-2 me-1 ng-tns-c1798067373-12 ng-star-inserted"
                    >
                      <img
                        _ngcontent-ng-c1798067373=""
                        className="ng-tns-c1798067373-12"
                        src={`https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/${item}.webp`}
                      />
                    </div>
                  ))}
              </div>
            </div>
          </div>
        ),
      "cards32a poker-20": (
        <div
          _ngcontent-ng-c1798067373=""
          className="casino-video-cards ng-tns-c1798067373-13 ng-star-inserted"
        >
          <div
            _ngcontent-ng-c1798067373=""
            className="mb-lg-2 mb-1 d-flex justify-content-between align-items-center ng-tns-c1798067373-13"
          >
            <div
              _ngcontent-ng-c1798067373=""
              className="ng-tns-c1798067373-13"
            >
              <h5
                _ngcontent-ng-c1798067373=""
                className="ng-tns-c1798067373-13"
              >
                PLAYER A
              </h5>
              <div
                _ngcontent-ng-c1798067373=""
                className="flip-card-container ng-tns-c1798067373-13"
              >
                {casino?.response?.cards &&
                  casino?.response?.cards[0]?.map((item) => (
                    <div
                      _ngcontent-ng-c1798067373=""
                      className="me-lg-2 me-1 ng-tns-c1798067373-13 ng-star-inserted"
                    >
                      <img
                        _ngcontent-ng-c1798067373=""
                        className="ng-tns-c1798067373-13"
                        src={`https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/${item}.webp`}
                      />
                    </div>
                  ))}

                {/**/}
              </div>
            </div>
            <div
              _ngcontent-ng-c1798067373=""
              className="ng-tns-c1798067373-13"
            >
              <h5
                _ngcontent-ng-c1798067373=""
                className="text-end ng-tns-c1798067373-13"
              >
                PLAYER B
              </h5>
              <div
                _ngcontent-ng-c1798067373=""
                className="flip-card-container ng-tns-c1798067373-13"
              >
                {casino?.response?.cards &&
                  casino?.response?.cards[1]?.map((item) => (
                    <div
                      _ngcontent-ng-c1798067373=""
                      className="ms-lg-2 ms-1 ng-tns-c1798067373-13 ng-star-inserted"
                    >
                      <img
                        _ngcontent-ng-c1798067373=""
                        className="ng-tns-c1798067373-13"
                        src={`https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/${item}.webp`}
                      />
                    </div>
                  ))}

                {/**/}
              </div>
            </div>
          </div>
          <h5
            _ngcontent-ng-c1798067373=""
            className="ng-tns-c1798067373-13"
          >
            BOARD
          </h5>
          <div
            _ngcontent-ng-c1798067373=""
            className="flip-card-container ng-tns-c1798067373-13"
          >
            {casino?.response?.cards &&
              casino?.response?.cards[2]?.map((item) => (
                <div
                  _ngcontent-ng-c1798067373=""
                  className="me-lg-2 me-1 mb-1 ng-tns-c1798067373-13 ng-star-inserted"
                >
                  <img
                    _ngcontent-ng-c1798067373=""
                    className="ng-tns-c1798067373-13"
                    src={`https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/${item}.webp`}
                  />
                </div>
              ))}
          </div>
        </div>
      ),
      teenpattitest: (
        <div
          _ngcontent-ng-c1798067373=""
          className="casino-video-cards ng-tns-c1798067373-14 ng-star-inserted"
        >
          <div
            _ngcontent-ng-c1798067373=""
            className="ng-tns-c1798067373-14 ng-star-inserted"
          >
            <h5
              _ngcontent-ng-c1798067373=""
              className="ng-tns-c1798067373-14 ng-star-inserted"
            >
              {" "}
              TIGER
            </h5>
            <div
              _ngcontent-ng-c1798067373=""
              className="flip-card-container ng-tns-c1798067373-14 ng-star-inserted"
            >
              {casino?.response?.cards &&
                casino?.response?.cards[0]?.map((item) => (
                  <div
                    _ngcontent-ng-c1798067373=""
                    className="me-lg-2 me-1 ng-tns-c1798067373-14 ng-star-inserted"
                  >
                    <img
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-14"
                      src={`https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/${item}.webp`}
                    />
                  </div>
                ))}
            </div>
            {/**/}
            {/**/}
            {/**/}
            {/**/}
          </div>
          <div
            _ngcontent-ng-c1798067373=""
            className="ng-tns-c1798067373-14 ng-star-inserted"
          >
            {/**/}
            <h5
              _ngcontent-ng-c1798067373=""
              className="ng-tns-c1798067373-14 ng-star-inserted"
            >
              LION
            </h5>
            <div
              _ngcontent-ng-c1798067373=""
              className="flip-card-container ng-tns-c1798067373-14 ng-star-inserted"
            >
              {casino?.response?.cards &&
                casino?.response?.cards[1]?.map((item) => (
                  <div
                    _ngcontent-ng-c1798067373=""
                    className="me-lg-2 me-1 ng-tns-c1798067373-14 ng-star-inserted"
                  >
                    <img
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-14"
                      src={`https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/${item}.webp`}
                    />
                  </div>
                ))}
            </div>
            {/**/}
            {/**/}
            {/**/}
          </div>
          <div
            _ngcontent-ng-c1798067373=""
            className="ng-tns-c1798067373-14 ng-star-inserted"
          >
            {/**/}
            {/**/}
            <h5
              _ngcontent-ng-c1798067373=""
              className="ng-tns-c1798067373-14 ng-star-inserted"
            >
              {" "}
              DRAGON
            </h5>
            <div
              _ngcontent-ng-c1798067373=""
              className="flip-card-container ng-tns-c1798067373-14 ng-star-inserted"
            >
              {casino?.response?.cards &&
                casino?.response?.cards[2]?.map((item) => (
                  <div
                    _ngcontent-ng-c1798067373=""
                    className="me-lg-2 me-1 ng-tns-c1798067373-14 ng-star-inserted"
                  >
                    <img
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-14"
                      src={`https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/${item}.webp`}
                    />
                  </div>
                ))}
            </div>
            {/**/}
            {/**/}
          </div>
          {/**/}
        </div>
      ),
      race20: (
        <div className="casino-video-cards">
          <div className="race-total-point">
            <div className="text-white">
              <div>Cards</div>
              <div>15</div>
            </div>
            <div className="text-white">
              <div>Points</div>
              <div>92</div>
            </div>
          </div>
          <div>
            <div className="flip-card-container">
              <div className="flip-card">
                <img src="https://versionobj.ecoassetsservice.com/v19/static/front/img/icons/spade.png" />
              </div>
              <div className="flip-card">
                <div className="flip-card-inner ">
                  <div className="flip-card-front">
                    <img src="https://versionobj.ecoassetsservice.com/v19/static/front/img/cards/ASS.jpg" />
                  </div>
                  <div className="flip-card-back">
                    <img src="https://versionobj.ecoassetsservice.com/v19/static/front/img/cards/ASS.jpg" />
                  </div>
                </div>
              </div>
              <div className="flip-card">
                <div className="flip-card-inner ">
                  <div className="flip-card-front">
                    <img src="https://versionobj.ecoassetsservice.com/v19/static/front/img/cards/JSS.jpg" />
                  </div>
                  <div className="flip-card-back">
                    <img src="https://versionobj.ecoassetsservice.com/v19/static/front/img/cards/JSS.jpg" />
                  </div>
                </div>
              </div>
              <div className="flip-card">
                <div className="flip-card-inner ">
                  <div className="flip-card-front">
                    <img src="https://versionobj.ecoassetsservice.com/v19/static/front/img/cards/10SS.jpg" />
                  </div>
                  <div className="flip-card-back">
                    <img src="https://versionobj.ecoassetsservice.com/v19/static/front/img/cards/10SS.jpg" />
                  </div>
                </div>
              </div>
              <div className="flip-card">
                <div className="flip-card-inner ">
                  <div className="flip-card-front">
                    <img src="https://versionobj.ecoassetsservice.com/v19/static/front/img/cards/4SS.jpg" />
                  </div>
                  <div className="flip-card-back">
                    <img src="https://versionobj.ecoassetsservice.com/v19/static/front/img/cards/4SS.jpg" />
                  </div>
                </div>
              </div>
              <div className="flip-card">
                <div className="flip-card-inner ">
                  <div className="flip-card-front">
                    <img src="https://versionobj.ecoassetsservice.com/v19/static/front/img/cards/KSS.jpg" />
                  </div>
                  <div className="flip-card-back">
                    <img src="https://versionobj.ecoassetsservice.com/v19/static/front/img/cards/KSS.jpg" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-1">
            <div className="flip-card-container">
              <div className="flip-card">
                <img src="https://versionobj.ecoassetsservice.com/v19/static/front/img/icons/heart.png" />
              </div>
              <div className="flip-card">
                <div className="flip-card-inner ">
                  <div className="flip-card-front">
                    <img src="https://versionobj.ecoassetsservice.com/v19/static/front/img/cards/QHH.jpg" />
                  </div>
                  <div className="flip-card-back">
                    <img src="https://versionobj.ecoassetsservice.com/v19/static/front/img/cards/QHH.jpg" />
                  </div>
                </div>
              </div>
              <div className="flip-card">
                <div className="flip-card-inner ">
                  <div className="flip-card-front">
                    <img src="https://versionobj.ecoassetsservice.com/v19/static/front/img/cards/10HH.jpg" />
                  </div>
                  <div className="flip-card-back">
                    <img src="https://versionobj.ecoassetsservice.com/v19/static/front/img/cards/10HH.jpg" />
                  </div>
                </div>
              </div>
              <div className="flip-card">
                <div className="flip-card-inner ">
                  <div className="flip-card-front">
                    <img src="https://versionobj.ecoassetsservice.com/v19/static/front/img/cards/7HH.jpg" />
                  </div>
                  <div className="flip-card-back">
                    <img src="https://versionobj.ecoassetsservice.com/v19/static/front/img/cards/7HH.jpg" />
                  </div>
                </div>
              </div>
              <div className="flip-card">
                <div className="flip-card-inner ">
                  <div className="flip-card-front">
                    <img src="https://versionobj.ecoassetsservice.com/v19/static/front/img/cards/AHH.jpg" />
                  </div>
                  <div className="flip-card-back">
                    <img src="https://versionobj.ecoassetsservice.com/v19/static/front/img/cards/AHH.jpg" />
                  </div>
                </div>
              </div>
              <div className="flip-card">
                <div className="flip-card-inner ">
                  <div className="flip-card-front">
                    <img src="https://versionobj.ecoassetsservice.com/v19/static/front/img/cards/KHH.jpg" />
                  </div>
                  <div className="flip-card-back">
                    <img src="https://versionobj.ecoassetsservice.com/v19/static/front/img/cards/KHH.jpg" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-1">
            <div className="flip-card-container">
              <div className="flip-card">
                <img src="https://versionobj.ecoassetsservice.com/v19/static/front/img/icons/club.png" />
              </div>
              <div className="flip-card">
                <div className="flip-card-inner ">
                  <div className="flip-card-front">
                    <img src="https://versionobj.ecoassetsservice.com/v19/static/front/img/cards/8CC.jpg" />
                  </div>
                  <div className="flip-card-back">
                    <img src="https://versionobj.ecoassetsservice.com/v19/static/front/img/cards/8CC.jpg" />
                  </div>
                </div>
              </div>
              <div className="flip-card">
                <div className="flip-card-inner ">
                  <div className="flip-card-front">
                    <img src="https://versionobj.ecoassetsservice.com/v19/static/front/img/cards/ACC.jpg" />
                  </div>
                  <div className="flip-card-back">
                    <img src="https://versionobj.ecoassetsservice.com/v19/static/front/img/cards/ACC.jpg" />
                  </div>
                </div>
              </div>
              <div className="flip-card">
                <div className="flip-card-inner ">
                  <div className="flip-card-front">
                    <img src="https://versionobj.ecoassetsservice.com/v19/static/front/img/cards/10CC.jpg" />
                  </div>
                  <div className="flip-card-back">
                    <img src="https://versionobj.ecoassetsservice.com/v19/static/front/img/cards/10CC.jpg" />
                  </div>
                </div>
              </div>
              <div className="flip-card">
                <div className="flip-card-inner ">
                  <div className="flip-card-front">
                    <img src="https://versionobj.ecoassetsservice.com/v19/static/front/img/cards/KCC.jpg" />
                  </div>
                  <div className="flip-card-back">
                    <img src="https://versionobj.ecoassetsservice.com/v19/static/front/img/cards/KCC.jpg" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-1">
            <div className="flip-card-container">
              <div className="flip-card">
                <img src="https://versionobj.ecoassetsservice.com/v19/static/front/img/icons/diamond.png" />
              </div>
              <div className="flip-card">
                <div className="flip-card-inner ">
                  <div className="flip-card-front">
                    <img src="https://versionobj.ecoassetsservice.com/v19/static/front/img/cards/2DD.jpg" />
                  </div>
                  <div className="flip-card-back">
                    <img src="https://versionobj.ecoassetsservice.com/v19/static/front/img/cards/2DD.jpg" />
                  </div>
                </div>
              </div>
              <div className="flip-card">
                <div className="flip-card-inner ">
                  <div className="flip-card-front">
                    <img src="https://versionobj.ecoassetsservice.com/v19/static/front/img/cards/JDD.jpg" />
                  </div>
                  <div className="flip-card-back">
                    <img src="https://versionobj.ecoassetsservice.com/v19/static/front/img/cards/JDD.jpg" />
                  </div>
                </div>
              </div>
              <div className="flip-card">
                <div className="flip-card-inner ">
                  <div className="flip-card-front">
                    <img src="https://versionobj.ecoassetsservice.com/v19/static/front/img/cards/3DD.jpg" />
                  </div>
                  <div className="flip-card-back">
                    <img src="https://versionobj.ecoassetsservice.com/v19/static/front/img/cards/3DD.jpg" />
                  </div>
                </div>
              </div>
              <div className="flip-card">
                <div className="flip-card-inner ">
                  <div className="flip-card-front">
                    <img src="https://versionobj.ecoassetsservice.com/v19/static/front/img/cards/ADD.jpg" />
                  </div>
                  <div className="flip-card-back">
                    <img src="https://versionobj.ecoassetsservice.com/v19/static/front/img/cards/ADD.jpg" />
                  </div>
                </div>
              </div>
              <div className="flip-card">
                <div className="flip-card-inner ">
                  <div className="flip-card-front">
                    <img src="https://versionobj.ecoassetsservice.com/v19/static/front/img/cards/KDD.jpg" />
                  </div>
                  <div className="flip-card-back">
                    <img src="https://versionobj.ecoassetsservice.com/v19/static/front/img/cards/KDD.jpg" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ),
      cards32a: casino?.response?.cards &&
        casino?.response?.cards.length > 0 && (
          <div
            _ngcontent-ng-c1798067373=""
            className="casino-video-cards ng-tns-c1798067373-11 ng-star-inserted"
          >
            {casino?.response?.cards &&
              casino?.response?.cards.length > 0 &&
              casino?.response?.cards[0].length > 0 && (
                <div
                  _ngcontent-ng-c1798067373=""
                  className="ng-tns-c1798067373-11 ng-star-inserted"
                >
                  <h5
                    _ngcontent-ng-c1798067373=""
                    className="ng-tns-c1798067373-11 ng-star-inserted"
                  >
                    {" "}
                    Player 8:{" "}
                    <span
                      _ngcontent-ng-c1798067373=""
                      className="text-warning ng-tns-c1798067373-11 ng-star-inserted"
                    >
                      {0}
                    </span>
                  </h5>

                  <div
                    _ngcontent-ng-c1798067373=""
                    className="flip-card-container ng-tns-c1798067373-11 ng-star-inserted"
                  >
                    <div
                      _ngcontent-ng-c1798067373=""
                      className="flip-card ng-tns-c1798067373-11"
                    >
                      <div
                        _ngcontent-ng-c1798067373=""
                        className="flip-card-inner ng-tns-c1798067373-11"
                      >
                        <div
                          _ngcontent-ng-c1798067373=""
                          className="flip-card-front ng-tns-c1798067373-11"
                        >
                          <img
                            _ngcontent-ng-c1798067373=""
                            className="ng-tns-c1798067373-11"
                            src={`https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/${
                              casino?.response?.cards[0][0]
                            }.webp`}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}

            <div
              _ngcontent-ng-c1798067373=""
              className="mt-1 ng-tns-c1798067373-11"
            >
              <h5
                style={{ marginTop: 30 }}
                _ngcontent-ng-c1798067373=""
                className="ng-tns-c1798067373-11 text-success ng-star-inserted"
              >
                {" "}
                Player 9:{" "}
                <span
                  _ngcontent-ng-c1798067373=""
                  className="text-warning ng-tns-c1798067373-11 ng-star-inserted"
                >
                  {0}
                </span>
              </h5>

              <div
                _ngcontent-ng-c1798067373=""
                className="flip-card-container ng-tns-c1798067373-11 ng-star-inserted"
              >
                <div
                  _ngcontent-ng-c1798067373=""
                  className="flip-card ng-tns-c1798067373-11"
                >
                  <div
                    _ngcontent-ng-c1798067373=""
                    className="flip-card-inner ng-tns-c1798067373-11"
                  >
                    <div
                      _ngcontent-ng-c1798067373=""
                      className="flip-card-front ng-tns-c1798067373-11"
                    >
                      {casino?.response?.cards &&
                        casino?.response?.cards.length > 1 && (
                          <img
                            _ngcontent-ng-c1798067373=""
                            className="ng-tns-c1798067373-11"
                            src={`https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/${
                              casino?.response?.cards[1][0]
                            }.webp`}
                          />
                        )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              _ngcontent-ng-c1798067373=""
              className="mt-1 ng-tns-c1798067373-11"
            >
              <h5
                style={{ marginTop: 30 }}
                _ngcontent-ng-c1798067373=""
                className="ng-tns-c1798067373-11 ng-star-inserted"
              >
                {" "}
                Player 10:{" "}
                <span
                  _ngcontent-ng-c1798067373=""
                  className="text-warning ng-tns-c1798067373-11 ng-star-inserted"
                >
                  {0}
                </span>
              </h5>

              <div
                _ngcontent-ng-c1798067373=""
                className="flip-card-container ng-tns-c1798067373-11 ng-star-inserted"
              >
                <div
                  _ngcontent-ng-c1798067373=""
                  className="flip-card ng-tns-c1798067373-11"
                >
                  <div
                    _ngcontent-ng-c1798067373=""
                    className="flip-card-inner ng-tns-c1798067373-11"
                  >
                    <div
                      _ngcontent-ng-c1798067373=""
                      className="flip-card-front ng-tns-c1798067373-11"
                    >
                      {casino?.response?.cards &&
                        casino?.response?.cards.length > 2 && (
                          <img
                            _ngcontent-ng-c1798067373=""
                            className="ng-tns-c1798067373-11"
                            src={`https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/${
                              casino?.response?.cards[2][0]
                            }.webp`}
                          />
                        )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              _ngcontent-ng-c1798067373=""
              className="mt-1 ng-tns-c1798067373-11"
            >
              <h5
                _ngcontent-ng-c1798067373=""
                style={{ marginTop: 30 }}
                className="ng-tns-c1798067373-11 ng-star-inserted"
              >
                {" "}
                Player 11:{" "}
                <span
                  _ngcontent-ng-c1798067373=""
                  className="text-warning ng-tns-c1798067373-11 ng-star-inserted"
                >
                  {0}
                </span>
              </h5>

              <div
                _ngcontent-ng-c1798067373=""
                className="flip-card-container ng-tns-c1798067373-11 ng-star-inserted"
              >
                <div
                  _ngcontent-ng-c1798067373=""
                  className="flip-card ng-tns-c1798067373-11"
                >
                  <div
                    _ngcontent-ng-c1798067373=""
                    className="flip-card-inner ng-tns-c1798067373-11"
                  >
                    <div
                      _ngcontent-ng-c1798067373=""
                      className="flip-card-front ng-tns-c1798067373-11"
                    >
                      {casino?.response &&
                        casino?.response?.cards &&
                        casino?.response?.cards.length > 3 &&
                        casino?.response?.cards[3].length > 0 && (
                          <img
                            _ngcontent-ng-c1798067373=""
                            className="ng-tns-c1798067373-11"
                            src={`https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/${
                              casino?.response?.cards[3][0]
                            }.webp`}
                          />
                        )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ),
      dt1day: casino?.response?.cards &&
        casino?.response?.cards.length > 0 && (
          <div
            _ngcontent-ng-c1798067373=""
            className="casino-video-cards ng-tns-c1798067373-0 ng-star-inserted"
          >
            <div
              _ngcontent-ng-c1798067373=""
              className="flip-card-container ng-tns-c1798067373-0"
            >
              {casino?.response?.cards &&
                casino?.response?.cards[0]?.map((item) => (
                  <div
                    _ngcontent-ng-c1798067373=""
                    className="me-lg-2 me-1 ng-tns-c1798067373-0 ng-star-inserted"
                  >
                    <img
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-0"
                      src={`https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/${item}.webp`}
                    />
                  </div>
                ))}
              {casino?.response?.cards &&
                casino?.response?.cards[1]?.map((item) => (
                  <div
                    _ngcontent-ng-c1798067373=""
                    className="me-lg-2 me-1 ng-tns-c1798067373-0 ng-star-inserted"
                  >
                    <img
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-0"
                      src={`https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/${item}.webp`}
                    />
                  </div>
                ))}
            </div>
          </div>
        ),
      worli: casino?.response?.cards && casino?.response?.cards.length > 0 && (
        <div
          _ngcontent-ng-c1798067373=""
          className="casino-video-cards ng-tns-c1798067373-0 ng-star-inserted"
        >
          <div
            _ngcontent-ng-c1798067373=""
            className="flip-card-container ng-tns-c1798067373-0"
          >
            {casino?.response?.cards &&
              casino?.response?.cards[0]?.map((item) => (
                <div
                  _ngcontent-ng-c1798067373=""
                  className="me-lg-2 me-1 ng-tns-c1798067373-0 ng-star-inserted"
                >
                  <img
                    _ngcontent-ng-c1798067373=""
                    className="ng-tns-c1798067373-0"
                    src={`https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/${item}.webp`}
                  />
                </div>
              ))}
            {casino?.response?.cards &&
              casino?.response?.cards[1]?.map((item) => (
                <div
                  _ngcontent-ng-c1798067373=""
                  className="me-lg-2 me-1 ng-tns-c1798067373-0 ng-star-inserted"
                >
                  <img
                    _ngcontent-ng-c1798067373=""
                    className="ng-tns-c1798067373-0"
                    src={`https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/${item}.webp`}
                  />
                </div>
              ))}
            {casino?.response?.cards &&
              casino?.response?.cards[2]?.map((item) => (
                <div
                  _ngcontent-ng-c1798067373=""
                  className="me-lg-2 me-1 ng-tns-c1798067373-0 ng-star-inserted"
                >
                  <img
                    _ngcontent-ng-c1798067373=""
                    className="ng-tns-c1798067373-0"
                    src={`https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/${item}.webp`}
                  />
                </div>
              ))}
          </div>
        </div>
      ),

      ab2: casino?.response?.cards && casino?.response?.cards.length > 0 && (
        <div
          _ngcontent-ng-c1798067373=""
          className="casino-video-cards ng-tns-c1798067373-0 ng-star-inserted"
        >
          <div
            _ngcontent-ng-c1798067373=""
            className="ab-cards-container ng-tns-c1798067373-0"
          >
            <div
              _ngcontent-ng-c1798067373=""
              className="row row5 align-items-center ng-tns-c1798067373-0"
            >
              <div
                _ngcontent-ng-c1798067373=""
                className="col-1 ng-tns-c1798067373-0"
              >
                <div
                  _ngcontent-ng-c1798067373=""
                  className="row row5 ng-tns-c1798067373-0"
                >
                  <div
                    _ngcontent-ng-c1798067373=""
                    className="col-12 ng-tns-c1798067373-0"
                  >
                    <b
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-0"
                    >
                      A
                    </b>
                  </div>
                </div>
                <div
                  _ngcontent-ng-c1798067373=""
                  className="row row5 ng-tns-c1798067373-0"
                >
                  <div
                    _ngcontent-ng-c1798067373=""
                    className="col-12 ng-tns-c1798067373-0"
                  >
                    <b
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-0"
                    >
                      B
                    </b>
                  </div>
                </div>
              </div>
              <div
                _ngcontent-ng-c1798067373=""
                className="col-2 ng-tns-c1798067373-0"
              >
                <div
                  _ngcontent-ng-c1798067373=""
                  className="flip-card ng-tns-c1798067373-0"
                >
                  <div
                    _ngcontent-ng-c1798067373=""
                    className="flip-card-inner ng-tns-c1798067373-0"
                  >
                    <div
                      _ngcontent-ng-c1798067373=""
                      className="flip-card-front ng-tns-c1798067373-0"
                    >
                      <img
                        _ngcontent-ng-c1798067373=""
                        className="ng-tns-c1798067373-0"
                        src={`https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/${
                          casino?.response?.cards[0][0]
                        }.webp`}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div
                _ngcontent-ng-c1798067373=""
                className="col-9 ng-tns-c1798067373-0 ng-star-inserted"
              >
                <div
                  _ngcontent-ng-c1798067373=""
                  className="row row5 mb-1 ng-tns-c1798067373-0"
                >
                  <div
                    _ngcontent-ng-c1798067373=""
                    className="col-lg-2 col-3 ng-tns-c1798067373-0"
                  >
                    <div
                      _ngcontent-ng-c1798067373=""
                      className="flip-card ng-tns-c1798067373-0"
                    >
                      <div
                        _ngcontent-ng-c1798067373=""
                        className="flip-card-front ng-tns-c1798067373-0"
                      >
                        {casino?.response?.cards.length > 0 &&
                          casino?.response?.cards[1]?.length > 0 && (
                            <img
                              _ngcontent-ng-c1798067373=""
                              onerror="this.src='assets/images/cards/1.webp'"
                              className="ng-tns-c1798067373-0"
                              src={`https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/${
                                casino?.response?.cards[1][1]
                              }.webp`}
                            />
                          )}
                      </div>
                    </div>
                  </div>
                  <div
                    _ngcontent-ng-c1798067373=""
                    className="col-lg-10 col-9 ng-tns-c1798067373-0"
                  >
                    <div
                      _ngcontent-ng-c1798067373=""
                      className="casino-result-cards col-lg-12 ng-tns-c1798067373-0"
                    >
                      <div
                        _ngcontent-ng-c1798067373=""
                        className="casino-result-cardimg ng-tns-c1798067373-0"
                      >
                        <div
                          _ngcontent-ng-c1798067373=""
                          className="andar-bhar2-cards-slider ng-tns-c1798067373-0"
                        >
                          {/**/}
                          {/**/}
                          {/**/}
                          {/**/}
                          <div
                            _ngcontent-ng-c1798067373=""
                            className="draggable ng-tns-c1798067373-0"
                          >
                            {casino?.response?.cards[1]?.map(
                              (item) =>
                                item != "1" && (
                                  <img
                                    _ngcontent-ng-c1798067373=""
                                    onerror="this.src='assets/images/cards/1.webp'"
                                    className="ng-tns-c1798067373-0"
                                    src={`https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/${item}.webp`}
                                  />
                                )
                            )}
                            {/**/}
                            {/**/}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  _ngcontent-ng-c1798067373=""
                  className="row row5 mb-1 ng-tns-c1798067373-0"
                >
                  <div
                    _ngcontent-ng-c1798067373=""
                    className="col-lg-2 col-3 ng-tns-c1798067373-0"
                  >
                    <div
                      _ngcontent-ng-c1798067373=""
                      className="flip-card ng-tns-c1798067373-0"
                    >
                      <div
                        _ngcontent-ng-c1798067373=""
                        className="flip-card-front ng-tns-c1798067373-0"
                      >
                        {casino?.response?.cards.length > 2 && (
                          <img
                            _ngcontent-ng-c1798067373=""
                            onerror="this.src='assets/images/cards/1.webp'"
                            className="ng-tns-c1798067373-0"
                            src={`https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/${
                              casino?.response?.cards[2][1]
                            }.webp`}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                  <div
                    _ngcontent-ng-c1798067373=""
                    className="col-lg-10 col-9 ng-tns-c1798067373-0"
                  >
                    <div
                      _ngcontent-ng-c1798067373=""
                      className="casino-result-cards col-lg-12 ng-tns-c1798067373-0"
                    >
                      <div
                        _ngcontent-ng-c1798067373=""
                        className="casino-result-cardimg ng-tns-c1798067373-0"
                      >
                        <div
                          _ngcontent-ng-c1798067373=""
                          className="andar-bhar2-cards-slider ng-tns-c1798067373-0"
                        >
                          {/**/}
                          {/**/}
                          {/**/}
                          {/**/}
                          <div
                            _ngcontent-ng-c1798067373=""
                            className="draggable ng-tns-c1798067373-0"
                          >
                            {casino?.response?.cards[2]?.map(
                              (item) =>
                                item != "1" && (
                                  <img
                                    _ngcontent-ng-c1798067373=""
                                    onerror="this.src='assets/images/cards/1.webp'"
                                    className="ng-tns-c1798067373-0"
                                    src={`https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/${item}.webp`}
                                  />
                                )
                            )}
                            {/**/}
                            {/**/}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/**/}
            </div>
          </div>
        </div>
      ),
    };
    if (shortName == "cards32a cards32b") {
      return cardMap["cards32a"];
    }
    if (shortName == "baccarat") {
      return "";
    }
    if(shortName == "teenpatti1day"){
      return cardMap.teenpatti20;
    }
    return cardMap[_];
  };
  return (
    <div
      _ngcontent-ng-c1798067373=""
      className="center-main-container casino-page ng-tns-c1798067373-0 ng-star-inserted"
    >
      <div
        _ngcontent-ng-c1798067373=""
        className="center-container ng-tns-c1798067373-0"
      >
        <div
          _ngcontent-ng-c1798067373=""
          className={`casino-page-container ng-tns-c1798067373-0 ${shortName}`}
        >
          <div
            _ngcontent-ng-c1798067373=""
            className="casino-title-header-mobile d-xl-none ng-tns-c1798067373-0"
          >
            <ul
              _ngcontent-ng-c1798067373=""
              className="nav nav-tabs menu-tabs ng-tns-c1798067373-0"
            >
              <li
                _ngcontent-ng-c1798067373=""
                className="nav-item ng-tns-c1798067373-0"
              >
                <div
                  onClick={() => {
                    setSelectedTab(0);
                  }}
                  _ngcontent-ng-c1798067373=""
                  className={`ng-tns-c1798067373-0 ${
                    selectedTab === 0 ? "active" : ""
                  } nav-link`}
                >
                  Game
                </div>
              </li>
              <li
                onClick={() => {
                  setSelectedTab(1);
                }}
                _ngcontent-ng-c1798067373=""
                className="nav-item ng-tns-c1798067373-0"
              >
                <div
                  _ngcontent-ng-c1798067373=""
                  className={`ng-tns-c1798067373-0 ${
                    selectedTab === 1 ? "active" : ""
                  } nav-link`}
                >
                  {" "}
                  Placed Bet ({casinoBets?.data?.length}){" "}
                </div>
              </li>
            </ul>
            <div
              _ngcontent-ng-c1798067373=""
              className="pe-2 ng-tns-c1798067373-0"
            >
              <span
                _ngcontent-ng-c1798067373=""
                className="casino-rid ng-tns-c1798067373-0"
              >
                <a
                  _ngcontent-ng-c1798067373=""
                  className="d-block ng-tns-c1798067373-0"
                >
                  <small
                    _ngcontent-ng-c1798067373=""
                    className="ng-tns-c1798067373-0"
                  >
                    Rules
                  </small>
                </a>{" "}
                Round ID:{" "}
                <span
                  _ngcontent-ng-c1798067373=""
                  className="ng-tns-c1798067373-0 ng-star-inserted"
                >
                  {casino?.response?.marketId}
                </span>
              </span>
            </div>
          </div>
          <div
            _ngcontent-ng-c1798067373=""
            className="casino-header ng-tns-c1798067373-0"
          >
            <span
              _ngcontent-ng-c1798067373=""
              className="casino-name ng-tns-c1798067373-0"
            >
              {" "}
              {gameName}
              <a
                _ngcontent-ng-c1798067373=""
                className="ms-1 d-xl-inline d-none ng-tns-c1798067373-0"
              >
                <small
                  _ngcontent-ng-c1798067373=""
                  className="ng-tns-c1798067373-0"
                >
                  Rules
                </small>
              </a>
            </span>
            <span
              _ngcontent-ng-c1798067373=""
              className="casino-rid d-none d-xl-inline-block ng-tns-c1798067373-0"
            >
              <small
                _ngcontent-ng-c1798067373=""
                className="ng-tns-c1798067373-0"
              >
                Round ID:{" "}
                <span
                  _ngcontent-ng-c1798067373=""
                  className="ng-tns-c1798067373-0 ng-star-inserted"
                >
                  {casino?.response?.marketId}
                </span>
              </small>
              <span
                _ngcontent-ng-c1798067373=""
                className="ng-tns-c1798067373-0 ng-star-inserted"
              >
                {" "}
                | Min: 100 | Max: 300000
              </span>
            </span>
          </div>

          {selectedTab == 0 && (
            <div
              _ngcontent-ng-c1798067373=""
              className="ng-tns-c1798067373-0 ng-star-inserted"
            >
              <div
                _ngcontent-ng-c1798067373=""
                className="casino-video ng-tns-c1798067373-0"
              >
                <div
                  _ngcontent-ng-c1798067373=""
                  className="video-box-container ng-tns-c1798067373-0"
                >
                  <div
                    _ngcontent-ng-c1798067373=""
                    className="casino-video-box ng-tns-c1798067373-0 ng-star-inserted"
                  >
                    <iframe
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-0"
                      src={CASINO_VIDEO_URL + VIDEO_ID}
                    />
                  </div>
                </div>
                <div
                  _ngcontent-ng-c1798067373=""
                  className="ng-tns-c1798067373-0 ng-star-inserted"
                >
                  {(shortName == "baccarat"||shortName=="baccarat baccarat2game") ? null : casinoCardMap(
                      shortName
                    ) ? (
                    casinoCardMap(shortName)
                  ) : (
                    <div
                      _ngcontent-ng-c1798067373=""
                      className="casino-video-cards ng-tns-c1798067373-0 ng-star-inserted"
                    >
                      <div
                        _ngcontent-ng-c1798067373=""
                        className="flip-card-container ng-tns-c1798067373-0"
                      >
                        {casino?.response?.cards &&
                          casino?.response?.cards[0]?.map((item) => (
                            <div
                              _ngcontent-ng-c1798067373=""
                              className="me-lg-2 me-1 ng-tns-c1798067373-0 ng-star-inserted"
                            >
                              <img
                                _ngcontent-ng-c1798067373=""
                                className="ng-tns-c1798067373-0"
                                src={`https://ik.imagekit.io/bmaxmbpyx/https://247maharaja.com/assets/images/cards/${item}.webp`}
                              />
                            </div>
                          ))}
                      </div>
                    </div>
                  )}
                </div>

                <div
                  _ngcontent-ng-c1798067373=""
                  className="clock ng-tns-c1798067373-9"
                >
                  <div
                    _ngcontent-ng-c1798067373=""
                    className="count-no ng-tns-c1798067373-9 ng-star-inserted"
                  >
                    <span
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-9"
                    >
                      <div
                        _ngcontent-ng-c1798067373=""
                        className="content ng-tns-c1798067373-9"
                      >
                        <div
                          _ngcontent-ng-c1798067373=""
                          style={{ position: "absolute" }}
                          className="ng-tns-c1798067373-9"
                        >
                          <div
                            _ngcontent-ng-c1798067373=""
                            className="flip ng-tns-c1798067373-9"
                          >
                            <div
                              _ngcontent-ng-c1798067373=""
                              className="up ng-tns-c1798067373-9"
                            >
                              <div
                                _ngcontent-ng-c1798067373=""
                                className="ng-tns-c1798067373-9"
                              >
                                1
                              </div>
                            </div>
                            <div
                              _ngcontent-ng-c1798067373=""
                              className="down ng-tns-c1798067373-9"
                            >
                              <div
                                _ngcontent-ng-c1798067373=""
                                className="ng-tns-c1798067373-9"
                              >
                                <div
                                  _ngcontent-ng-c1798067373=""
                                  className="ng-tns-c1798067373-9"
                                >
                                  {splitDigit(true)}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          _ngcontent-ng-c1798067373=""
                          style={{ position: "absolute" }}
                          className="ng-tns-c1798067373-9"
                        >
                          <div
                            _ngcontent-ng-c1798067373=""
                            className="flip-card ng-tns-c1798067373-9"
                          >
                            <div
                              _ngcontent-ng-c1798067373=""
                              className="flip-card-inner ng-tns-c1798067373-9 ng-trigger ng-trigger-flip"
                            >
                              <div
                                _ngcontent-ng-c1798067373=""
                                className="flip-card-front ng-tns-c1798067373-9"
                              >
                                <div
                                  _ngcontent-ng-c1798067373=""
                                  className="up ng-tns-c1798067373-9"
                                >
                                  <div
                                    _ngcontent-ng-c1798067373=""
                                    className="ng-tns-c1798067373-9"
                                  >
                                    {splitDigit(true)}
                                  </div>
                                </div>
                              </div>
                              <div
                                _ngcontent-ng-c1798067373=""
                                className="flip-card-back ng-tns-c1798067373-9"
                              >
                                <div
                                  _ngcontent-ng-c1798067373=""
                                  className="down ng-tns-c1798067373-9"
                                >
                                  <div
                                    _ngcontent-ng-c1798067373=""
                                    className="ng-tns-c1798067373-9"
                                  >
                                    <div
                                      _ngcontent-ng-c1798067373=""
                                      className="ng-tns-c1798067373-9"
                                    >
                                      0
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </span>
                  </div>

                  <div
                    _ngcontent-ng-c1798067373=""
                    className="count-no ng-tns-c1798067373-9 ng-star-inserted"
                    style={{ marginRight: 80 }}
                  >
                    <span
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-9"
                    >
                      <div
                        _ngcontent-ng-c1798067373=""
                        className="content ng-tns-c1798067373-9"
                      >
                        <div
                          _ngcontent-ng-c1798067373=""
                          style={{ position: "absolute" }}
                          className="ng-tns-c1798067373-9"
                        >
                          <div
                            _ngcontent-ng-c1798067373=""
                            className="flip ng-tns-c1798067373-9"
                          >
                            <div
                              _ngcontent-ng-c1798067373=""
                              className="up ng-tns-c1798067373-9"
                            >
                              <div
                                _ngcontent-ng-c1798067373=""
                                className="ng-tns-c1798067373-9"
                              >
                                {casino?.response?.autoTime}
                              </div>
                            </div>
                            <div
                              _ngcontent-ng-c1798067373=""
                              className="down ng-tns-c1798067373-9"
                            >
                              <div
                                _ngcontent-ng-c1798067373=""
                                className="ng-tns-c1798067373-9"
                              >
                                <div
                                  _ngcontent-ng-c1798067373=""
                                  className="ng-tns-c1798067373-9"
                                >
                                  {splitDigit(false)}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          _ngcontent-ng-c1798067373=""
                          style={{ position: "absolute" }}
                          className="ng-tns-c1798067373-9"
                        >
                          <div
                            _ngcontent-ng-c1798067373=""
                            className="flip-card ng-tns-c1798067373-9"
                          >
                            <div
                              _ngcontent-ng-c1798067373=""
                              className="flip-card-inner ng-tns-c1798067373-9 ng-trigger ng-trigger-flip"
                            >
                              <div
                                _ngcontent-ng-c1798067373=""
                                className="flip-card-front ng-tns-c1798067373-9"
                              >
                                <div
                                  _ngcontent-ng-c1798067373=""
                                  className="up ng-tns-c1798067373-9"
                                >
                                  <div
                                    _ngcontent-ng-c1798067373=""
                                    className="ng-tns-c1798067373-9"
                                  >
                                    {splitDigit(false)}
                                  </div>
                                </div>
                              </div>
                              <div
                                _ngcontent-ng-c1798067373=""
                                className="flip-card-back ng-tns-c1798067373-9"
                              >
                                <div
                                  _ngcontent-ng-c1798067373=""
                                  className="down ng-tns-c1798067373-9"
                                >
                                  <div
                                    _ngcontent-ng-c1798067373=""
                                    className="ng-tns-c1798067373-9"
                                  >
                                    <div
                                      _ngcontent-ng-c1798067373=""
                                      className="ng-tns-c1798067373-9"
                                    >
                                      {splitDigit(false)}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </span>
                  </div>
                </div>
              </div>

              <div
                _ngcontent-ng-c1798067373=""
                className="casino-detail ng-tns-c1798067373-0"
              >
                {children}

                <div
                  _ngcontent-ng-c1798067373=""
                  className="ng-tns-c1798067373-0"
                >
                  <div
                    _ngcontent-ng-c1798067373=""
                    className="casino-last-result-title ng-tns-c1798067373-0"
                  >
                    <span
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-0"
                    >
                      Last Result
                    </span>
                    <span
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-0"
                    >
                      <a
                        _ngcontent-ng-c1798067373=""
                        className="ng-tns-c1798067373-0"
                      >
                        View All
                      </a>
                    </span>
                  </div>
                  <div
                    _ngcontent-ng-c1798067373=""
                    className="casino-last-results ng-tns-c1798067373-0 ng-star-inserted"
                  >
                    {lastTenResultData?.data?.data?.casinoMarketHistoryResponseDTOS?.map(
                      (item) => (
                        <span
                          className={`ng-tns-c3502172659-5 result result-${
                            item?.mainWinner === "T" ? "b" : "a"
                          } ng-star-inserted`}
                        >
                          <span className="ng-tns-c3502172659-5">
                            {" "}
                            {item?.mainWinner}
                          </span>
                        </span>
                      )
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}
          <div
            _ngcontent-ng-c1798067373=""
            className={`ng-tns-c1798067373-0 ${selectedTab == 0 &&
              "d-none"} matchbettable w-100 ng-star-inserted`}
          >
            <div
              _ngcontent-ng-c1798067373=""
              className="table-responsive ng-tns-c1798067373-0"
            >
              <table
                _ngcontent-ng-c1798067373=""
                className="table ng-tns-c1798067373-0"
              >
                <thead
                  _ngcontent-ng-c1798067373=""
                  className="ng-tns-c1798067373-0"
                >
                  <tr
                    _ngcontent-ng-c1798067373=""
                    className="ng-tns-c1798067373-0"
                  >
                    <th
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-0"
                    >
                      Matched Bet
                    </th>
                    <th
                      _ngcontent-ng-c1798067373=""
                      className="text-end ng-tns-c1798067373-0"
                    >
                      Odds
                    </th>
                    <th
                      _ngcontent-ng-c1798067373=""
                      className="text-end ng-tns-c1798067373-0"
                    >
                      Stake
                    </th>
                  </tr>
                </thead>
                <tbody
                  _ngcontent-ng-c1798067373=""
                  className="ng-tns-c1798067373-0"
                >
                  {casinoBets?.data?.response?.map((item) => (
                    <tr
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-0"
                    >
                      <td
                        _ngcontent-ng-c1798067373=""
                        className="ng-tns-c1798067373-0"
                      >
                        <span
                          _ngcontent-ng-c1798067373=""
                          className="ng-tns-c1798067373-0"
                        >
                          {item.runnerName}
                        </span>
                      </td>
                      <td
                        _ngcontent-ng-c1798067373=""
                        className="text-end ng-tns-c1798067373-0"
                      >
                        <span
                          _ngcontent-ng-c1798067373=""
                          className="ng-tns-c1798067373-0"
                        >
                          {item.back ? item.back : item.lay}
                        </span>
                      </td>
                      <td
                        _ngcontent-ng-c1798067373=""
                        className="text-end ng-tns-c1798067373-0"
                      >
                        <span
                          _ngcontent-ng-c1798067373=""
                          className="ng-tns-c1798067373-0"
                        >
                          {item.amount}
                        </span>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div
        _ngcontent-ng-c1798067373=""
        className="sidebar right-sidebar casino-right-sidebar ng-tns-c1798067373-0"
      >
        <div
          _ngcontent-ng-c1798067373=""
          className="sidebar-box place-bet-container d-xl-block d-none ng-tns-c1798067373-0"
        >
          <div
            _ngcontent-ng-c1798067373=""
            className="sidebar-title ng-tns-c1798067373-0"
          >
            <h4 _ngcontent-ng-c1798067373="" className="ng-tns-c1798067373-0">
              Place Bet
            </h4>
          </div>
        </div>
        {selectedEventForBet && !isMobile && (
          <div>
            <div
              _ngcontent-ng-c1798067373=""
              className="place-bet-box ng-tns-c1798067373-0 back ng-star-inserted"
            >
              <div
                _ngcontent-ng-c1798067373=""
                className="place-bet-box-header ng-tns-c1798067373-0"
              >
                <div
                  _ngcontent-ng-c1798067373=""
                  className="place-bet-for ng-tns-c1798067373-0"
                >
                  (Bet for)
                </div>
                <div
                  _ngcontent-ng-c1798067373=""
                  className="place-bet-odds ng-tns-c1798067373-0"
                >
                  {" "}
                  odds{" "}
                </div>
                <div
                  _ngcontent-ng-c1798067373=""
                  className="place-bet-stake ng-tns-c1798067373-0"
                >
                  Stake
                </div>
                <div
                  _ngcontent-ng-c1798067373=""
                  className="place-bet-profit ng-tns-c1798067373-0"
                >
                  Profit
                </div>
              </div>
              <div
                _ngcontent-ng-c1798067373=""
                className="place-bet-box-body ng-tns-c1798067373-0"
              >
                <a
                  _ngcontent-ng-c1798067373=""
                  onClick={() => {
                    dispatch({
                      type: "gameData/setSelectedEventForBet",
                      payload: null,
                    });
                  }}
                  className="text-danger placebet-close ng-tns-c1798067373-0"
                >
                  <i
                    _ngcontent-ng-c1798067373=""
                    className="fa fa-times ng-tns-c1798067373-0"
                  />
                </a>
                <div
                  _ngcontent-ng-c1798067373=""
                  className="place-bet-for ng-tns-c1798067373-0"
                >
                  <span
                    _ngcontent-ng-c1798067373=""
                    className="ng-tns-c1798067373-0"
                  >
                    {selectedEventForBet?.runnerName}
                  </span>
                </div>
                <div
                  _ngcontent-ng-c1798067373=""
                  className="place-bet-odds ng-tns-c1798067373-0"
                >
                  <input
                    _ngcontent-ng-c1798067373=""
                    type="text"
                    disabled={isLoading}
                    defaultValue=""
                    value={selectedEventForBet?.selectedOdd}
                    className="form-control ng-tns-c1798067373-0 ng-untouched ng-pristine"
                  />
                  <div
                    _ngcontent-ng-c1798067373=""
                    className="spinner-buttons input-group-btn btn-group-vertical ng-tns-c1798067373-0"
                  >
                    <button
                      _ngcontent-ng-c1798067373=""
                      className="btn-default ng-tns-c1798067373-0"
                    >
                      <i
                        _ngcontent-ng-c1798067373=""
                        className="fa fa-angle-up ng-tns-c1798067373-0"
                      />
                    </button>
                    <button
                      _ngcontent-ng-c1798067373=""
                      className="btn-default ng-tns-c1798067373-0"
                    >
                      <i
                        _ngcontent-ng-c1798067373=""
                        className="fa fa-angle-down ng-tns-c1798067373-0"
                      />
                    </button>
                  </div>
                </div>
                <div
                  _ngcontent-ng-c1798067373=""
                  className="place-bet-stake ng-tns-c1798067373-0"
                >
                  <input
                    onChange={(e) => {
                      handleAmountChange(e.target.value);
                    }}
                    value={selectedEventForBet?.amount}
                    _ngcontent-ng-c1798067373=""
                    type="number"
                    className="form-control ng-tns-c1798067373-0 ng-untouched ng-pristine ng-valid"
                  />
                </div>
                <div
                  _ngcontent-ng-c1798067373=""
                  className="place-bet-profit ng-tns-c1798067373-0"
                >
                  <span
                    _ngcontent-ng-c1798067373=""
                    className="ng-tns-c1798067373-0 ng-star-inserted"
                  >
                    {
                      getProfit(
                        selectedEventForBet?.amount,
                        "casino",
                        selectedEventForBet
                      ).profit
                    }
                  </span>
                </div>
              </div>
              <div
                _ngcontent-ng-c1798067373=""
                className="place-bet-buttons ng-tns-c1798067373-0"
              >
                {buttons?.sport?.map((item) => (
                  <button
                    disabled={isLoading}
                    onClick={() => {
                      handleAmountChange(item?.value);
                    }}
                    _ngcontent-ng-c1798067373=""
                    className="btn btn-place-bet ng-tns-c1798067373-0 ng-star-inserted"
                  >
                    {" "}
                    {item?.label}{" "}
                  </button>
                ))}
              </div>
              <div
                _ngcontent-ng-c1798067373=""
                className="place-bet-action-buttons ng-tns-c1798067373-0"
              >
                <div
                  _ngcontent-ng-c1798067373=""
                  className="ng-tns-c1798067373-0"
                >
                  <button
                    onClick={() => {
                      dispatch({
                        type: "gameData/setSelectedEventForBet",
                        payload: null,
                      });
                    }}
                    disabled={isLoading}
                    _ngcontent-ng-c1798067373=""
                    className="btn btn-danger me-1 ng-tns-c1798067373-0"
                  >
                    {" "}
                    Reset{" "}
                  </button>
                </div>
                <div
                  _ngcontent-ng-c1798067373=""
                  className="ng-tns-c1798067373-0"
                >
                  <button
                    onClick={betPlace}
                    disabled={isLoading}
                    _ngcontent-ng-c1798067373=""
                    className="btn btn-success btn-loader ng-tns-c1798067373-0"
                  >
                    {isLoading ? (
                      <i
                        _ngcontent-ng-c1798067373=""
                        className="fa fa-spinner fa-spin ng-tns-c1798067373-0"
                      />
                    ) : (
                      "Submit"
                    )}
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
        <div
          _ngcontent-ng-c1798067373=""
          className="sidebar-box my-bet-container d-xl-block d-none ng-tns-c1798067373-0"
        >
          <div
            _ngcontent-ng-c1798067373=""
            className="sidebar-title ng-tns-c1798067373-0"
          >
            <h4 _ngcontent-ng-c1798067373="" className="ng-tns-c1798067373-0">
              My Bet
            </h4>
            <a
              _ngcontent-ng-c1798067373=""
              className="sideviewmore ng-tns-c1798067373-0"
            >
              View more
            </a>
          </div>
          <div
            _ngcontent-ng-c1798067373=""
            className="my-bets ng-tns-c1798067373-0"
          >
            <div
              _ngcontent-ng-c1798067373=""
              className="table-responsive w-100 ng-tns-c1798067373-0"
            >
              <table
                _ngcontent-ng-c1798067373=""
                className="table ng-tns-c1798067373-0"
              >
                <thead
                  _ngcontent-ng-c1798067373=""
                  className="ng-tns-c1798067373-0"
                >
                  <tr
                    _ngcontent-ng-c1798067373=""
                    className="ng-tns-c1798067373-0"
                  >
                    <th
                      _ngcontent-ng-c1798067373=""
                      className="ng-tns-c1798067373-0"
                    >
                      Matched Bet
                    </th>
                    <th
                      _ngcontent-ng-c1798067373=""
                      className="text-end ng-tns-c1798067373-0"
                    >
                      Odds
                    </th>
                    <th
                      _ngcontent-ng-c1798067373=""
                      className="text-end ng-tns-c1798067373-0"
                    >
                      Stake
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {casinoBets?.data?.response?.map((item) => (
                    <tr className="ng-tns-c3502172659-3">
                      <td className="ng-tns-c3502172659-3">
                        {item?.runnerName}
                      </td>
                      <td className="text-end ng-tns-c3502172659-3">
                        {item?.back ? item?.back : item?.lay}
                      </td>
                      <td className="text-end ng-tns-c3502172659-3">
                        {item?.amount}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div
          _ngcontent-ng-c1798067373=""
          className="sidebar-box sidebar-box-rules my-bet-container ng-tns-c1798067373-0"
        >
          {rule && ruleMap[rule]}
        </div>
      </div>
      {isMobile && (
        <div
          _ngcontent-ng-c1798067373=""
          id="placebetmodal"
          tabIndex={-1}
          aria-labelledby="placebetmodalLabel"
          className="modal fade ng-tns-c1798067373-0 show"
          aria-modal="true"
          role="dialog"
          style={{
            display: selectedEventForBet && "block",
          }}
        >
          <div
            _ngcontent-ng-c1798067373=""
            className="modal-dialog ng-tns-c1798067373-0"
          >
            <div
              ref={modalRef}
              _ngcontent-ng-c1798067373=""
              className="modal-content ng-tns-c1798067373-0"
            >
              <div
                _ngcontent-ng-c1798067373=""
                className="modal-header  ng-tns-c1798067373-0"
              >
                <h5
                  _ngcontent-ng-c1798067373=""
                  id="placebetmodalLabel"
                  className="modal-title ng-tns-c1798067373-0"
                >
                  Place Bet
                </h5>
                <button
                  _ngcontent-ng-c1798067373=""
                  type="button"
                  onClick={() => {
                    dispatch({
                      type: "gameData/setSelectedEventForBet",
                      payload: null,
                    });
                  }}
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  className="btn-close ng-tns-c1798067373-0"
                />
              </div>
              <div
                _ngcontent-ng-c1798067373=""
                className="modal-body ng-tns-c1798067373-0"
              >
                <div
                  _ngcontent-ng-c1798067373=""
                  className={`place-bet-modal ng-tns-c1798067373-0 ${
                    selectedEventForBet?.type
                  }`}
                >
                  <div
                    _ngcontent-ng-c1798067373=""
                    className="row ng-tns-c1798067373-0"
                  >
                    <div
                      _ngcontent-ng-c1798067373=""
                      className="col-6 ng-tns-c1798067373-0"
                    >
                      <b
                        _ngcontent-ng-c1798067373=""
                        className="ng-tns-c1798067373-0"
                      >
                        {selectedEventForBet?.runnerName}
                      </b>
                    </div>
                    <div
                      _ngcontent-ng-c1798067373=""
                      className="col-6 ng-tns-c1798067373-0"
                    >
                      <div
                        _ngcontent-ng-c1798067373=""
                        className="float-end ng-tns-c1798067373-0"
                      >
                        <button
                          _ngcontent-ng-c1798067373=""
                          className="stakeactionminus btn ng-tns-c1798067373-0"
                        >
                          <span
                            _ngcontent-ng-c1798067373=""
                            className="fa fa-minus ng-tns-c1798067373-0"
                          />
                        </button>
                        <input
                          _ngcontent-ng-c1798067373=""
                          type="text"
                          value={selectedEventForBet?.selectedOdd}
                          className="stakeinput ng-tns-c1798067373-0 ng-untouched ng-pristine"
                          disabled=""
                        />
                        <button
                          _ngcontent-ng-c1798067373=""
                          className="stakeactionminus btn ng-tns-c1798067373-0"
                        >
                          <span
                            _ngcontent-ng-c1798067373=""
                            className="fa fa-plus ng-tns-c1798067373-0"
                          />
                        </button>
                      </div>
                    </div>
                  </div>
                  <div
                    _ngcontent-ng-c1798067373=""
                    className="row mt-2 ng-tns-c1798067373-0"
                  >
                    <div
                      _ngcontent-ng-c1798067373=""
                      className="col-4 ng-tns-c1798067373-0"
                    >
                      <input
                        _ngcontent-ng-c1798067373=""
                        type="number"
                        onChange={(e) => {
                          handleAmountChange(e.target.value);
                        }}
                        value={selectedEventForBet?.amount}
                        className="stakeinput w-100 ng-tns-c1798067373-0 ng-untouched ng-pristine ng-valid"
                      />
                    </div>
                    <div
                      _ngcontent-ng-c1798067373=""
                      className="col-4 d-grid ps-0 ng-tns-c1798067373-0"
                    >
                      <button
                        disabled={isLoading}
                        onClick={betPlace}
                        _ngcontent-ng-c1798067373=""
                        className="btn btn-primary btn-block btn-loader ng-tns-c1798067373-0"
                      >
                        Submit{" "}
                      </button>
                    </div>
                    <div
                      _ngcontent-ng-c1798067373=""
                      className="col-4 text-center pt-2 ng-tns-c1798067373-0"
                    >
                      <span
                        _ngcontent-ng-c1798067373=""
                        className="ng-tns-c1798067373-0 ng-star-inserted"
                      >
                        {selectedEventForBet &&
                          getProfit(
                            selectedEventForBet?.amount,
                            "casino",
                            selectedEventForBet
                          )?.profit}
                      </span>
                    </div>
                  </div>
                  <div
                    _ngcontent-ng-c1798067373=""
                    className="place-bet-buttons mt-2 ng-tns-c1798067373-0"
                  >
                    {buttons?.sport?.map((item) => (
                      <button
                        _ngcontent-ng-c1798067373=""
                        onClick={() => {
                          handleAmountChange(item?.value);
                        }}
                        className="btn btn-place-bet ng-tns-c1798067373-0 ng-star-inserted"
                      >
                        {" "}
                        {item?.label}{" "}
                      </button>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
